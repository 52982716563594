import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'left',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 10
  },
  margin: {
    marginLeft : theme.spacing(2)
  },
  
}));



const ButtonA2X = props => {
  const { ...rest } = props;

  const classes = useStyles();

  return (
    <Button 
      {...rest}
      //className={clsx(classes.root, className)}
      className = {classes.margin}
      color="primary"
      variant="contained"
    >
      {}
    </Button>
  );
};

ButtonA2X.propTypes = {
  className: PropTypes.string
};

export default ButtonA2X;