import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ChartTooltipItem } from 'chart.js';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
         
const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

const ProducaoVendedorPercentuaisPorGenero = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();

  let percOpcional = rest.percOpcional;
  let percTransporte = rest.percTransporte;  
  let percHospedagem = rest.percHospedagem;
  let percAereo = rest.percAereo;
  let percSeguroViagem = rest.percSeguroViagem;
  let percOutros = rest.percOutros;
  

  const data = {
    datasets: [
      {
        data: [percOpcional, percTransporte, percHospedagem, percAereo, percSeguroViagem, percOutros],
        backgroundColor: [
          theme.palette.error.dark,
          theme.palette.success.light,
          theme.palette.info.light,
          theme.palette.warning.light,
          theme.palette.error.light         
        ],
        borderWidth: 2,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white
      }
    ],
    labels: ['Opcionais', 'Transporte', 'Hospedagem', 'Aéreo', 'Seguro Viagem', 'Outros']
  };

  const options = {
    legend: {
      display: true
    },
    responsive: true,
    maintainAspectRatio: false,
    //animation: false,
    animation: {
      animateRotate: true,
      animateScale: false,
    },
    

    cutoutPercentage: 70,
    layout: { padding: 0 },
    
    tooltips: {
      callbacks: {
        label: (tooltipItem: ChartTooltipItem, data) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const currentValue = dataset.data[tooltipItem.index];
          const total = dataset.data.reduce((sum, value) => sum + value, 0);
          const percentage = ((currentValue / total) * 100).toFixed(2);
          return `${data.labels[tooltipItem.index]}: ${percentage}%`
        },
      
      },   
  }}

  
  const tipos = [
    {
      //title: 'Opcionais',
      //value: percOpcional,
      //icon: <LaptopMacIcon />,
      //color: theme.palette.secondary.main
      
    },
    {
      //title: 'Transporte',
      //value: percTransporte,
      //icon: <TabletMacIcon />,
      //color: theme.palette.warning.main
    },
    {
      //title: 'Hospedagem',
      value: percHospedagem,
      //icon: <TabletMacIcon />,
      color: theme.palette.warning.main
    },
    {
      //title: 'Aéreo',
      value: percAereo,
      //icon: <TabletMacIcon />,
      color: theme.palette.warning.main
    },
    {
      //title: 'Seguro Viagem',
      value: percSeguroViagem,
      //icon: <TabletMacIcon />,
      color: theme.palette.warning.main
    },
    {
      //title: 'Outros',
      //value: percOutros ,
      //icon: <TabletMacIcon />,
      //color: theme.palette.warning.main
    }    

  ];

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Percentuais comissão por gênero"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Doughnut
            data={data}
            options={options}
          />

        </div>
        <div className={classes.stats}>
        </div>
      </CardContent>
    </Card>
  );
};

ProducaoVendedorPercentuaisPorGenero.propTypes = {
  className: PropTypes.string
};

export default ProducaoVendedorPercentuaisPorGenero;
