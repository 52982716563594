import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText, Collapse, Button } from '@material-ui/core';
import { ExpandLess, ExpandMore, Person, Business } from '@material-ui/icons';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Link } from 'react-router-dom';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DoneAllIcon from '@material-ui/icons/DoneAll';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const Sidebar = () => {
  const classes = useStyles();
  const [openCadastro, setOpenCadastro] = useState(false);

  const [openFinanceiro, setOpenFinanceiro] = useState(false);

  const handleCadastroClick = () => {
    setOpenCadastro(!openCadastro);
  };

  const handleFinanceiroClick = () => {
    setOpenFinanceiro(!openFinanceiro);
  };

  const handleSairClick = () =>{
    <NavLink to="/logout" >
    Go to Example
  </NavLink>

  }
  return (
      <List>
        <ListItem button onClick={handleCadastroClick}>
          <ListItemIcon>
            <CreateNewFolderIcon />
          </ListItemIcon>
          <ListItemText primary="Cadastro" />
          {openCadastro ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openCadastro} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          
            <ListItem Button component={Link} to="/financas/historicosbancarios">
              <ListItemIcon>
                <CompareArrowsIcon />
              </ListItemIcon>
              <ListItemText primary="Históricos Bancários" />
            </ListItem>

            <ListItem Button component={Link} to="/financas/tiposdocumentos">
              <ListItemIcon>
                <InsertDriveFileIcon />
              </ListItemIcon>
              <ListItemText primary="Tipos de Documentos" />
            </ListItem>

            <ListItem button className={classes.nested}>
              <ListItemText primary="Centros de Receitas e Despesas" />
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemText primary="Projetos" />
            </ListItem>


            <ListItem Button component={Link} to="/financas/contascorrentes">
              <ListItemIcon>
                <AccountBalanceWalletIcon />
              </ListItemIcon>
              <ListItemText primary="Contas Correntes" />
            </ListItem>

      
            <ListItem Button component={Link} to="/financas/transacoes">
              <ListItemIcon>
                <PlayForWorkIcon />
              </ListItemIcon>
              <ListItemText primary="Transação Financeira" />
            </ListItem>
      
      
            <ListItem Button component={Link} to="/financas/cartaodecredito">
              <ListItemIcon>
                <CreditCardIcon />
              </ListItemIcon>
              <ListItemText primary="Cartão de Crédito" />
            </ListItem>

      
            <ListItem Button component={Link} to="/financas/bancos">
              <ListItemIcon>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText primary="Bancos" />
            </ListItem>


            <ListItem Button component={Link} to="/financas/setores">
              <ListItemIcon>
                <AccountTreeIcon />
              </ListItemIcon>
              <ListItemText primary="Setores" />
            </ListItem>      
      

            <ListItem Button component={Link} to="/financas/feriados">
              <ListItemIcon>
                <DateRangeIcon />
              </ListItemIcon>
              <ListItemText primary="Feriados" />
            </ListItem>      


            <ListItem button className={classes.nested}>
              <ListItemText primary="Retenção de Impostos" />
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemText primary="Clientes" />
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemText primary="Fornecedores" />
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemText primary="Funcionários" />
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemText primary="Cidades" />
            </ListItem>

            <ListItem button className={classes.nested} component={Link} to="/financas/paises">
              <ListItemText primary="Países" />
            </ListItem>

            <ListItem button className={classes.nested}>
              <ListItemText primary="Usuários" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={handleFinanceiroClick}>
          <ListItemIcon>
            <MonetizationOn />
          </ListItemIcon>
          <ListItemText primary="Financeiro" />
          {openFinanceiro ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openFinanceiro} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText primary="Contas a Pagar" />
            </ListItem>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <Business />
              </ListItemIcon>
              <ListItemText primary="Contas a Receber" />
            </ListItem>
            
            <ListItem button className={classes.nested} component={Link} to="/financas/fechamentofinanceiro">
              <ListItemIcon>
                <DoneAllIcon />
              </ListItemIcon>
              <ListItemText primary="Fechamento Financeiro" />
            </ListItem>            

          </List>
        </Collapse>

        <ListItem Button component={Link} to="/Logout">
          <ListItemIcon>
            <LockOpenIcon />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItem>

      </List>

);
};

export default Sidebar;
