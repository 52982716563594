import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import api from '../../services/api';
import { TotalPaxTransito, TotalProfit, UsersByDevice } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = () => {
  const classes = useStyles();

  /*async function loadDashBoard  (){
    const response = await api.get('/TControllerAtendimentoSISSOFT/DashBoardA2X')                       
      .then(function (response) {
        setDashBoard({dashBoard : response});    
      })
      .catch(function (error) {
        console.log(error);
      });       
  }  */


  /* Indicadores DashBoard */
  const [dashBoardx, setDashBoard] = useState({
    totalBug : 0,
    totalImplementacao : 0,
    totalPercProjTemp : 0,
    totalPaxTransito : 0,
  });

  useEffect(() => {
   
    //console.log('entrou uso efeito');
    async function loadDashBoard  (){
      const response = await api.get('/TControllerDashboard/DashBoard')                       
        .then(function (response) {
          console.log(response)
          setDashBoard({totalBug : response.data.totalBug, 
            totalImplementacao : response.data.totalImplementacao,
            totalPercProjTemp : response.data.totalPercProjTemp,
            totalPaxTransito : response.data.totalPaxDestino
          });    
          return response;
          //setDashBoard({dashBoard: {totalBug: 1000}});
        })
        .catch(function (error) {
          console.log(error);
        });       
    }
  
    //var adash = loadDashBoard();
    console.log('valor1 ')

  }, []);  

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalPaxTransito 
            //totalPaxTransito={dashBoardx.totalPaxTransito}  
            />

          <TotalProfit/>
          <UsersByDevice/>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
