import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/styles';
import api from 'services/api';

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'left',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 10
    },
    margin: {
        marginLeft: theme.spacing(2)
    },

}));

const ComboBanco = props => {
    const { className, ...rest } = props;
    const classes = useStyles();
    const [bancosx, setBancos] = useState({
        bancos: []
    });

    useEffect(() => {
        async function loadBancos() {
            let params = '';

            try {
                const response = await api.get('/banco' + params);
                if (response.status === 200) {
                    setBancos({ bancos: response.data });

                    return response.data;
                }
            } catch (error) {
                console.log(error);
            }
        }

        loadBancos();
    }, []);

    function Bancos() {
        var listBancos = bancosx.bancos;

        return (
            <div>
                <Select
                    fullWidth
                    inputProps={{
                        id: 'outlined-age-native-simple',
                        name: rest.name
                    }}
                    label={rest.label}
                    native 
                    onChange={rest.onChange}
                    value={rest.value}
                >
                    <option
                        aria-label="Não Definido"
                        value="0"
                    />
                    {listBancos.map(banco => (
                        <option
                            key={banco.codbanco}
                            value={banco.codbanco}
                        >
                            {banco.descbanco}</option>
                    ))}
                </Select>
            </div>
        )
    }

    return (
        <Bancos />
    );
};

ComboBanco.propTypes = {
    className: PropTypes.string
};

export default ComboBanco;