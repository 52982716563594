import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import api from '../../services/api';
import { CardPax, CardChegada, CardHospedagem, CardPartida, CardFaleConosco, CardServicos } from './components';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const FaleConosco = () => {
  const classes = useStyles();

 
  const handleChange = event => {
    event.persist();

    //console.log('escolheu mes');
    window.location.reload();  
  }



 /* Dados da OS */
 const [cfgappcli, setCfgAppcli] = useState([])
   


/* carrega configurações app cli */
async function loadDadosCfgAppCli() {
  //const codOS = sessionStorage.getItem('codOS');
  const aURL = '/appcli/configuracoes/1';
  try {
    const response = await api.get(aURL);
    setCfgAppcli(response.data[0]);
   } catch (error) {
    console.log(error);
  }
}


useEffect(() => {
  loadDadosCfgAppCli();
}, []);  



  return (
    <div className={classes.root}>
      
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <CardFaleConosco
            faleconosco={cfgappcli.faleconosco}/>
            
            
            {/*msgboasvindas={os.nomefanthotel}*/}

        </Grid>
  
      </Grid> 


    </div>
  );
};

export default FaleConosco;
