import api from '../../../services/api';
import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {CardContent,  FormLabel,  FormGroup, TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Switch, FormControlLabel } from '@material-ui/core';
import { ANAO, ASIM } from 'consts';
import AlertInformation from '../../../components/AlertInformation/AlertInformation';

class BancoForm extends Component{  
  constructor(props) {
    super(props);
    this.state = {
      banco: {},
      isNewRecord : false,
      isView : false,
      schema : {
        usuario: {
          presence: { allowEmpty: false, message: 'é obrigatório' },
          length: {
            maximum: 64
          }
        },
      },
      
      useStyles : makeStyles(theme => ({
        root: {
          backgroundColor: theme.palette.background.default,
          height: '100%'
        },
        grid: {
          height: '100%'
        },
        quoteContainer: {
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        },
        quote: {
          backgroundColor: theme.palette.neutral,
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: 'url(/images/auth.jpg)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        },
        quoteInner: {
          textAlign: 'center',
          flexBasis: '600px'
        },
        quoteText: {
          color: theme.palette.white,
          fontWeight: 300
        },
        name: {
          marginTop: theme.spacing(3),
          color: theme.palette.white
        },
        bio: {
          color: theme.palette.white
        },
        contentContainer: {},
        content: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        },
        contentHeader: {
          display: 'flex',
          alignItems: 'center',
          paddingTop: theme.spacing(5),
          paddingBototm: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2)
        },
        logoImage: {
          marginLeft: theme.spacing(4)
        },
        contentBody: {
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
          }
        },
        form: {
          paddingLeft: 100,
          paddingRight: 100,
          paddingBottom: 125,
          flexBasis: 700,
          [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
          }
        },
        title: {
          marginTop: theme.spacing(3)
        },
        socialButtons: {
          marginTop: theme.spacing(3)
        },
        socialIcon: {
          marginRight: theme.spacing(1)
        },
        sugestion: {
          marginTop: theme.spacing(2)
        },
        textField: {
          marginTop: theme.spacing(2)
        },
        signInButton: {
          margin: theme.spacing(2, 0)
        },
        alert: {
          color: '#000000',
        }
      })),
    };
  }

  async componentDidMount(){
    const { id } = this.props.match.params;
    const { location } = this.props;
    const currentPath = location.pathname;
    
    if (id >= 0) {
      const response = await api.get(`/banco/${id}`);
      
      this.setState({ banco: response.data[0],
        isNewRecord : false,
        isView : (currentPath.includes('/view'))}
      );      
      
    }    
    else
    if ( id === 'novo') 
    {
      this.setState({ 
        isNewRecord : true
      });
      this.state.isNewRecord = true;
      const { banco } = this.state;
    }
  }
  
  // Change Banco
  OnChange = (event) => {  
    const { banco } = this.state;
    const input = event.target;
    let value = input.value.toUpperCase();   
    banco[event.target.name] = value;

    if (input.name === 'inativo') {
      value = input.checked; // Obtém o valor da propriedade checked do Switch
      // console.log('value: ' + value);
      if (value === false){
        banco.inativo = ASIM;
      }
      else
      {
        banco.inativo = ANAO;
      }
    }

    this.setState({ banco: banco });   
  };


  /* atualiza dados de banco existente - post */
  atualizaBanco = async () =>{
    const { banco } = this.state;

    banco.descbanco = banco.descbanco.trim();
    banco.nrocompensacao = banco.nrocompensacao.trim();
    if (banco.digito)
      banco.digito = banco.digito.trim();
    if (banco.swift)
      banco.swift = banco.swift.trim();

    if (banco.digito == null) {
      banco.digito = '';
    }

    if (banco.swift == null) {
      banco.swift = '';
    }

    try {
      const response = await api.post(`/banco/${banco.codbanco}`, banco);
      if (response.status === 200) {
        this.props.history.push('/financas/bancos');
      }
    } catch (error) {
      this.handleOpenAlert(error.response.data.error);
    }
  }

  /* abre janela de alerta com mensagem */
  handleOpenAlert = (message) => {
    this.setState({ openAlert: true });
    this.setState({ messageAlert: message});
  };

  /* fecha janela mensagem alerta */
  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  }

  /* Click salvar */
  onClickSave = () =>{
    if (this.validationRequiredFields()){
      if (this.state.isNewRecord){
        this.insereBanco()
      }
      else
      {
       this.atualizaBanco();
     }
    }
  }

  /* Click cancelar */
  onClickCancel = () =>{
    this.props.history.push('/financas/bancos');
  }

 
  /* verifica se tem erro campos atendimento */
  hasErrorField = (field, value) => {
    if (field === 'descbanco') {
      if ((String(value).trim() === ''))
      {
        return true
      }
    }
  }

  /* valida campos obrigatórios ao salvar */
  validationRequiredFields() {
    const { banco } = this.state
    if( (String(banco.descbanco).trim() === '')  || (banco.descbanco === undefined)){
      banco.descbanco = '';
      this.setState({banco: banco });
      return false
    } else 
    if ((String(banco.nrocompensacao).trim() === '')  || (banco.nrocompensacao === undefined)) {
      banco.nrocompensacao = '';
      this.setState({banco: banco });
      return false  
    }
    else
    {
      return true
    }  
  }

  /* insere novo banco */
  insereBanco = async () =>{
    const { banco } = this.state;
    banco.codusuarioregistrou = parseInt(sessionStorage.getItem('codUser'));
    
    banco.descbanco = banco.descbanco.trim();
    banco.nrocompensacao = banco.nrocompensacao.trim();
    if (banco.digito)
      banco.digito = banco.digito.trim();
    if (banco.swift)
      banco.swift = banco.swift.trim();
    
    try {
      const response = await api.post(`/banco`, banco);
      if (response.status === 200) {
        this.props.history.push('/financas/bancos');
      }
    } catch (error) {
      // console.log(error);
      // console.log(error.message);
      this.handleOpenAlert(error.response.data.error);
    }
  }

  render() {
    const { banco } = this.state;
    return (
      <div>
        <CardContent>
          <Typography 
            component="h1"
            variant="h3" 
          >
            Banco {banco.codbanco} 
          </Typography>             
          
          <FormGroup>
            <TextField
              className={this.state.useStyles.textField}
              label="Nome do banco"
              style={{ marginTop: '10px' }} 
              name="descbanco"
              value ={banco.descbanco || ''}
              variant="outlined"
              onChange={this.OnChange}
              helperText={banco.descbanco === '' ? 'Nome do banco obrigatório' : ' '}              
              disabled={this.state.isView}
              inputProps={{ maxLength: 30 }}
            />
          </FormGroup>  

          <FormControlLabel
            control={
              <Switch
                checked={!(banco.inativo === ASIM)}
                name="inativo"
                onChange={this.OnChange}
                color="primary"
                disabled={this.state.isView}
              />
            }
            label={(banco.inativo === ASIM) ? 'Inativo' : 'Ativo'}
          />

          <br/>
          <br/>

          <FormGroup row={true}>
            <TextField
              className={this.state.useStyles.textField}
              label="Número"
              style={{ width: 100, marginRight: '10px' }}
              name="nrocompensacao"
              value ={banco.nrocompensacao || ''}
              variant="outlined"
              onChange={this.OnChange}
              helperText={banco.nrocompensacao === '' ? 'Código de compensação obrigatório' : ' '}              
              disabled={this.state.isView}
              inputProps={{ maxLength: 20 }}
            />

            <TextField
              className={this.state.useStyles.textField}
              label="Dígito"
              style={{ width: 100, marginRight: '10px' }}
              name="digito"
              value ={banco.digito || ''}
              variant="outlined"
              onChange={this.OnChange}           
              disabled={this.state.isView}
              inputProps={{ maxLength: 1 }}
            />
    
            <TextField
              className={this.state.useStyles.textField}
              label="Identificação internacional (swift)"
              style={{ width: 400 }}
              name="swift"
              value ={banco.swift || ''}
              variant="outlined"
              onChange={this.OnChange}
              disabled={this.state.isView}
              inputProps={{ maxLength: 50 }}
            />
          </FormGroup>
        
          <br/>

          <Button
            name="salvar"
            color="primary"
            variant="contained"
            onClick={this.onClickSave}
            style={{ marginTop: '10px' }} 
            disabled={this.state.isView}
          >
            Salvar
          </Button>

          <Button
            name="cancelar"
            color="primary"
            variant="contained"
            onClick={this.onClickCancel}
            style={{ marginTop: '10px', marginLeft: '10px' }}
          >
            Cancelar
          </Button>
        </CardContent>

        <AlertInformation
          open={this.state.openAlert}
          handleClose={() => this.handleCloseAlert()}
          message={this.state.messageAlert}
        />
      </div>
    );
  }
}

BancoForm.propTypes = 
{
  classes: PropTypes.object.isRequired
};

export default BancoForm;