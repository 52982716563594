import React, { Component } from 'react';
import { Card, CardContent, Table, TableCell, TableRow, TableHead, TableBody} from '@material-ui/core';
import api from '../../../services/api';
import PerfectScrollbar from 'react-perfect-scrollbar';

class ClienteList  extends Component{  
  state = {
    clientes: [],
    //selectedOptionCliente : 1,
    modalIsOpenReportFilter : false,
    optionActiveInactive : '0',
    selectedReasonId : '0',
    groupByReason : false,
    showProgressPrint : false,
  };

  estilo = {
    marginLeft : 30,
    marginTop: 30,
    marginRight : 30,
    addButton: {
      height: '42px',
      display: 'flex',
      marginTop: 20,
      alignItems: 'center',
      float: 'right', // alinhamento a direita
    }
  }

  constructor(props) {
    super(props);
  }

  async componentDidMount(){
    this.loadClientes();
    //this.loadMotivosCancelamentoClientes();
  }

  /* Carrega clientes */
  loadClientes = async () =>{
    const response = await api.get('/TControllerCliente/Cliente/0');      
    this.setState({ clientes: response.data});
  }
  /* Carrega motivos de cancelamentos de clientes */
  loadMotivosCancelamentoClientes = async () =>{
    const response = await api.get('/TControllerAtendimentoSISSOFT/MotivoInativacaoCliente/0');
    this.setState({ motivosCancelamentoCliente: response.data});    
  }

  handleClickOpenClosingReportFilter = () => {
    this.setState({ modalIsOpenReportFilter : !this.state.modalIsOpenReportFilter});
  };

  /* escolhe ativo ou inativo ou todos filtro */
  handleChangeActiveInactiveFilter = (event) => {
    this.setState({optionActiveInactive: event.target.value });   
  };
  /* Escolhe motivo de cancelamento */
  handleSelectItem = (e) => {
    this.setState({selectedReasonId: e.target.value}); 
  };

  /* Gera relatório de cliente - faz download */
  getReportCustomer = async () =>{
    this.setState({showProgressPrint : true});
    var aFilter = '{"optionActiveInactive":' + this.state.optionActiveInactive + 
                  ', "groupByReason" '+ ':'  + this.state.groupByReason +
                  ', "idReason" ' + ':' +  this.state.selectedReasonId  + '}';
    const response = await api.get('/TControllerAtendimentoSISSOFT/ReportCustomer/' + aFilter)
      .then(function (response) {
        if (response){
          var a = document.createElement('a'); //Create <a>
          a.href = 'data:' + response.data.MIME + ';base64,' + response.data.fileBase64; //Image Base64 Goes here                              
          a.download = response.data.fileName; //File name Here          
          a.click(); //Downloaded file          
          //this.setState({showProgressPrint : false});
          //this.setState({ modalIsOpenReportFilter : !this.state.modalIsOpenReportFilter});  
          //document.getElementById('progressbarprint').hidden = true;                             
        }
      })
      .catch(function (error) {
        console.log(error);
      });       
    //console.log('teve resposta*****' + response.data);
      //this.setState({selectedFile : null});
    //this.getDecodeBase64(response.data.arquivo);
    //  });
    this.setState({showProgressPrint : false});    
    this.setState({ modalIsOpenReportFilter : !this.state.modalIsOpenReportFilter});      
  };

  /* Agrupar por motivo de inativação */
  //handleChangeGroupByReason = (e) =>
  handleChangeGroupByReason = () => {  
    this.setState({ groupByReason : !this.state.groupByReason});    
   
  };

   
  render(){
    const {clientes} = this.state;

    return(
      <div style={this.estilo}>
        <Card
          //{...rest}
          //className={clsx(classes.root, className)}
        >
          <CardContent>
            <PerfectScrollbar>
              <div >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome Fantasia</TableCell>
                      <TableCell>Fone</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {clientes.slice(0, 100).map(cliente => (
                      <TableRow
                        hover
                        key={cliente.codCliente}
                      >
                        <TableCell>{cliente.nomeFantasia}</TableCell>
                        <TableCell>{cliente.fone}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
        </Card>        



      </div>
    );
  }
}
export default ClienteList;
