import React, { Component,  useState   } from 'react';
import { Button, Card, CardContent, Table, TableCell, TableRow, TableHead, TableBody, Paper,
Input} from '@material-ui/core';
import api from '../../../services/api';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal/DeleteConfirmationModal';
import AlertInformation from '../../../components/AlertInformation/AlertInformation';
import { Select, MenuItem } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { ANAO, ASIM } from 'consts';

class SetorList  extends Component{  
  state = {
    setores: [],
    searchFilter : {
      nomeSetor : '',
      situacaoInativoFilter: ANAO,
    },
  };

  estilo = {
    marginLeft: 30,
    marginTop: 30,
    marginRight: 30,
    container: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    addButton: {
     
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      float: 'right', // alinhamento a direita


    },
    redText: {
      color: 'red',
    },  
  };
  constructor(props) {
    super(props);
  }

  /* carregamento inicial */
  async componentDidMount(){
    this.loadSetores();
  }

  /* Carrega Setores */
  loadSetores = async () =>{
    const { searchFilter } = this.state;
    let params = '';

    /* consulta pelo nome do setor */
    if (!(searchFilter.nomeSetor ==='')){
      params += '?tbsetor.descsetor=_like_' + searchFilter.nomeSetor
    }

    /* filtra situação do setor */
    switch (searchFilter.situacaoInativoFilter) {
      case ASIM:
        if (params){
          params += '&tbsetor.inativo=' + ASIM
        }
        else
          params += '?tbsetor.inativo=' + ASIM ;        
      break;
      case ANAO:
        if (params){
          params += '&tbsetor.inativo=' + ANAO
        }
        else
          params += '?tbsetor.inativo=' + ANAO  ;
        break;
      default:
        break;
    }        
    const response = await api.get('/setor' +params);      
    this.setState({ setores: response.data});
  }

  /* Escolha de um setor para exclusão */
  handleSelectItem = (id) => {
    this.setState({selectedId: id}); 
  };

  /* deleta setor */
  handleDelete = async (id) => {
    try {
      const response = await api.delete(`/setor/${id}`);
      // Verifica se a exclusão foi bem-sucedida
      if (response.status === 200) {
        this.handleClose();
        this.loadSetores();
      }
    } catch (error) {
        this.handleClose();
        this.handleOpenAlert(error.response.data.error);
        console.log(error);
        console.log(error.message);
    }
  };

  /* abre janela confirmação para exclusão */
  handleOpen = (codsetor) => {
    this.setState({ open: true });
    this.handleSelectItem(codsetor);
  };

  /* abre janela de alerta com mensagem */
  handleOpenAlert = (message) => {
    this.setState({ openAlert: true });
    this.setState({ messageAlert: message});
  };

  
  
  /* fecha janela de confirmação exclusão */
  handleClose = () => {
    this.setState({ open: false });
  };

  /* fecha janela mensagem alerta */
  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  }
  

  /* Pesquisar filtros na tela */
  OnChangeSearch = (event) => {
    const { searchFilter } = this.state;
    let {name, value } = event.target;
    value = value.toUpperCase()
    searchFilter[name] = value;
    this.setState({searchFilter: searchFilter });
    if (name === 'situacaoInativoFilter'){
      this.loadSetores();
     
    }   
  };

  

   /* Limpa Filtros na tela */
   LimpaFiltros = () => {
    let { searchFilter } = this.state;
    searchFilter.nomeSetor = '';    
    searchFilter.situacaoInativoFilter = 'N';
    this.setState({searchFilter: searchFilter });       
   }
  

  render(){
    const {setores} = this.state;
    const {searchFilter} = this.state;
    return(
      <div style={this.estilo}>
        <Button
          component={Link}
          to="/financas/setores/novo"
          variant="contained"
          color="primary"
          style={this.estilo.addButton}
        >
          Novo
        </Button>
        <Paper 
            hover
        >
            <Input 
              autoFocus
              id="nomeSetor"
              name="nomeSetor"
              onBlur = {this.onInputBlur}
              onChange={this.OnChangeSearch} 
              placeholder="Nome setor a pesquisar"
              style={{marginBottom: 10, width: 300, marginLeft:10, marginTop:10}}
              value = {searchFilter.nomeSetor} 
            />

        <Select
          id="situacaoInativoFilter"
          name="situacaoInativoFilter"
          style={{marginLeft: 20}}
          value={searchFilter.situacaoInativoFilter || ''}
          onChange={this.OnChangeSearch}
        >
          <MenuItem value={ANAO}>Ativos</MenuItem>
          <MenuItem value={ASIM}>Inativos</MenuItem>
          <MenuItem value={'T'}>Todos</MenuItem>
        </Select>

            <Button
              color="primary"
              id="pesquisar"
              onClick={() => this.loadSetores()}
            >
              <SearchIcon />
            </Button>

            <Button
              color="primary"
              onClick={() => this.LimpaFiltros()}
            >
              <ClearAllIcon/>
            </Button>

          </Paper>


        <Card>
          <CardContent>
            <PerfectScrollbar>
              <div >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Código</TableCell>
                      <TableCell>Setor</TableCell>
                      <TableCell>Ações</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {setores.slice(0, 100).map(setor => (
                      <TableRow
                        hover
                        key={setor.codsetor}
                      >
                        <TableCell style={(setor.inativo === ASIM) ? this.estilo.redText : {}}>{setor.codsetor}</TableCell>
                       
                        <TableCell style={(setor.inativo === ASIM) ? this.estilo.redText : {}} >{setor.descsetor}</TableCell>
                        
                        <TableCell>
                          <Link to={`/financas/setores/${setor.codsetor}`}>
    <                       EditOutlinedIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                          </Link>
                          <Link to={`/financas/setores/${setor.codsetor}/view`}>
    <                       PageviewOutlinedIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                          </Link>
    <                       DeleteForeverOutlinedIcon 
                              onClick={() => this.handleOpen(setor.codsetor)}
                              style={{ cursor: 'pointer', marginRight: '10px' }} />
                        </TableCell>
                        
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
        </Card>        

        <DeleteConfirmationModal
          open={this.state.open}
          handleClose={() => this.handleClose()}
          itemId={this.state.selectedId}
          message={"Confirma exclusão do setor " + this.state.selectedId + '?'}
          handleDelete={()=> this.handleDelete(this.state.selectedId)}
      />
      <AlertInformation
        open={this.state.openAlert}
        handleClose={() => this.handleCloseAlert()}
        message={this.state.messageAlert}
        />
      </div>
    );
  }
}
export default SetorList;
