import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/styles';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'left',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 10
  },
  margin: {
    marginLeft : theme.spacing(2)
  },
  
}));



const ComboMes = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  
  function Meses () {
    const meses = [
      {id: 1, descMes: 'Janeiro'},
      {id: 2, descMes: 'Fevereiro'},
      {id: 3, descMes: 'Março'},  
      {id: 4, descMes: 'Abril'},
      {id: 5, descMes: 'Maio'},
      {id: 6, descMes: 'Junho'},
      {id: 7, descMes: 'Julho'},
      {id: 8, descMes: 'Agosto'},
      {id: 9, descMes: 'Setembro'},
      {id: 10, descMes: 'Outubro'},
      {id: 11, descMes: 'Novembro'},
      {id: 12, descMes: 'Dezembro'}                                                          
    ];

    const listMeses = meses.map(
      (mes) => {
        return (
          <option
            key={mes.id} 
            value={mes.id}
          >{mes.descMes}</option>
        )
      }
    )

    return(
      <div>
        
        <InputLabel 
          htmlFor="outlined-age-native-simple"
        >Mês</InputLabel>
        <Select 
          inputProps={{
            id: 'outlined-age-native-simple',
            name: 'cmbMes'
          }}
          label="nomemes"
          native
          onChange={rest.onChange}
          size
          value={rest.mes}
        >
          {listMeses}
        </Select>
      </div>
    )
  }


  return (
    <Meses/>
    
  );
};

ComboMes.propTypes = {
  className: PropTypes.string
};

export default ComboMes;