import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import api from '../../services/api';
import { OperacionalOS, OperacionalNumeros, OperacionalGraficoPorTipoPax,OperacionalValoresMedio } from './components';
import ComboMes from '../Components/ComboMes';
import ComboAno from '../Components/ComboAno';
import ComboMoeda from '../Components/ComboMoeda';
import { ASIM, ATIPO_EXTRA, ATIPO_FATURADO, ATIPO_TODOS } from '../../consts';

//import SplitButton from'views/Components/ButtonGroupExtraFat/ButtonGroupExtraFat';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const MenuOperacional = () => {
  const classes = useStyles();

  /*var aano = sessionStorage.getItem('anoComboProd');
  if (aano == undefined){
    aano : new Date().getFullYear();
  } */

  const [ano, setAno] = useState({
    //ano : new Date().getFullYear()
    ano : sessionStorage.getItem('anoComboProd')
    //ano : aano
  });

  const [mes, setMes] = useState({
    mes : new Date().getMonth() + 1 // esta funcao comeca com 0 zero janeiro
  });

  const [moeda, setMoeda] = useState({
    moeda : sessionStorage.getItem('moeda')
  });


  const handleChange = event => {
    event.persist();

    if (event.target.name === 'cmbAno'){
      console.log('setará o ano no evento handlechange' + event.target.value )
      setAno({ano : event.target.value});
      sessionStorage.setItem('anoComboProd', event.target.value);    
    }
    if (event.target.name === 'cmbMes'){
      //console.log('setará o mes' + event.target.value )
      setMes({mes : event.target.value});
      sessionStorage.setItem('mesComboProd', event.target.value);          
    }
    if (event.target.name === 'cmbMoeda'){
      setMoeda({moeda : event.target.value});
      sessionStorage.setItem('moeda', event.target.value);          
    }


    window.location.reload(); 
  }

  /* Indicadores DashBoard */
  const [operacionalOS, setOperacionalOS] = useState({
    qtdeOrcamento : 0,
    qtdeOS : 0,
    qtdePaxADT : 0,
    qtdePaxCHD : 0,
    qtdePaxINF : 0,    
    qtdePaxSEN : 0,        
    qtdeServicos : 0,            
    qtdeEscala : 0,                
    valorMedioOrcamento: 0,
    valorMedioOS : 0,
    valorMedioPax : 0,
    valorMedioServico :0, 
    custoMedioEscala :0
  });

  useEffect(() => {
    /* se não tiver ano definido setará ano atual */
    if (sessionStorage.getItem('anoComboProd') == null){
      sessionStorage.setItem('anoComboProd', new Date().getFullYear())   
    }
    /* se não tiver mes definido setará ano atual */
    if (sessionStorage.getItem('mesComboProd') == null){
      sessionStorage.setItem('mesComboProd', new Date().getMonth() + 1);   
    }
    async function loadOperacional  (){    
    const ano = sessionStorage.getItem('anoComboProd');
    const mes = sessionStorage.getItem('mesComboProd');
    const codEmpresa = sessionStorage.getItem('codEmpresa');
    const tipoExtraFat = sessionStorage.getItem('mod-ger-tipoExtraFat');
    const moeda = sessionStorage.getItem('moeda'); 
    const paramsquery = 'codempresa='+  codEmpresa +'&mes='+ mes + '&ano=' +ano + '&tipoExtraFat=' + tipoExtraFat +
      '&moeda=' + moeda;
    const aURL = '/gerencial/operacional?' + paramsquery;
    console.log(aURL);
  
      const response = await api.get(aURL)                             
        .then(function (response) {
          console.log(response)
          setOperacionalOS({qtdeOrcamento : response.data.qtdeOrcamento, 
            qtdeOS : response.data.qtdeOS,
            qtdePaxADT : response.data.qtdePaxADT,
            qtdePaxCHD : response.data.qtdePaxCHD,
            qtdePaxINF : response.data.qtdePaxINF,            
            qtdePaxSEN : response.data.qtdePaxSEN, 
            qtdeServicos : response.data.qtdeServicos,             
            qtdeEscala : response.data.qtdeEscala,                         
            valorMedioOS : response.data.valorMedioOS,
            valorMedioOrcamento : response.data.valorMedioOrcamento,
            valorMedioPax : response.data.valorMedioPax,
            valorMedioServico : response.data.valorMedioServico,
            custoMedioEscala : response.data.custoMedioEscala
          });   
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });       
    }
  
    loadOperacional();
  
  }, []);  


  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
      >
        
        <Grid
          item
          lg={2}
          sm={6}
          xl={3}
          xs={12}
        >
          <ComboMes
            mes ={sessionStorage.getItem('mesComboProd')}
            onChange={handleChange}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <ComboAno
            ano={sessionStorage.getItem('anoComboProd')}
            onChange={handleChange}
          />

        </Grid>
        <Grid
          item
          // lg={3}
          // sm={6}
          // xl={3}
          // xs={12}
        >
          <ComboMoeda    
            name={"cmbMoeda"}
            label={"nomemoeda"}
            value={sessionStorage.getItem('moeda')}
            onChange={handleChange}
            visible={(sessionStorage.getItem('trabOutraMoeda') === ASIM)}
          />
        </Grid>
  
      </Grid>


      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <OperacionalOS
            qtdeOrcamento={operacionalOS.qtdeOrcamento}  
            qtdeOS ={operacionalOS.qtdeOS}
            qtdeEscalas ={operacionalOS.qtdeEscala}
          />            

        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <OperacionalNumeros
            qtdeOrcamento ={operacionalOS.qtdeOrcamento}
            qtdeOS ={operacionalOS.qtdeOS}            
            qtdeTotalPax ={operacionalOS.qtdePaxADT + operacionalOS.qtdePaxCHD +
                           operacionalOS.qtdePaxINF + operacionalOS.qtdePaxSEN}                        
            qtdeServicos ={operacionalOS.qtdeServicos}                                    
            qtdeEscalas ={operacionalOS.qtdeEscala}                                                
          />        
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <OperacionalValoresMedio
            valorMedioOrcamento ={operacionalOS.valorMedioOrcamento}
            valorMedioOS ={operacionalOS.valorMedioOS}            
            valorMedioPax ={operacionalOS.valorMedioPax}                        
            valorMedioServico ={operacionalOS.valorMedioServico}                                    
            custoMedioEscala ={operacionalOS.custoMedioEscala}             
          />        
        </Grid>


        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <OperacionalGraficoPorTipoPax
            qtdePaxADT ={operacionalOS.qtdePaxADT}
            qtdePaxCHD ={operacionalOS.qtdePaxCHD}            
            qtdePaxINF ={operacionalOS.qtdePaxINF}                        
            qtdePaxSEN ={operacionalOS.qtdePaxSEN}                                    
          />
        </Grid>

      </Grid>


    </div>
  );
};

export default MenuOperacional;
