import api from '../../../services/api';React
import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {CardContent,  FormGroup, TextField, FormControl, FormHelperText, FormLabel, RadioGroup, Radio, Checkbox, Divider, colors } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Switch, FormControlLabel } from '@material-ui/core';
import { ANAO, ASIM } from 'consts';
import AlertInformation from '../../../components/AlertInformation/AlertInformation';
import moment from 'moment';

class FeriadoForm extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      feriado: {},
      isNewRecord : false,
      isView : false,
      schema : {
        usuario: {
          presence: { allowEmpty: false, message: 'é obrigatório' },
          length: {
            maximum: 64
          }
        },
      },
      
      useStyles : makeStyles(theme => ({
        root: {
          backgroundColor: theme.palette.background.default,
          height: '100%'
        },
        grid: {          
          height: '100%'
        },
        quoteContainer: {
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        },
        quote: {
          backgroundColor:  theme.palette.neutral,
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: 'url(/images/auth.jpg)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        },
        quoteInner: {
          textAlign: 'center',
          flexBasis: '600px'
        },
        quoteText: {
          color: theme.palette.white,
          fontWeight: 300
        },
        name: {
          marginTop: theme.spacing(3),
          color: theme.palette.white
        },
        bio: {
          color: theme.palette.white
        },
        contentContainer: {},
        content: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        },
        contentHeader: {
          display: 'flex',
          alignItems: 'center',
          paddingTop: theme.spacing(5),
          paddingBototm: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2)
        },
        logoImage: {
          marginLeft: theme.spacing(4)
        },
        contentBody: {
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
          }
        },
        form: {
          paddingLeft: 100,
          paddingRight: 100,
          paddingBottom: 125,
          flexBasis: 700,
          [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
          }
        },
        title: {
          marginTop: theme.spacing(3)
        },
        socialButtons: {
          marginTop: theme.spacing(3)
        },
        socialIcon: {
          marginRight: theme.spacing(1)
        },
        sugestion: {
          marginTop: theme.spacing(2)
        },
        textField: {
          marginTop: theme.spacing(2)
        },
        signInButton: {
          margin: theme.spacing(2, 0)
        },
        alert: {
          color: '#000000',
        }
      })),
    };
  }

  async componentDidMount(){    
    // carrega informações formulário
    const { id } = this.props.match.params;
    const { location } = this.props;
    const currentPath = location.pathname;    
    
    if (id >= 0) {
      const response = await api.get(`/feriado/${id}`);
      
      response.data[0].data = moment(response.data[0].data).format("YYYY-MM-DD");
  
      this.setState({ feriado: response.data[0],
        isNewRecord : false,
        isView : (currentPath.includes('/view'))}
      );      
      
    }    
    else
    if ( id === 'novo') 
    {
      this.setState({ 
        isNewRecord : true
      });
      this.state.isNewRecord = true;
      const { feriado } = this.state;
    }
  }
  
  // Change feriado
  OnChange = (event) => {  
    const { feriado } = this.state;
    const input = event.target;
    let value = input.value.toUpperCase();
    feriado[event.target.name] = value;

    if (input.name === 'inativo') {
      value = input.checked;
      if (value === false){
        feriado.inativo = ASIM;
      }
      else
      {
        feriado.inativo = ANAO;
      }
    }

    if (input.name === 'datafixa') {
      value = input.checked;
      if (value === false) {
        feriado.datafixa = ANAO;
      } else {
        feriado.datafixa = ASIM;
      }
    }

    if (input.name === 'tipoferiado'){
      feriado.tipoferiado = parseInt(feriado.tipoferiado);
    }

    if (input.name === 'rh') {
      value = input.checked;
      if (value === false) {
        feriado.rh = ANAO;
      } else {
        feriado.rh = ASIM;
      }
    }

    this.setState({ feriado: feriado });   
  };

  /* atualiza dados de feriado existente - post */
  atualizaFeriado = async () =>{
    const { feriado } = this.state;

    feriado.descferiado = feriado.descferiado.trim();       

    try {
      const response = await api.post(`/feriado/${feriado.codferiado}`, feriado);
      if (response.status === 200) {
        this.props.history.push('/financas/feriados');
      }
    } catch (error) {
      this.handleOpenAlert(error.response.data.error);
    }
  }

  /* abre janela de alerta com mensagem */
  handleOpenAlert = (message) => {
    this.setState({ openAlert: true });
    this.setState({ messageAlert: message});
  };

  /* fecha janela mensagem alerta */
  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  }

  /* Click salvar */
  onClickSave = () =>{
    if (this.validationRequiredFields()){
      if (this.state.isNewRecord){
        this.insereFeriado()
      }
      else
      {
       this.atualizaFeriado();
     }
    }
  }

  /* Click cancelar */
  onClickCancel = () =>{
    this.props.history.push('/financas/feriados');
  }


  /* verifica se tem erro campos atendimento */
  hasErrorField = (field, value) => {
    if (field === 'descferiado') {
      if ((String(value).trim() === ''))
      {
        return true
      }
    }
  }

  /* valida campos obrigatórios ao salvar */
  validationRequiredFields() {
    const { feriado } = this.state

    if( (String(feriado.data).trim() === '')  || (feriado.data === undefined)){
      feriado.data = '';
      this.setState({feriado: feriado });
      return false
    } else 
    if( (String(feriado.descferiado).trim() === '')  || (feriado.descferiado === undefined)){
        feriado.descferiado = '';
      this.setState({feriado: feriado });
      return false
    } else 
    if((feriado.tipoferiado === 0) || (feriado.tipoferiado === undefined)){
        feriado.tipoferiado = 0;
      this.setState({feriado: feriado });
      return false
    } else 
    {
      return true
    }  
  }

  /* insere novo feriado */
  insereFeriado = async () =>{
    const { feriado } = this.state;
    feriado.codusuarioregistrou = parseInt(sessionStorage.getItem('codUser'));
    
    feriado.descferiado = feriado.descferiado.trim();
    
    try {
      const response = await api.post(`/feriado`, feriado);
      if (response.status === 200) {
        this.props.history.push('/financas/feriados');
      }
    } catch (error) {
      this.handleOpenAlert(error.response.data.error);
    }
  }

  render() {
    const { feriado } = this.state;
    return (
      <div>
        <CardContent>
          <Typography 
            component="h1"
            variant="h3"
          >
            Feriado {feriado.codferiado} 
          </Typography>  

          <FormGroup row>
            <TextField
              InputLabelProps={{ shrink: true, required: false }}
              inputProps={{ maxLength: 50 }}
              label="Data"
              name="data"
              onChange={this.OnChange}                     
              style={{marginTop : 20, width : 150 }}
              type="date"            
              value={feriado.data || ''}
              variant="outlined"
              helperText={feriado.data === '' ? 'Data obrigatória' : ' '}
              disabled={this.state.isView}
            />

            <FormControlLabel
              style={{marginLeft : 20, marginTop : 15 }}
              control={
                <Checkbox
                  checked={(feriado.datafixa === ASIM)}
                  name="datafixa"
                  onChange={this.OnChange}
                  color="primary"
                  disabled={this.state.isView}
                />} 
              label={'Data fixa'} 
            />
          </FormGroup>          
          
          <FormGroup>
            <TextField
              className={this.state.useStyles.textField}
              label="Nome do feriado"
              style={{ marginTop: 20 }} 
              name="descferiado"
              value = {feriado.descferiado || ''}
              variant="outlined"
              onChange={this.OnChange}
              helperText={feriado.descferiado === '' ? 'Nome do feriado obrigatório' : ' '}
              disabled={this.state.isView}
              inputProps={{ maxLength: 100 }}
            />
          </FormGroup>

          <FormControlLabel
            control={
              <Switch
                checked={!(feriado.inativo === ASIM)}
                name="inativo"
                onChange={this.OnChange}
                color="primary"
                disabled={this.state.isView}
              />
            }
            label={(feriado.inativo === ASIM) ? 'Inativo' : 'Ativo'}
          />
          
          <br />
          <br />

          <FormControl disabled={this.state.isView} style={{marginTop : 20}}>
            <FormLabel>Tipo</FormLabel>
            <RadioGroup
                row
                name="tipoferiado"
                value = {feriado.tipoferiado || 0}
                onChange={this.OnChange}
            >
                <FormControlLabel 
                  checked={parseInt(this.state.feriado.tipoferiado) === 1}
                  value={1} 
                  control={<Radio />} 
                  label="Nacional" />
                <FormControlLabel 
                  checked={parseInt(this.state.feriado.tipoferiado) === 2}
                  value={2} 
                  control={<Radio />} 
                  label="Estadual" />
                <FormControlLabel 
                  checked={parseInt(this.state.feriado.tipoferiado) === 3}
                  value={3} 
                  control={<Radio />} 
                  label="Municipal" />
                <FormControlLabel 
                  checked={parseInt(this.state.feriado.tipoferiado) === 4}
                  value={4} 
                  control={<Radio />} 
                  label="Outro" />
            </RadioGroup>
            <FormHelperText>{feriado.tipoferiado === 0 ? 'Tipo de feriado obrigatório' : ' '}</FormHelperText>
          </FormControl>
          
          <br />

          <FormControlLabel
            control={
              <Checkbox
                checked={(feriado.rh === ASIM)}
                name="rh"
                onChange={this.OnChange}
                color="primary"
                disabled={this.state.isView}
              />} 
            label={'Utiliza em RH'} 
          />          
          
          <br />
          <br />

          <Button
            name="salvar"
            color="primary"
            variant="contained"
            onClick={this.onClickSave}
            style={{ marginTop: '10px' }} 
            disabled={this.state.isView}
          >
            Salvar
          </Button>

          <Button
            name="cancelar"
            color="primary"
            variant="contained"
            onClick={this.onClickCancel}
            style={{ marginTop: '10px', marginLeft: '10px' }}
          >
            Cancelar
          </Button>
        </CardContent>

        <AlertInformation
          open={this.state.openAlert}
          handleClose={() => this.handleCloseAlert()}
          message={this.state.messageAlert}
        />
      </div>
    );
  }
}

FeriadoForm.propTypes = 
{
  classes: PropTypes.object.isRequired
};

export default FeriadoForm;