import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/styles';
import api from 'services/api';
import { ACREDITO, ADEBITO } from 'consts';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'left',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 10
  },
  margin: {
    marginLeft : theme.spacing(2)
  },
  
}));

const ComboHistorico = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const [historicosx, setHistoricos] = useState({
    historicos : []
  });

  useEffect(() => {
    async function loadHistoricos () {
      let params = '';

      if (rest.tipocreddeb == ACREDITO) {
        params += '?tbhistorico.indicadc=' + ACREDITO
      } else 
      if (rest.tipocreddeb == ADEBITO) {
        params += '?tbhistorico.indicadc=' + ADEBITO
      }

      try {
        const response = await api.get('/historico' + params);
        if (response.status === 200) {
          setHistoricos({historicos : response.data});

          return response.data; 
        }
      } catch (error) {
        console.log(error);
      }
    }
  
    loadHistoricos();
  }, []);

  function Historicos () {
    var listHistoricos = historicosx.historicos;
    
    return(
      <div>
        <Select
          fullWidth
          inputProps={{
            id: 'outlined-age-native-simple',
            name: rest.name
          }}
          label={rest.label}
          native
          onChange={rest.onChange}
          value={rest.value}
        >
          <option 
            aria-label="Não Definido" 
            value="0" 
          />
          {listHistoricos.map(historico =>(
            <option 
              key={historico.codigo} 
              value={historico.codigo}
            > 
              {historico.descricao}</option>
          ))}
        </Select>
      </div>
    )
  }

  return (
    <Historicos/>
  );
};

ComboHistorico.propTypes = {
  className: PropTypes.string
};

export default ComboHistorico;