import AppBar from '@material-ui/core/AppBar';
import api from '../../../services/api';
import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Box from '@material-ui/core/Box';
import {CardContent, FormLabel, FormGroup, TableCell, 
  TableBody, TableHead, TableRow, Table, Input, Button,  
  Paper, ButtonGroup, Checkbox } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';  
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ButtonA2X from '../../Components/Bottombar'
import DialogActions from '@material-ui/core/DialogActions';  
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import FormHelperText from '@material-ui/core/FormHelperText';
import { AVOO_CHEGADA, AVOO_PARTIDA, ANAO } from  '../../../consts';  
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CheckIcon from '@material-ui/icons/Check';
import { Link } from 'react-router-dom';
import { AIDIOMA_PORTUGUES, DESC_IDIOMA_PORTUGUES, AIDIOMA_ESPANHOL, DESC_IDIOMA_ESPANHOL, 
  AIDIOMA_INGLES, DESC_IDIOMA_INGLES, AIDIOMA_FRANCES, DESC_IDIOMA_FRANCES, AIDIOMA_CHINES, 
  DESC_IDIOMA_CHINES, AIDIOMA_JAPONES, DESC_IDIOMA_JAPONES, AIDIOMA_RUSSO, DESC_IDIOMA_RUSSO, 
  AIDIOMA_ALEMAO, DESC_IDIOMA_ALEMAO, AIDIOMA_BILINGUE, DESC_IDIOMA_BILINGUE, AIDIOMA_ITALIANO, 
  DESC_IDIOMA_ITALIANO, AIDIOMA_COREANO, DESC_IDIOMA_COREANO, AIDIOMA_POLONES, DESC_IDIOMA_POLONES,
  AIDIOMA_GREGO, DESC_IDIOMA_GREGO, AIDIOMA_MANDARIM, DESC_IDIOMA_MANDARIM, AIDIOMA_CANTONES, 
  DESC_IDIOMA_CANTONES } from  '../../../consts';  
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function TabContainer(props) {
  return (
    <Typography 
      component="div" 
      style={{ padding: 8 * 3 }}
    >
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};


//class ScrollableTabsButtonAuto extends React.Component {
class OS  extends Component{  

  constructor(props) {
    super(props);
    this.state = {
      os: {},
      clientes: [],
      produtos: [],
      modulos: [],
      hoteis: [],
      voosIN: [],
      voosOUT: [],
      vendedores : [],
      eventos: [],
      paxsOS: [],
      canaisVenda :[],
      itensOS: [],
      empresas: [],
      configuracoes: {},

      showProgressBar : true,      
      showProgressBarPreLiberacao : true,
      modalIsOpenNewSeq : false,
      modalIsOpenClosing : false,
      modalIsOpenTransfer : false,    
      modalDeletionAttachmentIsOpen : false,        
      modalDeletionPreLiberacaoIsOpen : false,
      count: 0,
      activetab: 0,
      isNewRecord : false,
      filesPreLiberacao: [],
      progressPreLiberacao : 0,
      countPreLiberacao: 0,
      totalPagar : 0,
      schema : {
        usuario: {
          presence: { allowEmpty: false, message: 'é obrigatório' },
          length: {
            maximum: 64
          }
        },
        senha: {
          presence: { allowEmpty: false, message: 'é obrigatória' },
          length: {
            maximum: 8, message: 'máximo 8 caracteres'
          }
        }
      },
      

      useStyles : makeStyles(theme => ({
        root: {
          backgroundColor: theme.palette.background.default,
          height: '100%'
        },
        grid: {
          height: '100%'
        },
        quoteContainer: {
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        },
        quote: {
          backgroundColor: theme.palette.neutral,
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: 'url(/images/auth.jpg)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        },
        quoteInner: {
          textAlign: 'center',
          flexBasis: '600px'
        },
        quoteText: {
          color: theme.palette.white,
          fontWeight: 300
        },
        name: {
          marginTop: theme.spacing(3),
          color: theme.palette.white
        },
        bio: {
          color: theme.palette.white
        },
        contentContainer: {},
        content: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        },
        contentHeader: {
          display: 'flex',
          alignItems: 'center',
          paddingTop: theme.spacing(5),
          paddingBototm: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2)
        },
        logoImage: {
          marginLeft: theme.spacing(4)
        },
        contentBody: {
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
          }
        },
        form: {
          paddingLeft: 100,
          paddingRight: 100,
          paddingBottom: 125,
          flexBasis: 700,
          [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
          }
        },
        title: {
          marginTop: theme.spacing(3)
        },
        socialButtons: {
          marginTop: theme.spacing(3)
        },
        socialIcon: {
          marginRight: theme.spacing(1)
        },
        sugestion: {
          marginTop: theme.spacing(2)
        },
        textField: {
          marginTop: theme.spacing(2)
        },
        signInButton: {
          margin: theme.spacing(2, 0)
        },
        alert: {
          color: '#000000',
        }
      })),
    };
  }

  async componentDidMount(){
    const { id } = this.props.match.params;
    
    if (id > 0) {
      const response = await api.get(`/TControllerOS/OS/1/${id}`);
      this.setState({ os: response.data[0],
        selectedCliente : response.data[0].codCliente,
        selectedClienteFaturado : response.data[0].codClienteFaturado,        
        selectedHotel : response.data[0].codHotel_Pousada,
        selectedVooIN : response.data[0].codVooIN,
        selectedVooOUT : response.data[0].codVooOUT,        
        selectedVendedor : response.data[0].codVendedor,        
        selectedEvento : response.data[0].codEvento,                
        selectedIdioma : response.data[0].idioma,
        selectedCanalVenda : response.data[0].codCanalVenda,
        activeTab : '1',
        isNewRecord : false}
      );      
      //console.log('cliente selecionado ao abrir: ' + this.state.selectcliente)
      const clientes = await api.get('/TControllerCliente/Cliente/0');    
      this.setState({ clientes: clientes.data});        
      
      const hoteis = await api.get('/TControllerCliente/Hotel/0');    
      this.setState({ hoteis: hoteis.data});

      const voosIN = await api.get('/TControllerVoo/Voo/0/' + AVOO_CHEGADA);    
      this.setState({ voosIN: voosIN.data});

      const voosOUT = await api.get('/TControllerVoo/Voo/0/' + AVOO_PARTIDA);    
      this.setState({ voosOUT: voosOUT.data});

      const vendedores = await api.get('/TControllerVendedor/Vendedor/0');    
      this.setState({ vendedores: vendedores.data});

      const eventos = await api.get('/TControllerEvento/Evento/0');    
      this.setState({ eventos: eventos.data});      

      var aCodOS = this.state.os.codOS;
      console.log('the codOS is:' + aCodOS);

      const paxsOS = await api.get(`/TControllerPax/GetPaxByCodOS/${aCodOS}/0`);    
      this.setState({ paxsOS: paxsOS.data});            

      const canaisVenda = await api.get(`/TControllerCanalVenda/CanalVenda/${0}`);    
      this.setState({ canaisVenda: canaisVenda.data});            

      const configuracoes = await api.get(`/TControllerConfiguracoes/Configuracoes/${1}`);    
      this.setState({ configuracoes:  configuracoes.data[0]});            

      const empresas = await api.get(`/TControllerEmpresa/Empresa/${1}`);    
      this.setState({ configuracoes:  empresas.data[0]});            
      
      //console.log('trabalha com Deadline é:' + this.state.configuracoes.trabalhaComDeadLine);      

      this.loadItensOS();
    }    
    else
    // se for novo registro
    if ( id === 'novo') 
    {
      this.setState({ 
        activeTab : '1',
        isNewRecord : true
      });    
      this.state.isNewRecord = true;

      //console.log('the variable isnewrecord is: ' + this.state.isNewRecord) ; 
      //console.log('the variable isnewrecord is: ' + this.state.activeTab) ;       
      //const { os } = this.state;
      const clientes = await api.get('/TControllerCliente/Cliente/0');    
      this.setState({ clientes: clientes.data});    
      const hoteis = await api.get('/TControllerCliente/Hotel/0')
      this.setState({ hoteis: hoteis.data});    
      const voosIN = await api.get('/TControllerVoo/Voo/0/' + AVOO_CHEGADA);    
      this.setState({ voosIN: voosIN.data});
      const voosOUT = await api.get('/TControllerVoo/Voo/0/' + AVOO_PARTIDA);    
      this.setState({ voosOUT: voosOUT.data});
      const vendedores = await api.get('/TControllerVendedor/Vendedor/0');    
      this.setState({ vendedores: vendedores.data});
      const eventos = await api.get('/TControllerEvento/Evento/0');    
      this.setState({ eventos: eventos.data});            
      const canaisVenda = await api.get(`/TControllerCanalVenda/CanalVenda/${0}`);    
      this.setState({ canaisVenda: canaisVenda.data});                  

      //      
      this.setState({selectedCliente :0});
      this.setState({selectedClienteFaturado :0});      
      this.setState({selectedHotel :0});      
      this.setState({selectedVooIN :0});            
      this.setState({selectedVooOUT :0});                  
      this.setState({selectedVendedor :0});                  
      this.setState({selectedEvento :0});        
      this.setState({selectedIdioma : AIDIOMA_PORTUGUES});  
      this.setState({selectedCanalVenda : 0});  

    }
  }
 
// On change OS
OnChange = (event) => {
  const { os } = this.state;
  const input = event.target;
  var value =  input.value.toUpperCase();   
  if (event.target.name === 'obs'){
    value =  input.value;
    console.log('o valor campo observação é: ' + value)
  }
  else
  if (event.target.name === 'sugestao'){
    value =  input.value;
  }
  else
  if (event.target.name === 'email'){
    value =  input.value.toLowerCase();
  }
  
  os[event.target.name] = value;
  this.setState({os: os });   
};

// ao informar texto da sequencia
OnChangeSequence = (event) => {
  if (event.target.name === 'comQuemContatouNovaSequencia') {  
    const input = event.target;
    var value =  input.value.toUpperCase();   
    var comQuemContatouNovaSequencia = value;
    this.setState({comQuemContatouNovaSequencia : comQuemContatouNovaSequencia});
  }

  if (event.target.name === 'descricaoNovaSequencia') {
    this.setState({descricaoNovaSequencia : event.target.value});   
  }
};


/*  atualiza dados de atendimento existente - post */
atualizaAtendimentos = async () =>{
  await api.post('/TControllerAtendimentoSISSOFT/alteraOS', this.state.os)
    .then(function (response) {
      //console.log(response);
    })
    .catch(function (error) {
      //console.log(error);
    });
  this.props.history.push('/oss');
}

  handleChangeTab = (event, value) => {
    this.setState({ activetab : value });
    if (value === 1 ){
      //this.loadProcedimentosOS();
    }
    else
    if (value === 2){
      this.loadAnexos();
      //this.enabledProgressBarUpload();
      this.setState({showProgressBar: true});        
    }
    else
    if (value === 3){
      this.loadFilesPreLiberacao();
      //this.enabledProgressBarUpload();
      this.setState({showProgressBarPreLiberacao: true});        
    }    
  };

  onClick = () =>{
    //console.log('clicado...');
    if (this.state.isNewRecord){
      if (!this.validationRequiredFields()){
        this.insereOS()
      }
    }
    else
    {
      this.atualizaOS();
    }
  }

  /* change item in combo */
  handleSelectItem = (e) => {
    const { os } = this.state;
    var acampo = e.target.name;
    //console.log('nome do campo combo: ' + acampo)
  
    if (acampo === 'cliente'){    
      os['codCliente'] = e.target.value;
      var selectedCliente = e.target.value;
      //this.setState({selectcliente: e.target.value});       
      this.setState({selectedCliente: selectedCliente});           
      var index = e.nativeEvent.target.selectedIndex;
      os['nomeFantasiaCliente'] = e.nativeEvent.target[index].text;  
    }
    else
    if (acampo === 'clienteFaturado'){    
      os['codClienteFaturado'] = e.target.value;
      var selectedClienteFaturado = e.target.value;
      //this.setState({selectcliente: e.target.value});       
      this.setState({selectedClienteFaturado: selectedClienteFaturado});           
      var index = e.nativeEvent.target.selectedIndex;
      os['nomeFantasiaClienteFaturado'] = e.nativeEvent.target[index].text;  
    }
    else
    if (acampo === 'vendedor'){    
      os['codVendedor'] = e.target.value;
      var selectedVendedor = e.target.value;
      //this.setState({selectcliente: e.target.value});       
      this.setState({selectedVendedor: selectedVendedor});           
      var index = e.nativeEvent.target.selectedIndex;
      os['nomeVendedor'] = e.nativeEvent.target[index].text;  
    }
    else
    if (acampo === 'hotel'){    
      os['codHotel_Pousada'] = e.target.value;
      var selectedHotel = e.target.value;
      this.setState({selectedHotel: selectedHotel});           
      var index = e.nativeEvent.target.selectedIndex;
      os['nomeHotel'] = e.nativeEvent.target[index].text;  
    }
    else
    if (acampo === 'evento'){    
      os['codEvento'] = e.target.value;
      var selectedEvento = e.target.value;
      //this.setState({selectcliente: e.target.value});       
      this.setState({selectedEvento: selectedEvento});           
      var index = e.nativeEvent.target.selectedIndex;
      os['descEvento'] = e.nativeEvent.target[index].text;  
    }
    else
    if (acampo === 'idioma'){    
      os['idioma'] = e.target.value;
      var selectedIdioma = e.target.value;
      this.setState({selectedIdioma: selectedIdioma});           
    }
    else
    if (acampo === 'canalVenda'){    
      os['codCanalVenda'] = e.target.value;
      var selectedCanalVenda = e.target.value;
      this.setState({selectedCanalVenda: selectedCanalVenda});           
    }

    this.setState({os: os });          
  };

  
  /* radio button - mostra Extra Fat */
  handleOptionChange = (e) => {
    const { os } = this.state;
    os['mostraExtraFat'] = e.target.value;
    this.setState({os: os });      
  }

  
  /* carrega detalhes - sequencias de ordens de serviço */
  loadProcedimentosOS = async () =>{
    const response = await api.get(`/TControllerAtendimentoSISSOFT/ProcedimentoOS/0/${this.state.os.codOS}`);      
    this.setState({ procedimentosOS: response.data});
  };

  toggleModal(){
    this.setState({
      modalIsOpenNewSeq: !this.state.modalIsOpenNewSeq
    });
  //  console.log('janela modal..' + this.state.modalIsOpenNewSeq);
  }
  
  handleClose = () => {
    //setOpen(false);
  };  

  handleClickOpenNewSeq = () => {
    this.setState({
      modalIsOpenNewSeq: !this.state.modalIsOpenNewSeq });  
  };

  handleClickOpenClosing = () => {
    this.setState({
      modalIsOpenClosing: !this.state.modalIsOpenClosing});
  };

  handleClickOpenTransfer = () => {
    this.setState({
      modalIsOpenTransfer: !this.state.modalIsOpenTransfer });  
  };


  /* verifica se tem erro campos atendimento */
  hasErrorField = (field, value) => {
    if (field === 'solicitadorPor') {
      if ((String(value).trim() === ''))
      {
        return true
      }
    }

    if (field === 'codCliente'){
      if (this.state.selectedCliente === '0'){
        return true
      }
    }

    if (field === 'codProduto'){
      if (this.state.selectproduto === '0'){
        return true
      }
    }    

    if (field === 'codModulo'){
      if (this.state.selectmodulo === '0'){
        return true
      }
    }    

    if (field === 'codProjeto'){
      if (this.state.selectprojeto === '0'){
        return true
      }
    }    
    if (field === 'tipoAtendimento'){
      if (value === 0){
        return true
      }
    }    

    if (field === 'descricaoOS'){
      if ((String(value).trim() === '') || (value === undefined))
      {
        //console.log('entrou validação atendimento')
        return true
      }
    }
  }

  /* Verifica se tem erro no lançamento nova sequencia */
  hasErrorFieldNewSequence = (field, value) => {
    if (field === 'comQuemContatouNovaSequencia') {
      if ((String(value).trim() === ''))
      {
        return true
      }
    }
    if (field === 'descricaoNovaSequencia') {
      if ((String(value).trim() === ''))
      {
        return true
      }
    }
  }

  hasErrorFieldClosing = (field, value) => {
    if (field === 'comQuemContatouNovaSequencia') {
      if ((String(value).trim() === ''))
      {
        return true
      }
    }
    if (field === 'descricaoNovaSequencia') {
      if ((String(value).trim() === ''))
      {
        return true
      }
    }
  }

  hasErrorFieldTransfer = (field, value ) => {
    if (field === 'codUsuarioTransfer'){
      if (this.state.selectusuariotransfer === '0'){
        return true
      }
    }
    if (field === 'codEtapaTransfer'){
      if (this.state.selectetapatransfer === ' '){
        return true
      }
    }    
    if (field === 'descricaoTransferencia') {
      if ((String(value).trim() === ''))
      {
        return true
      }
    }
  }

  validationRequiredFields() {
    const { os } = this.state
    //console.log('the type of attendace is ' + atendimento.tipoAtendimento);
    //console.log(atendimento.contato)
    //console.log('selecionado cliente:' + this.state.selectcliente)

    if( (String(os.solicitadoPor).trim() === '') || (os.solicitadoPor === undefined) || 
        (this.state.selectedCliente === 0) || (this.state.selectedCliente === undefined) ||
        (this.state.selectproduto === 0) || (this.state.selectproduto === undefined) ||
        (this.state.selectmodulo  === 0) || (this.state.selectmodulo === undefined) ||
        (this.state.selectprojeto === 0) || (this.state.selectprojeto === undefined)
    )
    {
      //console.log('a validação está correta')
      return true
    } 
  }

  /* usado para não exibir componente*/
  getNotDisplay(show){
    if (show){
      return 'none'
    }
    else
      return ''
  }

  getTotalPagar(){
    var
      ATotal
    ATotal = 0;

    const { itensOS } = this.state;     
    {itensOS.slice(0, 100).map(item => (
      ATotal += item.totalPagar))}
    return ATotal;
  }

  /* insere nova OS */
  insereOS = async () =>{
    const { os } = this.state;
    os['codUsuarioRegistrou'] = sessionStorage.getItem('token');
    os['codUsuarioResp'] = sessionStorage.getItem('token');  
    os['situacao'] = 'A';
    os['etapa'] = 'S';  

    var dataPrazo = new Date();
    dataPrazo.setDate(dataPrazo.getDate() + 1); // incrementa um dia no horário mundial ignorando fuso horário
    os['dataPrazo'] = dataPrazo;  

    this.setState({os: os});

    var ajsonstr =  JSON.stringify(this.state.os) ;
    const response = await api.post(`/TControllerAtendimentoSISSOFT/OS`, ajsonstr)
      .then(function (response) {
        alert('Gerado OS ' + response.data.codOS);
       
      })
      .catch(function (error) {
        //console.log(error);
      });       
    this.props.history.push('/oss');
  } 

  /* salva nova sequencia de OS */
  saveTheSequence= async (_acao) =>{
    //const isValid = this.validateNewSequence(_acao); // avaliar se precisará ainda..
    //if (isValid) {
    // atualizando a situação do atendimento |1 = aberto | 2 = encerrado
    const { novoprocedimentoos } = this.state;
    const { os } = this.state;
    novoprocedimentoos['codOS'] = os.codOS;
    novoprocedimentoos['codUsuarioReg'] = sessionStorage.getItem('token');    
      

    this.setState({novoprocedimentoos: novoprocedimentoos });      

    // zera informação da descrição utilizada;
    this.setState({descricaoNovaSequencia: undefined});
    this.setState({comQuemContatouNovaSequencia : undefined});

    // fecha janela
    this.setState({modalIsOpenNewSeq: false});
    this.setState({modalIsOpenClosing: false});    
    this.setState({modalIsOpenTransfer: false});        
      
   /* var ajsonstr =  JSON.stringify(this.state.atendimento) ;
    console.log('o código do usuário responsável:' + this.state.atendimento.codUsuarioResp )
    ajsonstr = ajsonstr.substring(0, ajsonstr.length-1);
    ajsonstr = (ajsonstr +  ',  "sequencias":[' + JSON.stringify(this.state.novaseqatendimento) + ']');
    ajsonstr = ajsonstr + '}';
    ajsonstr = '[' + ajsonstr + ']'; */

    await api.post('/TControllerAtendimentoSISSOFT/RegistraProcedimentoOS', novoprocedimentoos)
      .then(function (response) {
        return true;
      })
      .catch(function (error) {
        //console.log(error);
      });       
    this.setState({activeTab : '1'});
    //}
  //}
  }


  /*  Adiciona sequência */
  moreSequence = async () =>{
    //this.saveTheSequence(tpAcao.NOVASEQUENCIA); 
    //this.setState({activeTab : 1});

    await this.saveTheSequence().then(() => {
      this.setState({activeTab : 1 });
      //console.log('deu certo..')
      //document.getElementById("tab1").click();                               
      this.loadProcedimentosOS();      
    }).catch((error) => {
      //console.log(error)
    })  


  }

  
  /* Transfere OS */
  transferOS = async () =>{
    const {os} = this.state;
    os['codUsuarioResp'] = this.state.selectusuariotransfer;
    //console.log('usuário selecionado.......' + this.state.selectusuariotransfer);
    this.setState({os : os});
    //    const ehvalido = this.validateNewSequence(tpAcao.TRANSFERENCIA);  
    //if (ehvalido){
    this.saveTheSequence(0).then(() => {
      this.props.history.push('/oss')  
    }).catch((error) => {
      //console.log(error)
    })  
    //    }
  
  }

/*  carrega anexos de atendimentos */
loadAnexos = async () => {
  //const response = await api.get(`/TControllerAtendimentoSISSOFT/AnexoOS/${this.state.os.codOS}`);      
  //this.setState({ anexosos: response.data});
  //console.log('anexos da OS: ' + this.state.anexosos);
}

/* habilita|oculta barra de progresso */
enabledProgressBarUpload(){
  this.setState({ showProgressBar: !this.state.showProgressBar});  
}

/* habilita|oculta barra de progresso */
enabledProgressBarUploadPreLiberacao(){
  this.setState({ showProgressBarPreLiberacao: !this.state.showProgressBarPreLiberacao});  
}



/*  Opção upload  de arquivos pre-liberação */
onClickHandlerUploadPreLiberacao  =   () => {
  this.enabledProgressBarUploadPreLiberacao();
  let arquivoBase64 = '';
  this.getEncodeBase64(this.state.selectedFilePreLiberacao, (result) => {
    arquivoBase64 = result;
    arquivoBase64 = arquivoBase64.split(',')[1]; // copia apartir da virgula no bloco 2 inicia com ;
    const body = {
      'arquivo' : arquivoBase64,
      'nomeArquivo' : this.state.loadedFileNamePreLiberacao,
      'codOS': this.state.os.codOS,
    };
    //console.log('the body: ' + body.arquivo);
         
    const response =   api.post('/TControllerAtendimentoSISSOFT/UploadFilePreliberacao', body)
      .then(function (response) {
      //console.log('transferido...' + response.data);
        if (response){
          //console.log('teve resposta-->>' + response.body);
          document.getElementById('arquivoUploadPreLiberacao').value = null;           
          
          //document.getElementById('progressbarupload').style = {display:false};           
          document.getElementById('tabpreliberacao').click();
        }
      })
      .catch(function (error) {
        //console.log(error);
      });       
    this.setState({selectedFilePreLiberacao : null});
  });
}

/* Codifica para base64 */
getEncodeBase64(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result)
  };
  reader.onerror = function (error) {
    //console.log('Error: ', error);
  };
}

/* ao escolher arquivo para upload */
onChangeFile=event=>{
  //console.log('event...' + event.target.files[0].name)
  this.setState({
    selectedFile: event.target.files[0],
    loaded: 0,
    loadedFileName : event.target.files[0].name
  }) 
} 

/* ao escolher arquivo para upload */
onChangeFilePreLiberacao=event=>{
  //console.log('event...' + event.target.files[0].name)
  this.setState({
    selectedFilePreLiberacao: event.target.files[0],
    loadedPreLiberacao: 0,
    loadedFileNamePreLiberacao : event.target.files[0].name
  }) 
} 


/* Habilita botão  para upload */
enabledUpload(){
  const {selectedFile} = this.state;
  if (selectedFile === null){
    return true
  }
  else
  {
    return false;
  }
}

/* Habilita botão para upload PreLiberacao */
enabledUploadPreLiberacao(){
  const {selectedFilePreLiberacao} = this.state;
  if (selectedFilePreLiberacao === null){
    return true
  }
  else
  {
    return false;
  }
}




/*  Download de Pre-Liberação de OS */
onClickHandlerDownloadPreLiberacao  =   (aFileName) => {
  this.enabledProgressBarUpload();
  //document.getElementById('progressbarupload').hidden = false;        
  //this.getBase64(this.state.selectedFile, (result) => {
  const response =  api.get(`/TControllerAtendimentoSISSOFT/DownloadPreliberacao/${this.state.os.codOS + '/' + aFileName}`)  
    .then(function (response) {
      //console.log('recebido...' + response);
      if (response){
      //console.log('teve resposta-->>' + response.data.arquivo);
      //var file = () => this.dataURLtoFile('data:text/plain;base64,' + response.data.arquivo,'hello.txt');
      //var file = () => this.dataURLtoFile('data:image/png;base64,' + response.data.arquivo,'hello.png');
      //console.log(file);
      //https://stackoverflow.com/questions/14011021/how-to-download-a-base64-encoded-image
                   
        var a = document.createElement('a'); //Create <a>
        //a.href = "data:text/txt;base64," + response.data.arquivo; //Image Base64 Goes here
        //a.href = "data:image/png;base64," + response.data.arquivo; //Image Base64 Goes here          
        //a.href = "data:image/png;base64," + response.data.arquivo; //Image Base64 Goes here                    
        a.href = 'data:' + response.data.MIME + ';base64,' + response.data.arquivo; //Image Base64 Goes here                              
          
        //a.download = "hello.png"; //File name Here
        a.download = response.data.nomeArquivo; //File name Here          
        a.click(); //Downloaded file          
        //this.enabledProgressBarUpload();
        //document.getElementById('progressbarupload').hidden = true;           
        document.getElementById('tabpreliberacao').click();
      }
    })
    .catch(function (error) {
      //console.log(error);
    });       
  //console.log('teve resposta*****' + response.data);
  this.setState({selectedFilePreLiberacao : null});
  //this.getDecodeBase64(response.data.arquivo);
//  });
}

/*  Exclusão de Arquivo Pre-Liberação  */
onClickHandleFileDeletionPreLiberacao  =   (aFileName) => {
  this.enabledProgressBarUploadPreLiberacao();
  //document.getElementById("progressbarupload").hidden = false;                  
  const url = `/TControllerAtendimentoSISSOFT/FilePreLiberacao/${this.state.os.codOS}/${aFileName}`;  
  /* const data = {
        "codProtocolo": "20000"
      } */
      
  api.delete(url)
    .then((data) =>{
      //console.log(data);
      this.toggleModalDeletePreLiberacao('');     
      document.getElementById('tabpreliberacao').click();
    })
    .catch(function (error) {
      //console.log(error);
    });       
}


/*  Exclusão de Anexos da OS */
onClickHandleFileDeletion  =   (aFileName) => {
  this.enabledProgressBarUpload();
  //document.getElementById("progressbarupload").hidden = false;                  
  const url = `/TControllerAtendimentoSISSOFT/FileOS/${this.state.os.codOS}/${aFileName}`;  
  /* const data = {
        "codProtocolo": "20000"
      } */
      
  api.delete(url)
    .then((data) =>{
      //console.log(data);
      this.toggleModalDeleteAttachment('');     
      document.getElementById('tab3').click();
    })
    .catch(function (error) {
      //console.log(error);
    });       
}
  
/* Habilita / desabilita confirmação de exclusão de arquivo pre-liberação */
toggleModalDeletePreLiberacao= (afile) => {
  // guarda arquivo selecionado
  this.setState({loadedFileNamePreLiberacao : afile});
  
  this.setState({
    modalDeletionPreLiberacaoIsOpen: !this.state.modalDeletionPreLiberacaoIsOpen });  
};


/* Habilita / desabilita confirmação de exclusão de anexo OS */
toggleModalDeleteAttachment= (afile) => {
  // guarda arquivo selecionado
  this.setState({loadedFileName : afile});
  
  this.setState({
    modalDeletionAttachmentIsOpen: !this.state.modalDeletionAttachmentIsOpen });  
};

/*  atualiza dados de atendimento existente - post */
atualizaOS = async () =>{
  await api.post('/TControllerAtendimentoSISSOFT/alteraOS', this.state.os)
    .then(function (response) {
      //console.log(response);
    })
    .catch(function (error) {
      //console.log(error);
    });
  this.props.history.push('/oss');
}

/* Carrega lista de arquivos */
loadFilesPreLiberacao = async () =>{
  const response = await api.get(`/TControllerAtendimentoSISSOFT/PreLiberacoes/${this.state.os.codOS}`);      
  this.setState({ filesPreLiberacao: response.data});
}


  /* Carrega Itens da OS */
  loadItensOS = async (opcao) =>{
    const { os } = this.state;
    var params = '';
    params = 'FIRST=500';
    params = params + '&SKIP=0';
    params = params + '&TBOS.NROOS=' + os.nroOS;

    const itensOS = await api.get('/TControllerItemOS/ItemOS/0/' + '?'+ params)
    this.setState({ itensOS: itensOS.data});
  }

render() {
  const { activetab } = this.state;
  const { os } = this.state;
  const { clientes } = this.state;
  const { hoteis } = this.state;
  const { voosIN } = this.state;  
  const { voosOUT } = this.state;    
  const { vendedores } = this.state;
  const { eventos } = this.state;  
  const { paxsOS } = this.state;  
  const { canaisVenda } = this.state;  
  const { itensOS } = this.state;    
  const { configuracoes } = this.state;
  const { empresas} = this.state;
  
    return (
      <div>
        <AppBar 
          color="default"
          position="static" 
        >
          <Tabs
            indicatorColor="primary"
            onChange={this.handleChangeTab}
            scrollButtons="auto"
            scrollable
            textColor="primary"
            value={activetab}
          >
            <Tab 
              icon={<AssignmentIcon/>}
              label="Geral" 
            />
            <Tab 
              icon={<PeopleOutlineIcon/>}
              label="Pax" 
              //style ={{ display: this.getNotDisplay(this.state.isNewRecord)}}              
            />
            <Tab 
              icon={<ReceiptIcon/>}
              id="tab3"
              label="Serviços" 
              //style ={{ display: this.getNotDisplay(this.state.isNewRecord)}}              
            />

        </Tabs>
      </AppBar>
      {/* Aba Geral */}        
      {activetab === 0 && 
          <TabContainer>
            <CardContent 
              style ={{ display: this.getNotDisplay(this.state.isNewRecord)}}
            >
              <Typography 
                variant="h3" 
              >OS {os.nroOS} 
              </Typography>
              <Typography 
                color="textSecondary" 
                component="p"  
                variant="body2"
              >
              Registrado em {os.data} por {os.usuarioRegistrou}                 
              </Typography>            
            </CardContent>  

         
            <div>

              <TextField
                className={this.state.useStyles.textField}
                error={this.hasErrorField('Nro. Reserva Cli [File]', os.nroreservaorigcli)}
                //error={atendimento.contato === ''}
                //size ={2}
                //fullWidth
                //helperText={os.solicitadorPor === '' ? 'Solicitante obrigatório' : ' '}              
                inputProps={{ maxLength: 35 }} // tamanho máximo permitido
                label="Nro. Reserva Cli [File]"
                name="nroReservaOrigCli"
                onChange={this.OnChange} 
                type="text"
                value ={os.nroReservaOrigCli || ''}
                variant="outlined"

              />

              <TextField
                className={this.state.useStyles.textField}
                error={this.hasErrorField('Titular', os.titular)}
                //error={atendimento.contato === ''}
//                size ={22}
                //fullWidth
                style={{width:'30%'}}                
                helperText={os.titular === '' ? 'Titular obrigatório' : ' '}              
                inputProps={{ maxLength: 100 }} // tamanho máximo permitido
                label="Titular"
                name="titular"
                onChange={this.OnChange} 
                type="text"
                value ={os.titular || ''}
                variant="outlined"
                width  ="200"
              />

              <TextField
                className={this.state.useStyles.textField}
                inputProps={{ maxLength: 50 }} 
                label="Deadline"
                name="deadLine"
                onChange={this.OnChange} 
                style={{ marginBottom: '1.0rem' , 
                  display: this.getNotDisplay(configuracoes.trabalhaComDeadLine === ANAO)}}                  
                type="date"
                value ={os.deadLine || 'dd/mm/yyyy'}              
                variant="outlined"
              />

            </div>

            <Box width="100%">   
              <div>
                <FormControl 
                  error={this.hasErrorField('codCliente')}
                  //variant= "filled"
                  //fullWidth
              
                  //style={{ marginTop: '1rem' }}    
                  //variant="filled" 
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-age-native-simple">Cliente</InputLabel>
                  <Select
                  //width = "200"
                    inputProps={{
                      id: 'outlined-age-native-simple',
                      name: 'cliente'
                    }}
                    label="Cliente"
                    native
                    onChange={this.handleSelectItem}
                    value={this.state.selectedCliente || ''}
                    //variant="filled" 
                    variant="filled"                    
                  >
                    <option 
                      aria-label="Não Definido" 
                      value="0" 
                    />
                    {clientes.map(cliente =>(
                      <option 
                        key={cliente.codCliente} 
                        value={cliente.codCliente}
                      > 
                        {cliente.nomeFantasia}</option>
                    ))}
                  </Select>
                  <FormHelperText>{this.state.selectedCliente === '0' ? 'Cliente obrigatório' : ' '}</FormHelperText>
                </FormControl>

                <FormControl 
                  error={this.hasErrorField('codClienteFaturado')}
                  //fullWidth
                  //style={{ marginTop: '1rem' }}    
                  variant="outlined" 
                >
                  <InputLabel htmlFor="outlined-age-native-simple">Faturamento</InputLabel>
                  <Select
                    inputProps={{
                      id: 'outlined-age-native-simple',
                      name: 'clienteFaturado'
                    }}
                    label="Cliente Faturado"
                    native
                    onChange={this.handleSelectItem}
                    value={this.state.selectedClienteFaturado || ''}
                  >
                    <option 
                      aria-label="Não Definido" 
                      value="0" 
                    />
                    {clientes.map(cliente =>(
                      <option 
                        key={cliente.codCliente} 
                        value={cliente.codCliente}
                      > 
                        {cliente.nomeFantasia}</option>
                    ))}
                  </Select>
                  <FormHelperText>{this.state.selectedClienteFaturado === '0' ? 'Cliente obrigatório' : ' '}</FormHelperText>
                </FormControl>
              </div>
            </Box>    

            <Box width="100%">    
              <TextField
                className={this.state.useStyles.textField}
                inputProps={{ maxLength: 50 }} 
                label="Data IN"
                name="dataIN"
                onChange={this.OnChange} 
                style={{ marginBottom: '1.0rem' }}                  
                type="date"
                value ={os.dataIN || 'dd/mm/yyyy'}              
                variant="outlined"
              />

              <FormControl 
                error={this.hasErrorField('codVooIN')}
                //fullWidth
                style={{width:'20%', marginLeft: '1rem'}}    
                variant="outlined" 
              >
                <InputLabel htmlFor="outlined-age-native-simple">Vôo / Rodoviário IN</InputLabel>
                <Select
                  inputProps={{
                    id: 'outlined-age-native-simple',
                    name: 'codVooIN'
                  }}
                  label="Vôo IN"
                  native
                  onChange={this.handleSelectItem}
                  value={this.state.selectedVooIN || ''}
                >
                  <option 
                    aria-label="Não Definido" 
                    value="0" 
                  />
                  {voosIN.map(voo =>(
                    <option 
                      key={voo.codVoo} 
                      value={voo.codVoo}
                    > 
                      {voo.descVoo}</option>
                  ))}
                </Select>
                <FormHelperText>{this.state.selectedVooIN === 0 ? 'Vôo obrigatório' : ' '}</FormHelperText>
              </FormControl>

              <TextField
                inputProps={{ maxLength: 50 }} // tamanho máximo permitido
                label="Hora IN"
                name="horaIN"
                onChange={this.OnChangeSearch} 
                //onBlur = {this.onInputBlur}                        
                //type="datetime-local"              
                style={{marginLeft: 20}}            
                type="Time"              
                //type="hour"              
                value ={os.horaIN || ''} 
                //variant="outlined"
              />
            </Box>    

            <Box width="100%">    
              <TextField
                inputProps={{ maxLength: 50 }} // tamanho máximo permitido
                label="Data OUT"
                name="dataOUT"
                onChange={this.OnChange} 
                //onBlur = {this.onInputBlur}                        
                //type="datetime-local"              
                //style={{marginLeft: 20}}            
                //type="date"              
                type="date"              
                //value ={os.dataOUT || ''} 
                value ={os.dataOUT || 'yyyy-mm-dd'}                                  
                variant="outlined"
              />
              
              <FormControl 
                error={this.hasErrorField('codVooOUT')}
                //fullWidth
                //style={{ marginLeft: '1rem'}}    
                style={{width:'20%', marginLeft: '1rem'}}                
                variant="outlined" 
              >
                <InputLabel htmlFor="outlined-age-native-simple">Vôo / Rodoviário OUT</InputLabel>
                <Select
                  inputProps={{
                    id: 'outlined-age-native-simple',
                    name: 'vooOUT'
                  }}
                  label="Vôo OUT"
                  native
                  onChange={this.handleSelectItem}
                  value={this.state.selectedVooOUT || ''}
                >
                  <option 
                    aria-label="Não Definido" 
                    value="0" 
                  />
                  {voosOUT.map(voo =>(
                    <option 
                      key={voo.codVoo} 
                      value={voo.codVoo}
                    > 
                      {voo.descVoo}</option>
                  ))}
                </Select>
                <FormHelperText>{this.state.selectedVooOUT === '0' ? 'Vôo OUT obrigatório' : ' '}</FormHelperText>
              </FormControl>
              

              <TextField
                inputProps={{ maxLength: 50 }} // tamanho máximo permitido
                label="Hora OUT"
                name="horaOUT"
                onChange={this.OnChangeSearch} 
                //onBlur = {this.onInputBlur}                        
                //type="datetime-local"              
                style={{marginLeft: 20}}            
                type="Time"              
                //type="hour"              
                value ={os.horaOUT || ''} 
                //variant="outlined"
              />

            </Box>    

            <Box width="100%">    
              <FormControl 
                error={this.hasErrorField('codHotel_Pousada')}
                //fullWidth
                style={{ marginTop: '1rem' }}    
                variant="outlined" 
              >
                <InputLabel htmlFor="outlined-age-native-simple">Hotel / Pousada Principal</InputLabel>
                <Select
                  inputProps={{
                    id: 'outlined-age-native-simple',
                    name: 'hotel'
                  }}
                  label="Hotel / Pousada Principal"
                  native
                  onChange={this.handleSelectItem}
                  value={this.state.selectedHotel || ''}
                >
                  <option 
                    aria-label="Não Definido" 
                    value="0" 
                  />
                  {hoteis.map(hotel =>(
                    <option 
                      key={hotel.codHotel} 
                      value={hotel.codHotel}
                    > 
                      {hotel.nomeFantasia}</option>
                  ))}
                </Select>
                <FormHelperText>{this.state.selectedHotel === '0' ? 'Hotel obrigatório' : ' '}</FormHelperText>              
              </FormControl>

              <TextField
                className={this.state.useStyles.textField}
                error={this.hasErrorField('Apto', os.apto)}
                //helperText={os.apto === '' ? 'Apartamento obrigatório' : ' '}              
                inputProps={{ maxLength: 20 }} // tamanho máximo permitido
                label="Apartamento"
                name="apto"
                onChange={this.OnChange} 
                style={{width:'15%', marginLeft: '1rem'}}
                type="text"
                value ={os.apto || ''}
                variant="outlined"
                
              />
            </Box>                


            <Box width="100%">    
              <TextField
                className={this.state.useStyles.textField}
                inputProps={{ maxLength: 20 }} // tamanho máximo permitido
                label="Fone"
                name="fone"
                onChange={this.OnChange} 
                type="text"
                value ={os.fone || ''}
                variant="outlined"
              />
              <TextField
                className={this.state.useStyles.textField}
                error={this.hasErrorField('Email', os.email)}
                helperText={os.solicitadorPor === '' ? 'E-mail obrigatório' : ' '}              
                inputProps={{ maxLength: 50 }} // tamanho máximo permitido
                label="E-mail"
                name="email"
                onChange={this.OnChange} 
                style={{width:'50%'}}
                type="text"
                value ={os.email || ''}
                variant="outlined"
              />
            </Box>                

            <Box width="100%">   
              <div>
                <FormControl 
                  error={this.hasErrorField('codEvento')}
                  style={{ display: this.getNotDisplay(configuracoes.trabalhaComEvento === ANAO || empresas.moduloCRM )}}                                                          
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-age-native-simple">Evento</InputLabel>
                  <Select
                  //width = "200"
                    inputProps={{
                      id: 'outlined-age-native-simple',
                      name: 'evento'
                    }}
                    label="Evento"
                    native
                    onChange={this.handleSelectItem}
                    style={{ display: this.getNotDisplay(configuracoes.trabalhaComEvento === ANAO)}}                                        
                    value={this.state.selectedEvento || ''}
                    variant="filled"                  
                  >
                    <option 
                      aria-label="Não Definido" 
                      value="0" 
                    />
                    {eventos.map(evento =>(
                      <option 
                        key={evento.codEvento} 
                        value={evento.codEvento}
                      > 
                        {evento.descEvento}</option>
                    ))}
                  </Select>
                  <FormHelperText>{this.state.selectedEvento === '0' ? 'Evento obrigatório' : ' '}</FormHelperText>
                </FormControl>

                <FormControl 
                  error={this.hasErrorField('codCanalVenda')}
                  style={{ marginBottom: '1rem',  width:'20%',
                    display: this.getNotDisplay(empresas.moduloCRM === ANAO)}}                  

                  variant="outlined" 
                >
                  <InputLabel htmlFor="outlined-age-native-simple">Canal de Venda</InputLabel>
                  <Select
                    inputProps={{
                      id: 'outlined-age-native-simple',
                      name: 'canalVenda'
                    }}
                    label="Canal de Venda"
                    native
                    onChange={this.handleSelectItem}
                    value={this.state.selectedCanalVenda || ''}
                  >
                    <option 
                      aria-label="Não Definido" 
                      value="0" 
                    />
                    {canaisVenda.map(canalVenda =>(
                      <option 
                        key={canalVenda.codCanal} 
                        value={canalVenda.codCanal}
                      > 
                        {canalVenda.descCanal}</option>
                    ))}
                  </Select>
                  <FormHelperText>{this.state.selectedCanalVenda === '0' ? 'Canal de venda obrigatório' : ' '}</FormHelperText>
                </FormControl>
              </div>
            </Box>    


            <Box width="100%">    
              <FormControl 
                error={this.hasErrorField('codVendedor')}
                //fullWidth
                style={{ marginTop: '1rem' }}    
                variant="outlined" 
              >
                <InputLabel htmlFor="outlined-age-native-simple">Vendedor</InputLabel>
                <Select
                  inputProps={{
                    id: 'outlined-age-native-simple',
                    name: 'vendedor'
                  }}
                  label="Vendedor"
                  native
                  onChange={this.handleSelectItem}
                  value={this.state.selectedVendedor || ''}
                >
                  <option 
                    aria-label="Não Definido" 
                    value="0" 
                  />
                  {vendedores.map(vendedor =>(
                    <option 
                      key={vendedor.codVendedor} 
                      value={vendedor.codVendedor}
                    > 
                      {vendedor.nomeVendedor}</option>
                  ))}
                </Select>
                <FormHelperText>{this.state.selectedVendedor === '0' ? 'Vendedor obrigatório' : ' '}</FormHelperText>              
              </FormControl>
            </Box>                


            <Box width="100%">    
              <TextField
                className={this.state.useStyles.textField}
                error={this.hasErrorField('Solicitante', os.solicitante)}
                helperText={os.solicitadorPor === '' ? 'Solicitante obrigatório' : ' '}              
                inputProps={{ maxLength: 50 }} // tamanho máximo permitido
                label="Solicitante"
                name="solicitante"
                onChange={this.OnChange} 
                style={{ marginBottom: '1.0rem' , 
                  display: this.getNotDisplay(configuracoes.solicitanteDaOS === ANAO)}}                  

                type="text"
                value ={os.solicitante || ''}
                variant="outlined"
              />

              <TextField
                inputProps={{ maxLength: 50 }} // tamanho máximo permitido
                label="Data Solicitação"
                name="dataSolicitacao"
                onChange={this.OnChange} 
                //onBlur = {this.onInputBlur}                        
                //type="datetime-local"              
                //style={{marginLeft: 20}}            
                //type="date"          
                style={{ marginBottom: '1.0rem' , 
                  display: this.getNotDisplay(configuracoes.solicitanteDaOS === ANAO)}}                                      
                type="date"              
                value ={os.dataSolicitacao || 'dd/mm/yyyy'} 
                variant="outlined"
              />

            </Box>                

            <FormLabel 
              component="legend"
              style={{ marginTop: '1rem'}}   
            > Observação
            </FormLabel>

            <FormGroup>
              <textarea 
                //error={this.hasErrorField('atendimento', atendimento.atendimento)}
                
                id="obs" 
                name="obs" 
                onChange={this.OnChange}
                style={{height: '100px'}} 
                type="textarea"  
                value={os.obs || ''}
              />
            </FormGroup>
          </TabContainer>}
      {/* Aba Pax */}        
      {activetab === 1 && 
          <TabContainer>
            <div >
              <Box width="100%" >    
                <FormControl 
                  error={this.hasErrorField('idioma')}
                  //fullWidth
                  ///width="10"
                  style={{width:'50%'}}                  
                  //style={{ marginTop: '1rem' }}    
                  variant="outlined" 
                >
                  <InputLabel htmlFor="outlined-age-native-simple">Idioma Padrão</InputLabel>
                  <Select
                    inputProps={{
                      id: 'outlined-age-native-simple',
                      name: 'idioma'
                    }}
                    label="Idioma"
                    native
                    onChange={this.handleSelectItem}
                    value={this.state.selectedIdioma || ''}
                  >

                    <option 
                      key={AIDIOMA_PORTUGUES} 
                      value={AIDIOMA_PORTUGUES}
                    > 
                      {DESC_IDIOMA_PORTUGUES}</option>

                    <option 
                      key={AIDIOMA_ESPANHOL} 
                      value={AIDIOMA_ESPANHOL}
                    > 
                      {DESC_IDIOMA_ESPANHOL}</option>

                    <option 
                      key={AIDIOMA_INGLES} 
                      value={AIDIOMA_INGLES}
                    > 
                      {DESC_IDIOMA_INGLES}</option>

                    <option 
                      key={AIDIOMA_FRANCES} 
                      value={AIDIOMA_FRANCES}
                    > 
                      {DESC_IDIOMA_FRANCES}</option>

                    <option 
                      key={AIDIOMA_CHINES} 
                      value={AIDIOMA_CHINES}
                    > 
                      {DESC_IDIOMA_CHINES}</option>

                    <option 
                      key={AIDIOMA_JAPONES} 
                      value={AIDIOMA_JAPONES}
                    > 
                      {DESC_IDIOMA_JAPONES}</option>

                    <option 
                      key={AIDIOMA_RUSSO} 
                      value={AIDIOMA_RUSSO}
                    > 
                      {DESC_IDIOMA_RUSSO}</option>

                    <option 
                      key={AIDIOMA_ALEMAO} 
                      value={AIDIOMA_ALEMAO}
                    > 
                      {DESC_IDIOMA_ALEMAO}</option>

                    <option 
                      key={AIDIOMA_BILINGUE} 
                      value={AIDIOMA_BILINGUE}
                    > 
                      {DESC_IDIOMA_BILINGUE}</option>

                    <option 
                      key={AIDIOMA_ITALIANO} 
                      value={AIDIOMA_ITALIANO}
                    > 
                      {DESC_IDIOMA_ITALIANO}</option>

                    <option 
                      key={AIDIOMA_COREANO} 
                      value={AIDIOMA_COREANO}
                    > 
                      {DESC_IDIOMA_COREANO}</option>

                    <option 
                      key={AIDIOMA_POLONES} 
                      value={AIDIOMA_POLONES}
                    > 
                      {DESC_IDIOMA_POLONES}</option>

                    <option 
                      key={AIDIOMA_GREGO} 
                      value={AIDIOMA_GREGO}
                    > 
                      {DESC_IDIOMA_GREGO}</option>

                    <option 
                      key={AIDIOMA_MANDARIM} 
                      value={AIDIOMA_MANDARIM}
                    > 
                      {DESC_IDIOMA_MANDARIM}</option>

                    <option 
                      key={AIDIOMA_CANTONES} 
                      value={AIDIOMA_CANTONES}
                    > 
                      {DESC_IDIOMA_CANTONES}</option>

                  </Select>
                  </FormControl>              
                  <Paper
                    style={{width:'40%'},{float: 'right'}}>

                                      
                    <Input 
                      id="qtdeADT"
                      name="qtdeADT"
                      //onChange={this.OnChangeSearch}
                      onBlur = {this.onInputBlur}
                      placeholder="ADT"
                      style={{marginTop: 15, marginLeft: 15, marginBottom: 10, width: 50}}
                      type="number"
                    />
                    <Input 
                      id="qtdeCHD"
                      name="qtdeCHD"
                      //onChange={this.OnChangeSearch}
                      onBlur = {this.onInputBlur}
                      placeholder="CHD"
                      style={{marginTop: 15, marginLeft: 15, marginBottom: 10, width: 50}}
                      type="number"
                    />
                    <Input 
                      id="qtdeINF"
                      name="qtdeINF"
                      //onChange={this.OnChangeSearch}
                      onBlur = {this.onInputBlur}
                      placeholder="INF"
                      style={{marginTop: 15, marginLeft: 15, marginBottom: 10, width: 50}}
                      type="number"
                    />
                    <Input 
                      id="qtdeSEN"
                      name="qtdeSEN"
                      //onChange={this.OnChangeSearch}
                      onBlur = {this.onInputBlur}
                      placeholder="SEN"
                      style={{marginTop: 15, marginLeft: 15, marginBottom: 10, width: 50}}
                      type="number"
                    />
                    <Button
                      color="primary"
                      id="inserePax"
                      //onClick={() => this.loadOSs()}
                    >
                      <ArrowDownwardIcon />
                    </Button>
                  </Paper>        
              </Box>                

      
              <Table className={''} aria-label="caption table">
                <caption>Pax da OS</caption>
                <TableHead>
                  <TableRow>
                    <TableCell>Seq.</TableCell>
                    <TableCell>Nome</TableCell>
                    <TableCell align="left">Tipo</TableCell>
                    <TableCell align="left">Categoria</TableCell>
                    <TableCell align="left">Data Nasc.</TableCell>
                    <TableCell align="left">Ações</TableCell>            

                  </TableRow>
                </TableHead>
                <TableBody>
                  {paxsOS.map((pax) => (
                    <TableRow key={pax.idPax}>
                      <TableCell align="left" width="5">{pax.seqPaxOS}</TableCell>
                      <TableCell align="left" width="250" >{pax.nomePax} </TableCell>
                      <TableCell align="left" width="5">{pax.descTipoPax}</TableCell>
                      <TableCell align="left" width="5">{pax.descCategoriaPax}</TableCell>
                      <TableCell align="left" width="5" >{pax.dataNasc}</TableCell>
                      <TableCell width="1">
                        {this.props.ehModal ?           
                          <Button
                            color="primary"
                            onClick={() => this.clickModalCliente(os.codOS)}                            
                          > 
                            <EditIcon/>
                          </Button>
                          : null}
                        {!this.props.ehModal ?           
                          <Link to={{ pathname: `/os/${os.codOS}`, state: { backUrl: '/os/10000'} }}>
                            <Button
                              color="primary"
                            >
                              <EditIcon
                                style ={{ display: 'none'}}/>
                            </Button>
                          </Link>
                          : null}

                        <Button
                          color="primary"
                          onClick={() => this.setState({modalDeletionIsOpen: true,
                            selectedClienteDelete : os.codOS,
                            osToDelete : os
                          })}                             
                        >
                          <DeleteOutlineIcon
                            style ={{ display: 'none'}}/>
                        </Button>

                        {this.props.showOptionSelectRecord ? 
                          <Button
                            onClick={() => this.props.lookupClienteCallBack(os.codOS)}>
                            <CheckIcon/>
                          </Button> : null}
                      </TableCell>                        
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            {/* totalizador de pax*/}
            
            <Table>
              <TableRow>
                <br />
              </TableRow>
            </Table>              
            
            <Grid 
              container spacing={1}>
              <Grid item xs={12} sm={1}>
                <Box 
                  bgcolor="teal" 
                  color="success.contrastText" 
                  p={2}>
                  ADT {os.qtdePax}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box bgcolor="secondary.main" color="secondary.contrastText" p={2}>
                  CHD {os.qtdePaxCHD}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box bgcolor="warning.main" color="warning.contrastText" p={2}>
                  INF {os.qtdePaxINF}
                </Box>
              </Grid>

              <Grid item xs={12} sm={1}>
                <Box bgcolor="success.main" color="success.contrastText" p={2}>
                  SEN {os.qtdePaxSEN}
                </Box>
              </Grid>

              <Grid item xs={12} sm={1}>
                <Box bgcolor="text.disabled" color="background.paper" p={2}>
                  Total {os.qtdePax + os.qtdePaxCHD + os.qtdePaxINF + os.qtdePaxSEN }
                </Box>
              </Grid>

            </Grid>

            
          </TabContainer>}        

      {/* Aba Serviços */}        
      {activetab === 2 && 
          <TabContainer>        
            <FormControl component="fieldset">
              <FormLabel component="legend">Mostrar</FormLabel>
              <RadioGroup 
                row aria-label="position" 
                name="position" 
                onChange={this.handleOptionChange}
                defaultValue="top">
                <FormControlLabel
                  checked={this.state.os.mostraExtraFat == 1}                  
                  control={<Radio color="primary" />}
                  label="Extras"
                  labelPlacement="top"
                  value={1}                  
                />

                <FormControlLabel
                  checked={this.state.os.mostraExtraFat == 2}
                  control={<Radio color="primary" />}
                  label="Faturados"
                  labelPlacement="top"
                  value={2}                  
                />

                <FormControlLabel
                  checked={this.state.os.mostraExtraFat == 3}
                  control={<Radio color="primary" />}
                  label="Todos"
                  labelPlacement="top"
                  value={3}                  
                />
              </RadioGroup>
            </FormControl>

            <div>
              <ButtonGroup>
                <Button 
                  color="primary" 
                  disabled={this.state.selectedOptionUser === sessionStorage.getItem('token')}
                  id="buttonLogado"
                  onClick={() => this.setOpcaoUsuario(sessionStorage.getItem('token'))} 
                >R$</Button>
                <Button 
                  color="primary" 
                  disabled={this.state.selectedOptionUser === 0}
                  id="buttonTodos"          
                  onClick={() => this.setOpcaoUsuario(0)} 
                >
                  US$
                </Button>
                <Button 
                  color="primary" 
                  disabled={this.state.selectedOptionUser === 0}
                  id="buttonTodos"          
                  onClick={() => this.setOpcaoUsuario(0)} 
                >
                  €
                </Button>
                <Button 
                  color="primary" 
                  disabled={this.state.selectedOptionUser === 0}
                  id="buttonTodos"          
                  onClick={() => this.setOpcaoUsuario(0)} 
                >
                  P$
                </Button>
                <Button 
                  color="primary" 
                  disabled={this.state.selectedOptionUser === 0}
                  id="buttonTodos"          
                  onClick={() => this.setOpcaoUsuario(0)} 
                >
                  G$
                </Button>

              </ButtonGroup>
            </div>

            <PerfectScrollbar>
                  <div >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Item</TableCell>
                          <TableCell>Fat</TableCell>
                          <TableCell>Data/Hora</TableCell>
                          <TableCell>Serviço</TableCell>                             
                          <TableCell>ADT|CHD|INF|SEN</TableCell>                                                       
                          <TableCell>Total Pagar</TableCell>                          
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {itensOS.slice(0, 100).map(item => (

                          <TableRow
                            hover
                            key={item.codItemOS}
                          >
                            <TableCell width="3" >{item.codItemOS}</TableCell>
                            <TableCell padding="checkbox">
                              <Checkbox
                                //checked={documento.marcado === ASIM ? true : false}
                                //checked={documento.marcado === ASIM ? true : false}
                                //checked={this.state.checkedDocs.indexOf(item.extraFat) !== -1}
                                color="primary"
                                /*indeterminate={
                                  selectedUsers.length > 0 &&
                                  selectedUsers.length < users.length
                                }
                                */
                                //onChange={event => this.checkUnckedDocumentoProcesso(event, 
                                //  documento.idProcesso, documento.idDocumento)}
                                //event, user.id
                              />
                            </TableCell>

                            <TableCell width="5">{item.dataServico} {item.horaInServico}</TableCell>
                            <TableCell width="5">{item.descServico}</TableCell>                                                        
                            <TableCell width="2">{item.qtdePaxADT}|{item.qtdePaxCHD}|{item.qtdePaxINF}|{item.qtdePaxSEN}</TableCell>
                            <TableCell width="5">{item.totalPagar.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</TableCell>                                                                                    
                          </TableRow>
                        ))
                        }
                      </TableBody>
                    </Table>
                  </div>
                </PerfectScrollbar>

              <Grid align="right">                                
                <Grid align="right" item xs={12} sm={2}>

                  <Box  bgcolor="secondary.main" 
                    color="background.paper" p={2}
                    style ={{ display: 'none'}}
                    >
                  Sub-Total: {1000.00}
                  </Box>
                </Grid>
              </Grid>

              <Grid align="right">                                
                <Grid align="right" item xs={12} sm={2}>
                  <Box  bgcolor="text.disabled" color="background.paper" p={2}
                  style ={{ display: 'none'}}
                  >
                  Descontos(-): {0.00}
                  </Box>
                </Grid>
              </Grid>


              <Grid align="right">                                
                <Grid align="right" item xs={12} sm={2}>
                  <Box  bgcolor="secondary.main" color="background.paper" p={2}
                  style ={{ display: 'none'}}
                  >
                  Total: {0.00}
                  </Box>
                </Grid>
              </Grid>


              <Grid align="right">                                
                <Grid align="right" item xs={12} sm={2}>
                  <Box  bgcolor="success.main" color="background.paper" p={2}
                  style ={{ display: 'none'}}>
                  Credito: {1000.00}
                  </Box>
                </Grid>
              </Grid>


              <Grid align="right">                                
                <Grid align="right" item xs={12} sm={2}>
                  <Box  bgcolor="warning.main" color="background.paper" p={2}>
                  Total a Pagar: {this.getTotalPagar().toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                  
                  </Box>
                </Grid>
              </Grid>




          </TabContainer>}        


      <div>
        <ButtonA2X  
          disabled={this.validationRequiredFields()}
          style ={{ display: 'none'}}
          onClick={() => { this.onClick()}}
        > 
            Salvar
        </ButtonA2X>
        <ButtonA2X
          onClick={this.handleClickOpenTransfer}
          //style ={{ display: this.getNotDisplay(!(this.state.activetab ==2))}}
          style ={{ display: 'none'}}          
        >
            Fechar Conta $
        </ButtonA2X>            
      </div>            
      {/* formulário modal para inserção de nova sequência */}
      <div>
        <Dialog 
          aria-labelledby="form-dialog-title"
          onClose={this.handleClose} 
          open={this.state.modalIsOpenNewSeq} 
        >
          <DialogTitle id="form-dialog-title">Sequência de atendimento</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography gutterBottom>
                  Informe abaixo o nome do contato e a descrição
              </Typography>              
            
            </DialogContentText>              
            <TextField
              autoFocus
              error={this.hasErrorFieldNewSequence('comQuemContatouNovaSequencia', this.state.comQuemContatouNovaSequencia)}
              fullWidth
              helperText={this.state.comQuemContatouNovaSequencia === '' ? 'Contato obrigatório' : ' '}              
              id="comQuemContatouNovaSequencia"
              inputProps={{ maxLength: 50 }}
              label="Nome do contato"
              name="comQuemContatouNovaSequencia"
              onChange={this.OnChangeSequence} 
              value ={this.state.comQuemContatouNovaSequencia || ''}
            />
            <div>
              <textarea 
                cols="50" 
                id="descricaoNovaSequencia" 
                name="descricaoNovaSequencia"
                onChange={this.OnChangeSequence} 
                placeholder="Descreva a sequência"
                rows="15" 
                value={this.state.descricaoNovaSequencia}
              />
            </div>
            <FormHelperText 
              error={this.hasErrorFieldNewSequence('descricaoNovaSequencia', this.state.descricaoNovaSequencia)}
            >{this.state.descricaoNovaSequencia === '' ? 'Descrição obrigatória' : ' '}
            </FormHelperText>                                                                      
          </DialogContent>
          <DialogActions>
            <ButtonA2X 
              color="secondary"
              disabled={this.state.descricaoNovaSequencia === '' || 
                         this.state.descricaoNovaSequencia === undefined ||
                         this.state.comQuemContatouNovaSequencia === '' ||
                         this.state.comQuemContatouNovaSequencia === undefined}
              onClick={this.moreSequence}                          
            >
                Salvar
            </ButtonA2X>

            <ButtonA2X 
              color="primary"
              onClick={this.handleClickOpenNewSeq} 
            >
                Cancelar
            </ButtonA2X>
          </DialogActions>
        </Dialog>
      </div>
      {/* formulário modal para encerramento de OS */}
      <div>
        <Dialog 
          aria-labelledby="form-dialog-title"
          onClose={this.handleClose} 
          open={this.state.modalIsOpenClosing} 
        >
          <DialogTitle id="form-dialog-title">Encerramento de OS</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography gutterBottom>
                  Informe abaixo o nome do contato e a descrição do encerramento
              </Typography>              
            </DialogContentText>              
            <TextField
              autoFocus
              error={this.hasErrorFieldClosing('comQuemContatouNovaSequencia', this.state.comQuemContatouNovaSequencia)}
              fullWidth
              helperText={this.state.comQuemContatouNovaSequencia === '' ? 'Contato obrigatório' : ' '}              
              id="comQuemContatouNovaSequencia"
              inputProps={{ maxLength: 50 }}
              label="Nome do contato"
              name="comQuemContatouNovaSequencia"
              onChange={this.OnChangeSequence} 
              value ={this.state.comQuemContatouNovaSequencia || ''}
            />
            <div>
              <textarea 
                cols="50" 
                id="descricaoNovaSequencia" 
                name="descricaoNovaSequencia"
                onChange={this.OnChangeSequence} 
                placeholder="Descreva o encerramento"
                rows="15" 
                value={this.state.descricaoNovaSequencia}
              />
            </div>
            <FormHelperText 
              error={this.hasErrorFieldClosing('descricaoNovaSequencia', this.state.descricaoNovaSequencia)}
            >{this.state.descricaoNovaSequencia === '' ? 'Descrição obrigatória' : ' '}
            </FormHelperText>                                                                      
          </DialogContent>
          <DialogActions>
            <ButtonA2X 
              color="secondary"
              disabled={this.state.descricaoNovaSequencia === '' || 
                         this.state.descricaoNovaSequencia === undefined ||
                         this.state.comQuemContatouNovaSequencia === '' ||
                         this.state.comQuemContatouNovaSequencia === undefined}
              onClick={this.endService}                          
            >
                Salvar
            </ButtonA2X>

            <ButtonA2X 
              color="primary"
              onClick={this.handleClickOpenClosing} 
            >
                Cancelar
            </ButtonA2X>
          </DialogActions>
        </Dialog>
      </div>
      {/* formulário modal para transferência de OS */}
      <div>
        <Dialog 
          aria-labelledby="form-dialog-title"
          onClose={this.handleClose} 
          open={this.state.modalIsOpenTransfer} 
        >
          <DialogTitle id="form-dialog-title">Transferência de OS</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography gutterBottom>
                Selecione o usuário, etapa e a descrição de transferência
              </Typography>              
            </DialogContentText>              

            <div>
              <textarea 
                cols="50" 
                id="descricaoNovaSequencia" 
                name="descricaoNovaSequencia"
                onChange={this.OnChangeSequence} 
                placeholder="Descreva o texto de transferência"
                rows="10" 
                value={this.state.descricaoNovaSequencia}
              />
            </div>
            <FormHelperText 
              error={this.hasErrorFieldTransfer('descricaoNovaSequencia', this.state.descricaoNovaSequencia)}
            >{this.state.descricaoNovaSequencia === '' ? 'Descrição obrigatória' : ' '}
            </FormHelperText>                                                                      
          </DialogContent>
          <DialogActions>
            <ButtonA2X 
              color="secondary"
              disabled={this.state.descricaoNovaSequencia === '' || 
                       this.state.descricaoNovaSequencia === undefined ||
                         this.state.selectusuariotransfer === '0' ||
                         this.state.selectusuariotransfer === undefined ||
                         this.state.selectetapatransfer === '' ||
                         this.state.selectetapatransfer === undefined 
              }
                         
                         
              onClick={this.transferOS}                          
            >
                Salvar
            </ButtonA2X>

            <ButtonA2X 
              color="primary"
              onClick={this.handleClickOpenTransfer}                 
            >
                Cancelar
            </ButtonA2X>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        <Dialog
          open={this.state.modalDeletionAttachmentIsOpen}
          //aria-labelledby="alert-dialog-title"
          //aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Confirmação de exclusão'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Confirma exclusão do anexo selecionado?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
              color="primary"
              onClick={() => this.setState({modalDeletionAttachmentIsOpen: false })} 
            >
            Não
            </Button>
            <Button 
              autoFocus
              color="primary" 
              onClick={() => this.onClickHandleFileDeletion(this.state.loadedFileName)} 
            >
            Sim
            </Button>
          </DialogActions>
        </Dialog>
      </div>        

      <div>
        <Dialog
          open={this.state.modalDeletionPreLiberacaoIsOpen}
        >
          <DialogTitle id="alert-dialog-title">{'Confirmação de exclusão'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Confirma exclusão do arquivo pré-liberação selecionado?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
              color="primary"
              onClick={() => this.setState({modalDeletionPreLiberacaoIsOpen: false })} 
            >
            Não
            </Button>
            <Button 
              autoFocus
              color="primary" 
              onClick={() => this.onClickHandleFileDeletionPreLiberacao(this.state.loadedFileNamePreLiberacao)} 
            >
            Sim
            </Button>
          </DialogActions>
        </Dialog>
      </div>        
    </div>
  );
}
}

OS.propTypes = 
{
  classes: PropTypes.object.isRequired
};
export default OS;