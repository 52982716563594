import React, { Component } from 'react';
import { Button, Card, CardContent, Table, TableCell, TableRow, TableHead, TableBody, Paper,
TextField} from '@material-ui/core';
import api from '../../../services/api';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import AlertInformation from '../../../components/AlertInformation/AlertInformation';
import { AFECHAMENTO_FINANCEIRO_CANCELADO } from 'consts';
import { CancelOutlined } from '@material-ui/icons';
import moment from 'moment';
import CancelConfirmationModal from 'components/CancelConfirmationModal/CancelConfirmationModal';

class FechamentoFinanceiroList extends Component{
  state = {
    fechamentosfinanceiros : [],
    searchFilter : {
      dataInicialFilter : '',
      dataFinalFilter : ''
    },
  };

  estilo = {
    marginLeft: 30,
    marginTop: 30,
    marginRight: 30,
    container: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    addButton: {
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      float: 'right', // alinhamento a direita
      marginTop: 3,
      marginRight: 3
    },
    redText: {
      color: 'red',
    },  
  };

  constructor(props) {
    super(props);
  }

  /* carregamento inicial */
  async componentDidMount(){
    this.loadFechamentosFinanceiros();
  }

  /* Carrega Fechamentos Financeiros */
  loadFechamentosFinanceiros = async () =>{
    const { searchFilter } = this.state;
    let params = '';
    
    /* consulta por período */
    if (!(searchFilter.dataInicialFilter === '')){
      params += '&datainicial=' + searchFilter.dataInicialFilter
    }

    if (!(searchFilter.dataFinalFilter === '')){
      params += '&datafinal=' + searchFilter.dataFinalFilter
    }

    console.log(params);

    const response = await api.get('/financas/fechamentofinanceiro?sort=datafechamento_desc,datahorafechamento_desc' + params);
    
    for (let i = 0; i < response.data.length; i++) {
      response.data[i].datafechamento = moment(response.data[i].datafechamento).format("DD/MM/YYYY");
      response.data[i].datahorafechamento = moment(response.data[i].datahorafechamento).format("DD/MM/YYYY hh:mm:ss");
    }

    this.setState({ fechamentosfinanceiros : response.data });
  }

  /* Escolha de um fechamento financeiro para cancelamento */
  handleSelectItem = (id) => {
    this.setState({selectedId: id}); 
  };

  /* cancela fechamento financeiro */
  handleCancel = async (id) => {
    try {
      const response = await api.post(`/financas/cancelafechamentofinanceiro/${id}`);
      // Verifica se a cancelamento foi bem-sucedido
      if (response.status === 200) {
        this.handleClose();
        this.loadFechamentosFinanceiros();
      }
    } catch (error) {
        this.handleClose();
        this.handleOpenAlert(error.response.data.error);
        console.log(error);
        console.log(error.message);
    }
  };

  /* abre janela confirmação para cancelamento */
  handleOpen = (codfechamentofinanceiro) => {
    this.setState({ open: true });
    this.handleSelectItem(codfechamentofinanceiro);
  };

  /* abre janela de alerta com mensagem */
  handleOpenAlert = (message) => {
    this.setState({ openAlert: true });
    this.setState({ messageAlert: message });
  };
  
  /* fecha janela de confirmação cancelamento */
  handleClose = () => {
    this.setState({ open: false });
  };

  /* fecha janela mensagem alerta */
  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  }

  /* Pesquisar filtros na tela */
  OnChangeSearch = (event) => {
    const { searchFilter } = this.state;
    let { name, value } = event.target;
    searchFilter[name] = value;
    this.setState({searchFilter: searchFilter });
  };

  /* Limpa Filtros na tela */
  LimpaFiltros = () => {
    let { searchFilter } = this.state;
    searchFilter.dataInicialFilter = '';
    searchFilter.dataFinalFilter = '';
    this.setState({searchFilter: searchFilter });       
  }

  render(){
    const { fechamentosfinanceiros } = this.state;
    const {searchFilter} = this.state;
    return(
      <div style={this.estilo}>
        <Paper hover>
          <TextField
            InputLabelProps={{ shrink: true, required: false }}
            inputProps={{ maxLength: 50 }} // tamanho máximo permitido
            label="Data inicial"
            name="dataInicialFilter"
            onChange={this.OnChangeSearch}                      
            style={{ marginLeft: 20, marginTop : 5, marginBottom : 10 }}
            type="date"   
            value ={searchFilter.dataInicialFilter || ''}
          />

          <TextField
            InputLabelProps={{ shrink: true, required: false }}
            inputProps={{ maxLength: 50 }} // tamanho máximo permitido
            label="Data final"
            name="dataFinalFilter"
            onChange={this.OnChangeSearch}               
            style={{ marginLeft: 20, marginTop : 5, marginBottom : 10 }}
            type="date"        
            value ={searchFilter.dataFinalFilter || ''}
          />

          <Button
            color="primary"
            id="pesquisar"
            onClick={() => this.loadFechamentosFinanceiros()}
            style={{ marginLeft : 20, marginTop : 16 }}
          >
            <SearchIcon />
          </Button>

          <Button
            color="primary"
            onClick={() => this.LimpaFiltros()}
            style={{ marginTop : 16 }}
          >
            <ClearAllIcon/>
          </Button>
        </Paper>

        <Card>
          <CardContent>
            <PerfectScrollbar>
              <div >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Fechado até</TableCell>
                      <TableCell>Usuário fechou</TableCell>
                      <TableCell>Fechado em</TableCell>
                      <TableCell>Cancelar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fechamentosfinanceiros.slice(0, 100).map(fechamentofinanceiro => (
                      <TableRow
                        hover
                        key={fechamentofinanceiro.chave}
                      >
                        <TableCell style={(fechamentofinanceiro.situacao === AFECHAMENTO_FINANCEIRO_CANCELADO) ? this.estilo.redText : {}}>{fechamentofinanceiro.datafechamento}</TableCell>
                       
                        <TableCell style={(fechamentofinanceiro.situacao === AFECHAMENTO_FINANCEIRO_CANCELADO) ? this.estilo.redText : {}} >{fechamentofinanceiro.nomeusuariofechou}</TableCell>
                        
                        <TableCell style={(fechamentofinanceiro.situacao === AFECHAMENTO_FINANCEIRO_CANCELADO) ? this.estilo.redText : {}} >{fechamentofinanceiro.datahorafechamento}</TableCell>
                        
                        <TableCell>
                          <CancelOutlined
                              onClick={() => this.handleOpen(fechamentofinanceiro.chave)}
                              style={{ cursor: 'pointer', marginRight: '10px' }} />
                        </TableCell>
                        
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
        </Card>

        <CancelConfirmationModal
          open={this.state.open}
          handleClose={() => this.handleClose()}
          itemId={this.state.selectedId}
          message={"Confirma cancelamento do fechamento financeiro ?"}
          handleCancel={()=> this.handleCancel(this.state.selectedId)}
        />

        <AlertInformation
          open={this.state.openAlert}
          handleClose={() => this.handleCloseAlert()}
          message={this.state.messageAlert}
        />
      </div>
    );
  }
}

export default FechamentoFinanceiroList;