import api from '../../../services/api';
import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {CardContent,  FormLabel,  FormGroup, TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Switch, FormControlLabel } from '@material-ui/core';
import { ANAO, ASIM } from 'consts';

class SetorForm  extends Component{  
  constructor(props) {
    super(props);
    this.state = {
      setor: {},
      isNewRecord : false,
      isView : false,
      schema : {
        usuario: {
          presence: { allowEmpty: false, message: 'é obrigatório' },
          length: {
            maximum: 64
          }
        },
      },
      
      useStyles : makeStyles(theme => ({
        root: {
          backgroundColor: theme.palette.background.default,
          height: '100%'
        },
        grid: {
          height: '100%'
        },
        quoteContainer: {
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        },
        quote: {
          backgroundColor: theme.palette.neutral,
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: 'url(/images/auth.jpg)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        },
        quoteInner: {
          textAlign: 'center',
          flexBasis: '600px'
        },
        quoteText: {
          color: theme.palette.white,
          fontWeight: 300
        },
        name: {
          marginTop: theme.spacing(3),
          color: theme.palette.white
        },
        bio: {
          color: theme.palette.white
        },
        contentContainer: {},
        content: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        },
        contentHeader: {
          display: 'flex',
          alignItems: 'center',
          paddingTop: theme.spacing(5),
          paddingBototm: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2)
        },
        logoImage: {
          marginLeft: theme.spacing(4)
        },
        contentBody: {
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
          }
        },
        form: {
          paddingLeft: 100,
          paddingRight: 100,
          paddingBottom: 125,
          flexBasis: 700,
          [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
          }
        },
        title: {
          marginTop: theme.spacing(3)
        },
        socialButtons: {
          marginTop: theme.spacing(3)
        },
        socialIcon: {
          marginRight: theme.spacing(1)
        },
        sugestion: {
          marginTop: theme.spacing(2)
        },
        textField: {
          marginTop: theme.spacing(2)
        },
        signInButton: {
          margin: theme.spacing(2, 0)
        },
        alert: {
          color: '#000000',
        }
      })),
    };
  }

  async componentDidMount(){
    const { id } = this.props.match.params;
    const { location } = this.props;
    const currentPath = location.pathname;
    
    if (id >= 0) {
      const response = await api.get(`/setor/${id}`);
      
      this.setState({ setor: response.data[0],
        isNewRecord : false,
        isView : (currentPath.includes('/view'))}
      );      
      
    }    
    else
    if ( id === 'novo') 
    {
      this.setState({ 
        isNewRecord : true
      });    
      this.state.isNewRecord = true;
      const { setor } = this.state;
    }
  }
  
  // Change Setor
  OnChange = (event) => {
    const { setor } = this.state;
    const input = event.target;
    let value =  input.value.toUpperCase();   
    setor[event.target.name] = value;

    if (input.type === 'checkbox') {
      value = input.checked; // Obtém o valor da propriedade checked do Switch
      if (value === false){
        setor.inativo = ASIM;
      }
      else
      {
        setor.inativo = ANAO;
      }
    }

    this.setState({setor: setor });   
  };


  /*  atualiza dados de setor existente - post */
  atualizaSetor = async () =>{
    const { setor } = this.state;

    setor.tipoempexportcontabil = 0;
    setor.codempmatrizexportctabil = 0;

    try {
      const response = await api.post(`/setor/${setor.codsetor}`, setor);
      if (response.status === 200) {
        this.props.history.push('/financas/setores');
      }
    } catch (error) {
      this.handleOpenAlert(error.response.data.error);
    }
  }

 /* Click salvar */
  onClick = () =>{
    if (this.validationRequiredFields()){
      if (this.state.isNewRecord){
        this.insereSetor()
      }
      else
      {
        this.atualizaSetor();
      }
    }
  }

  /* verifica se tem erro campos atendimento */
  hasErrorField = (field, value) => {
    if (field === 'descsetor') {
      if ((String(value).trim() === ''))
      {
        return true
      }
    }
  }

  /* valida campos obrigatórios ao salvar */
  validationRequiredFields() {
    const { setor } = this.state
    if( (String(setor.descsetor).trim() === '')  || (setor.descsetor === undefined)){
      setor.descsetor = '';
      this.setState({setor: setor });
      return false
    } 
    else
    {
      return true
    }
       
  }

  /* insere novo setor */
  insereSetor = async () =>{
    const { setor } = this.state;
    setor.codUsuarioRegistrou = parseInt(sessionStorage.getItem('codUser'));
        
    try {
      const response = await api.post(`/setor`, setor);
      this.props.history.push('/financas/setores');
    } catch (error) {
       console.log(error);
        console.log(error.message);
    }
  } 

 
  /* Click cancelar */
  onClickCancel = () =>{
    this.props.history.push('/financas/setores');
  } 

  render() {
    const { setor } = this.state;
     return (
      <div>
        <CardContent>
        <Typography 
          component="h1"
          variant="h3" 
        >
          Setor {setor.codsetor} 
        </Typography>             
          
        <FormGroup>
            <TextField
              className={this.state.useStyles.textField}
              label="Nome do setor"
              style={{ marginTop: '10px' }} 
              name="descsetor"
              value ={setor.descsetor || ''}
              variant="outlined"
              onChange={this.OnChange}
              //error={this.hasErrorField('Nome do setor', setor.descsetor)}
              helperText={setor.descsetor === '' ? 'Nome do setor obrigatório' : ' '}              
              disabled={this.state.isView}
            />
        </FormGroup>
        <FormControlLabel
      control={
        <Switch
          checked={!(setor.inativo === ASIM)}
          name="inativo"
          onChange={this.OnChange}
          color="primary"
          disabled={this.state.isView}
        />
      }
      label={(setor.inativo === ASIM) ? 'Inativo' : 'Ativo'}
    />
    <br/>
    <br/>
      <Button
        color="primary"
        variant="contained"
        onClick={this.onClick}
        style={{ marginTop: '10px' }} 
        disabled={this.state.isView}
      >
        Salvar
      </Button>
     
      <Button
        name="cancelar"
        color="primary"
        variant="contained"
        onClick={this.onClickCancel}
        style={{ marginTop: '10px', marginLeft: '10px' }}
      >
        Cancelar
      </Button>
     
      </CardContent>
    </div>
      
      
    );
  }
}

SetorForm.propTypes = 
{
  classes: PropTypes.object.isRequired
};
export default SetorForm;