import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import moment from 'moment';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage: 
      '#eaeaf0',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});


function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  let { active, completed, rotulo } = props;

  console.log('em rotulo: ' +  JSON.stringify(rotulo));

  //completed = false;
  //active = true;


  let icons = {
    1: <FlightLandIcon />,
    2: <LocalActivityIcon />,
    3: <FlightTakeoffIcon />,
  };   

  icons =[];


  let icone;
  if (rotulo == 'Chegada'){
    icone = <FlightLandIcon />
    active = true;
    completed = true;
  }
  else
  if (rotulo == 'Partida'){
    icone = <FlightTakeoffIcon />
    active = true;
    completed = true;
    
  }
  else
  {
    icone = <LocalActivityIcon />
    active = true;
    completed = true;
  
  }
  //console.log('the props.icon is ' + JSON.stringify(props));
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icone}
    
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node

  
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginBottom: theme.spacing(1),
  },
}));


/* monta as steps */
function getSteps(datain, dataout, itens) {
  let hasIN = false;
  let hasOUT = false;
  let dtin = '';
  let dtout = '';

  for (let i = 0; i < itens.length; i++) {
    if (itens[i].inout == 1){
      hasIN = true;
    }  
    if (itens[i].inout == 2){
      hasOUT = true;
    }

  }
  let steps = [];
  var iconesescolhidos = [];

  if (hasIN) {
    steps.push('Chegada');
    
    //console.log('sentando icone do voo... ');
    //iconesescolhidos.push(<FlightLandIcon/>);
  };
 
  steps.push('Serviços');
  //console.log('sentando icone de servico... ');
  iconesescolhidos.push(<LocalActivityIcon/>);

  if (hasOUT) {
    steps.push('Partida');
    iconesescolhidos.push(<FlightTakeoffIcon/>);
    
  };
  //return ['Chegada ' + datain, 'Serviços', 'Partida '+ dataout ];
  
  //console.log('o que está em iconesescolhidos: ' + JSON.stringify(iconesescolhidos.toString()));
  //setIcones(icones);
  
  // setAno(2022);

  return steps;
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return 'Select campaign settings...';
    case 1:
      return 'What is an ad group anyways?';
    case 2:
      return 'This is the bit I really care about!';
    default:
      return 'Unknown step';
  }
}



//export default function CustomizedSteppers() {
const CustomizedSteppers = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const { className, ...rest } = props;
  
  let datain  = rest.datain;
  let dataout = rest.dataout;
  
  
  datain = moment(datain).format("DD/MM/YYYY");
  dataout = moment(dataout).format("DD/MM/YYYY");
  
  
  if (dataout === undefined){
    dataout = '';
  }

  const steps = getSteps(datain, dataout, rest.itens);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  function getDataServicoStep(step){
    
    let resultado;
      switch (step) {
        case 1:
          resultado = datain;
          break;
        case 2:
          resultado = <p>Verifique sua programação abaixo</p>;
          break;
        case 3:
          resultado = dataout;
          break;
        default:
          resultado = <p></p>;
      }
    return resultado
  }

  return (
    <div className={classes.root}>
      <Stepper  activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label, index) => (
          <Step key={label}>
            
            <StepLabel StepIconComponent={() => <ColorlibStepIcon rotulo={label}/>}
            
            />
            
            <Typography className={classes.instructions}>
              {label} {getDataServicoStep(index+1)}
            </Typography> 
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              Estadia etapas completada - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reiniciar
            </Button>
          </div>
        ) : (
          <div>
          </div>
        )}
      </div>
    </div>
  );
}
CustomizedSteppers.propTypes = {
    className: PropTypes.string,
    datain : PropTypes.string,
    dataout : PropTypes.string 
  };
  
export default CustomizedSteppers; 