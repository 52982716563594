import React from 'react';
import Sidebar from '../Sidebar';
import SideBarGerencial from '../SideBarGerencial';
import SideBarFinancas from '../SideBarFinancas/SideBarFinancas';
import SideBarAppCli from '../SideBarAppCli/SideBarAppCli';
import { AMODULO_APPCLI, AMODULO_FINANCAS, AMODULO_GERENCIAL, AMODULO_RECEPTIVO } from 'consts';


const ChosenBar = (props) => {
  let { onClose} = props;
  let {variant} = props;
  let { modulo } = props;
  let { open } = props;

  
  // Declare os métodos handleSidebarOpen e handleSidebarClose aqui
  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    console.log('vai usar o handlesidebarclos')
    setOpenSidebar(false);
  };

//  const shouldOpenSidebar = isDesktop ? true : openSidebar;

        

  let componenteRenderizado;

  switch (parseInt(modulo)) {
    case AMODULO_RECEPTIVO:
      componenteRenderizado = 
      <Sidebar 
        onClose={onClose}
        open={open}
        variant={variant}
      />;
      break;
    
    case AMODULO_FINANCAS:
      componenteRenderizado = 
      <SideBarFinancas 
        onClose={onClose}
        open={open}
        variant={variant}
      />;
      break;
     
    case AMODULO_GERENCIAL:
      componenteRenderizado = 
      <SideBarGerencial 
        onClose={onClose}
        open={open}
        variant={variant}
    />;
      break;

      case AMODULO_APPCLI:
        componenteRenderizado = 
      <SideBarAppCli 
        onClose={onClose}
        open={open}
        variant={variant}
      />;
        break;
  
      default:
      componenteRenderizado = <div>nenhum menu selecionado</div>;
      break;
  }


  return (
    <div>
      {componenteRenderizado}
    </div>
  );
};

export default ChosenBar;