import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AMODULO_APPCLI, AMODULO_CRM, AMODULO_ESTOQUE, AMODULO_FINANCAS, AMODULO_GERENCIAL, AMODULO_RECEPTIVO } from 'consts';
const Logout = props => {
  const { history } = props;


  let modulo = sessionStorage.getItem('modulo');  

  console.log('o módulo localizado na saída é: ' + modulo);

  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('codModulo');   
  sessionStorage.removeItem('mesComboProd');
  sessionStorage.removeItem('anoComboProd');  
  sessionStorage.removeItem('moeda');
  sessionStorage.removeItem('trabOutraMoeda');    
  sessionStorage.removeItem('formaPDV');    
  sessionStorage.removeItem('codEmpresa');    
  sessionStorage.removeItem('mod-ger-tipoExtraFat');    
  sessionStorage.removeItem('fantasia');    
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('codUser');
  sessionStorage.removeItem('modulo');
  sessionStorage.removeItem('localizador');
  sessionStorage.removeItem('codOS');
  sessionStorage.removeItem('exportaLayoutA2x');
  sessionStorage.removeItem('nomePax');
  sessionStorage.removeItem('exportaContabil');
  sessionStorage.removeItem('identEmpCtbPeloSetor');
  
  
  console.log('o módulo é: ' + modulo);

  switch (parseInt(modulo)) {
    case AMODULO_RECEPTIVO:
      history.push('/front');
    break;
    case AMODULO_FINANCAS:
      history.push('/financas');
    break;
     
    case AMODULO_GERENCIAL:
      history.push('/gerencial');
    break;
    
    case AMODULO_ESTOQUE:
        history.push('/estoque');
    break;
    case AMODULO_CRM:
        history.push('/crm');
    break;
    case AMODULO_APPCLI:
        history.push('/appcli');
    break;

  }


  //history.push('/gerencial');

  return (
    null
  );
};

Logout.propTypes = {
  history: PropTypes.object
};

export default withRouter(Logout);
