import api from '../../../services/api';React
import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {CardContent,  FormGroup, TextField, Divider, FormControl, InputLabel, FormHelperText } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Switch, FormControlLabel } from '@material-ui/core';
import { ACREDITO, ADEBITO, ANAO, ASIM } from 'consts';
import AlertInformation from '../../../components/AlertInformation/AlertInformation';
import { colors } from '@material-ui/core';
import ComboHistorico from 'views/Components/ComboHistorico/ComboHistorico';

class TipoDocumentoForm extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      tipodocumento: {},
      isNewRecord : false,
      isView : false,
      schema : {
        usuario: {
          presence: { allowEmpty: false, message: 'é obrigatório' },
          length: {
            maximum: 64
          }
        },
      },
      
      useStyles : makeStyles(theme => ({
        root: {
          backgroundColor: theme.palette.background.default,
          height: '100%'
        },
        grid: {          
          height: '100%'
        },
        quoteContainer: {
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        },
        quote: {
          backgroundColor:  theme.palette.neutral,
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: 'url(/images/auth.jpg)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        },
        quoteInner: {
          textAlign: 'center',
          flexBasis: '600px'
        },
        quoteText: {
          color: theme.palette.white,
          fontWeight: 300
        },
        name: {
          marginTop: theme.spacing(3),
          color: theme.palette.white
        },
        bio: {
          color: theme.palette.white
        },
        contentContainer: {},
        content: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        },
        contentHeader: {
          display: 'flex',
          alignItems: 'center',
          paddingTop: theme.spacing(5),
          paddingBototm: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2)
        },
        logoImage: {
          marginLeft: theme.spacing(4)
        },
        contentBody: {
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
          }
        },
        form: {
          paddingLeft: 100,
          paddingRight: 100,
          paddingBottom: 125,
          flexBasis: 700,
          [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
          }
        },
        title: {
          marginTop: theme.spacing(3)
        },
        socialButtons: {
          marginTop: theme.spacing(3)
        },
        socialIcon: {
          marginRight: theme.spacing(1)
        },
        sugestion: {
          marginTop: theme.spacing(2)
        },
        textField: {
          marginTop: theme.spacing(2)
        },
        signInButton: {
          margin: theme.spacing(2, 0)
        },
        alert: {
          color: '#000000',
        }
      })),
    };
  }

  async componentDidMount(){
    // busca dados configurações
    let aExportaLayoutA2x = sessionStorage.getItem('exportaLayoutA2x');
    if ((aExportaLayoutA2x == null) || (aExportaLayoutA2x == undefined)) {      
      let codEmpresa = sessionStorage.getItem('codEmpresa');
      if((codEmpresa === null) || (codEmpresa === undefined)){
        codEmpresa = 1;
      }

      const field = '?field=EXPORTALAYOUTA2X';
      const aURL = '/financas/configuracoes/' + codEmpresa + field;
      const response = await api.get(aURL)                             
      .then(function (response) {
        sessionStorage.setItem('exportaLayoutA2x', response.data[0].exportalayouta2x);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
    }
    
    // carrega informações formulário
    const { id } = this.props.match.params;
    const { location } = this.props;
    const currentPath = location.pathname;    
    
    if (id >= 0) {
      const response = await api.get(`/tipodocumento/${id}`);
      
      this.setState({ tipodocumento: response.data[0],
        isNewRecord : false,
        isView : (currentPath.includes('/view'))}
      );      
      
    }    
    else
    if ( id === 'novo') 
    {
      this.setState({ 
        isNewRecord : true
      });
      this.state.isNewRecord = true;
      const { tipodocumento } = this.state;
    }
  }
  
  // Change tipo de documento
  OnChange = (event) => {  
    const { tipodocumento } = this.state;
    const input = event.target;
    let value = input.value.toUpperCase();   
    tipodocumento[event.target.name] = value;

    if (input.name === 'inativo') {
      value = input.checked;
      if (value === false){
        tipodocumento.inativo = ASIM;
      }
      else
      {
        tipodocumento.inativo = ANAO;
      }
    }

    this.setState({ tipodocumento: tipodocumento });   
  };

  /* atualiza dados de tipo de documento existente - post */
  atualizaTipoDocumento = async () =>{
    const { tipodocumento } = this.state;

    tipodocumento.desctipo = tipodocumento.desctipo.trim();    
    if (tipodocumento.codtipodocexp) 
      tipodocumento.codtipodocexp = tipodocumento.codtipodocexp.trim();
    
    if (tipodocumento.codtipodocexp == null)
      tipodocumento.codtipodocexp = '';

    try {
      const response = await api.post(`/tipodocumento/${tipodocumento.codigo}`, tipodocumento);
      if (response.status === 200) {
        this.props.history.push('/financas/tiposdocumentos');
      }
    } catch (error) {
      this.handleOpenAlert(error.response.data.error);
    }
  }

  /* abre janela de alerta com mensagem */
  handleOpenAlert = (message) => {
    this.setState({ openAlert: true });
    this.setState({ messageAlert: message});
  };

  /* fecha janela mensagem alerta */
  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  }

  /* Click salvar */
  onClickSave = () =>{
    if (this.validationRequiredFields()){
      if (this.state.isNewRecord){
        this.insereTipoDocumento()
      }
      else
      {
       this.atualizaTipoDocumento();
     }
    }
  }

  /* Click cancelar */
  onClickCancel = () =>{
    this.props.history.push('/financas/tiposdocumentos');
  }


  /* verifica se tem erro campos atendimento */
  hasErrorField = (field, value) => {
    if (field === 'desctipo') {
      if ((String(value).trim() === ''))
      {
        return true
      }
    }
  }

  /* valida campos obrigatórios ao salvar */
  validationRequiredFields() {
    const { tipodocumento } = this.state

    if( (String(tipodocumento.desctipo).trim() === '')  || (tipodocumento.desctipo === undefined)){
      tipodocumento.desctipo = '';
      this.setState({tipodocumento: tipodocumento });
      return false
    } else 
    if ( (String(tipodocumento.histdefcredito).trim() === '0')  || (tipodocumento.histdefcredito === undefined)){
      tipodocumento.histdefcredito = 0;
      this.setState({tipodocumento: tipodocumento });
      return false
    } else
    if ( (String(tipodocumento.histdefdebito).trim() === '0')  || (tipodocumento.histdefdebito === undefined)){
        tipodocumento.histdefdebito = 0;
        this.setState({tipodocumento: tipodocumento });
        return false
      } else 
    {
      return true
    }  
  }

  /* insere novo tipo de documento */
  insereTipoDocumento = async () =>{
    const { tipodocumento } = this.state;
    tipodocumento.codusuarioregistrou = parseInt(sessionStorage.getItem('codUser'));
    
    tipodocumento.desctipo = tipodocumento.desctipo.trim();
    if (tipodocumento.codtipodocexp) {
      tipodocumento.codtipodocexp = tipodocumento.codtipodocexp.trim(); 
    }
    
    try {
      const response = await api.post(`/tipodocumento`, tipodocumento);
      if (response.status === 200) {
        this.props.history.push('/financas/tiposdocumentos');
      }
    } catch (error) {
      // console.log(error);
      // console.log(error.message);
      this.handleOpenAlert(error.response.data.error);
    }
  }

  render() {
    const { tipodocumento } = this.state;
    return (
      <div>
        <CardContent>
          <Typography 
            component="h1"
            variant="h3"
          >
            Tipo de Documento {tipodocumento.codigo} 
          </Typography>             
          
          <FormGroup>
            <TextField
              className={this.state.useStyles.textField}
              label="Nome do tipo de documento"
              style={{ marginTop: '10px' }} 
              name="desctipo"
              value ={tipodocumento.desctipo || ''}
              variant="outlined"
              onChange={this.OnChange}
              helperText={tipodocumento.desctipo === '' ? 'Nome do tipo de documento obrigatório' : ' '}
              disabled={this.state.isView}
              inputProps={{ maxLength: 30 }}
            />
          </FormGroup> 

          <FormControlLabel
            control={
              <Switch
                checked={!(tipodocumento.inativo === ASIM)}
                name="inativo"
                onChange={this.OnChange}
                color="primary"
                disabled={this.state.isView}
              />
            }
            label={(tipodocumento.inativo === ASIM) ? 'Inativo' : 'Ativo'}
          />
          
          <br />
          <br />

          <Divider style={{ backgroundColor: colors.grey[400] }} />

          <Typography style={{ marginTop:'5px', color: colors.grey[600] }}>
            Históricos de lançamento padrão
          </Typography>

          <FormGroup row={true}>
            <FormControl
              // fullWidth
              style={{ marginTop: '1rem', width: '525px', marginRight: '10px' }}
              variant="outlined"
              disabled={this.state.isView}
            >
              <InputLabel htmlFor="outlined-age-native-simple">Crédito</InputLabel>

              <ComboHistorico 
                tipocreddeb={ACREDITO}
                name={'histdefcredito'}
                label={'Crédito'}
                value={tipodocumento.histdefcredito}
                onChange={this.OnChange}
              />

              <FormHelperText>{tipodocumento.histdefcredito === 0 ? 'Campo obrigatório' : ' '}</FormHelperText>
            </FormControl>

            <FormControl
              // fullWidth
              style={{ marginTop: '1rem', width: '525px', marginRight: '10px' }}
              variant="outlined" 
              disabled={this.state.isView}
            >
              <InputLabel htmlFor="outlined-age-native-simple">Débito</InputLabel>

              <ComboHistorico 
                tipocreddeb={ADEBITO}
                name={'histdefdebito'}
                label={'Débito'}
                value={tipodocumento.histdefdebito}
                onChange={this.OnChange}
              />

              <FormHelperText>{tipodocumento.histdefdebito === 0 ? 'Campo obrigatório' : ' '}</FormHelperText>
            </FormControl>

          </FormGroup>

          <Divider style={{ backgroundColor: colors.grey[400], marginTop : '10px' }} />

          <br />
  
          <FormGroup style={{ display: (sessionStorage.getItem('exportaLayoutA2x') == ASIM ? '' : 'none' ) }} >
            <TextField
              className={this.state.useStyles.textField}
              label="Código para exportação"
              style={{ width: 200, marginRight: '10px' }}
              name="codtipodocexp"
              value ={tipodocumento.codtipodocexp || ''}
              variant="outlined"
              onChange={this.OnChange}          
              disabled={this.state.isView}
              inputProps={{ maxLength: 50 }}
            />
          </FormGroup>
        
          <br/>

          <Button
            name="salvar"
            color="primary"
            variant="contained"
            onClick={this.onClickSave}
            style={{ marginTop: '10px' }} 
            disabled={this.state.isView}
          >
            Salvar
          </Button>

          <Button
            name="cancelar"
            color="primary"
            variant="contained"
            onClick={this.onClickCancel}
            style={{ marginTop: '10px', marginLeft: '10px' }}
          >
            Cancelar
          </Button>
        </CardContent>

        <AlertInformation
          open={this.state.openAlert}
          handleClose={() => this.handleCloseAlert()}
          message={this.state.messageAlert}
        />
      </div>
    );
  }
}

TipoDocumentoForm.propTypes = 
{
  classes: PropTypes.object.isRequired
};

export default TipoDocumentoForm;