import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';

import { getISOMoeda } from 'lib/functions';
const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

const AdministrativoGraficoExtrasRecebidosReceber = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();


  let totalExtrasRecebido = rest.totalExtrasRecebidos;
  let totalExtrasAReceber = rest.totalExtrasAReceber;
    

  
  const data = {
    datasets: [
      {
        data: [totalExtrasRecebido, totalExtrasAReceber],
        backgroundColor: [
          theme.palette.secondary.main,
          theme.palette.warning.main,
        ],
        borderWidth: 1,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white,
      }
    ],
    labels: ['Recebidos', 'A Receber']
  };

  const options = {
    legend: {
      display: true
    },
    responsive: true,
    maintainAspectRatio: false,
    //animation: false,
    cutoutPercentage: 70,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Extras"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Doughnut
            data={data}
            options={options}
          />
        </div>
      </CardContent>
    </Card>
  );
};

AdministrativoGraficoExtrasRecebidosReceber.propTypes = {
  className: PropTypes.string
};

export default AdministrativoGraficoExtrasRecebidosReceber;
