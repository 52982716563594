import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import api from '../../services/api';
import { ProducaoServico, PercentuaisPorTipo, ServicosPorGenero, GraficoPorGenero, ProducaoVendedor,
  ProducaoVendedorPercentuaisPorGenero, ProducaoVendedorServicosPorGenero, ProducaoVendedorPercentuais } from './components';
import ComboMes from '../Components/ComboMes';
import ComboAno from '../Components/ComboAno';
import ComboMoeda from '../Components/ComboMoeda';
import {ASIM, ATIPO_EXTRA, ATIPO_FATURADO, ATIPO_TODOS } from '../../consts';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const MenuProducao = () => {
  const classes = useStyles();

 var aano = sessionStorage.getItem('anoComboProd');
  if (aano == undefined){
    aano : new Date().getFullYear();
  } 

  const [ano, setAno] = useState({
    //ano : new Date().getFullYear()
    ano : sessionStorage.getItem('anoComboProd')
    //ano : 2023
    //ano : aano
  });

  const [mes, setMes] = useState({
  // mes : new Date().getMonth() + 1 // esta funcao comeca com 0 zero janeiro
    mes : sessionStorage.getItem('mesComboProd')
    
  });

  const [moeda, setMoeda] = useState({
      moeda : sessionStorage.getItem('moeda')
    });
  
  const handleChange = event => {
    event.persist();

    if (event.target.name === 'cmbAno'){
      //console.log('setará o ano no evento handlechange' + event.target.value )
      setAno({ano : event.target.value});
      sessionStorage.setItem('anoComboProd', event.target.value);    
    }
    if (event.target.name === 'cmbMes'){
      //console.log('setará o mes' + event.target.value )
      setMes({mes : event.target.value});
      sessionStorage.setItem('mesComboProd', event.target.value);          
    }
    if (event.target.name === 'cmbMoeda'){
      setMoeda({moeda : event.target.value});
      sessionStorage.setItem('moeda', event.target.value);          
    }

    //console.log('escolheu mes');
    window.location.reload();  
  }

  /* Indicadores Producao */
  const [producaoServico, setProducaoServico] = useState({
    totalProducaoServico : 0,
    totalProducaoServicoExtra : 0,
    totalProducaoServicoFat : 0,
    percProducaoExtra : 0,
    percProducaoFat : 0,    
    totalOpcionais : 0,        
    totalTransporte : 0,            
    totalHospedagem : 0,                
    totalAereo : 0,        
    totalSeguroViagem : 0,            
    totalOutros : 0,
  });

  /* Indicadores Producao Comissão */
  const [producaoVendedor, setProducaoVendedor] = useState({
    totalProducaoComissao : 0,
    totalProducaoComissaoExtra : 0,
    totalProducaoComissaoFat : 0,
    totalOpcionaisComissao : 0,
    totalTransporteComissao :0,
    totalHospedagemComissao :0,
    totalAereoComissao : 0,
    totalSeguroViagemComissao : 0,
    totalOutrosComissao : 0,
    vendedores :[],
  });

  /* Total Producao Servico */
  function getTotalProducao(){
    var totProducao = 0;
    if (sessionStorage.getItem('mod-ger-tipoExtraFat') == ATIPO_TODOS) {
      totProducao = producaoServico.totalProducaoServico
    }
    else
    if (sessionStorage.getItem('mod-ger-tipoExtraFat') == ATIPO_EXTRA) {
      totProducao = producaoServico.totalProducaoServicoExtra
    }
    else 
    if (sessionStorage.getItem('mod-ger-tipoExtraFat') == ATIPO_FATURADO) {
      totProducao = producaoServico.totalProducaoServicoFat
    }
    return totProducao
  }

  function getTotalProducaoComissao(){
    let totProducaoComissao = 0;
    if (sessionStorage.getItem('mod-ger-tipoExtraFat') == ATIPO_TODOS) {
      totProducaoComissao = producaoVendedor.totalProducaoComissao
    }
    else
    if (sessionStorage.getItem('mod-ger-tipoExtraFat') == ATIPO_EXTRA) {
      totProducaoComissao = producaoVendedor.totalProducaoComissaoExtra
    }
    else 
    if (sessionStorage.getItem('mod-ger-tipoExtraFat') == ATIPO_FATURADO) {
      totProducaoComissao = producaoVendedor.totalProducaoComissaoFat
    }
    return totProducaoComissao
  }
  
  /* carrega produção de serviço */
  async function loadProducaoServico  (){
    const ano = sessionStorage.getItem('anoComboProd');
    const mes = sessionStorage.getItem('mesComboProd');
    const codEmpresa = sessionStorage.getItem('codEmpresa');
    const tipoExtraFat = sessionStorage.getItem('mod-ger-tipoExtraFat');
    const moeda = sessionStorage.getItem('moeda'); 
    const paramsquery = 'codempresa='+  codEmpresa +'&mes='+ mes + '&ano=' +ano + '&tipoExtraFat=' + tipoExtraFat +
      '&moeda=' + moeda;
    const aURL = '/gerencial/producao?' + paramsquery;
    //console.log(aURL);
  
    const response = await api.get(aURL)                             
      .then(function (response) {
        setProducaoServico({totalProducaoServico : response.data.totalProducaoServico, 
          totalProducaoServicoExtra : response.data.totalProducaoServicoExtra,
          totalProducaoServicoFat : response.data.totalProducaoServicoFat,
          percExtra : response.data.perProducaocExtra,
          percFat : response.data.percProducaoFat,            
          totalOpcionais : response.data.totalOpcionais, 
          totalTransporte : response.data.totalTransporte,             
          totalHospedagem : response.data.totalHospedagem,                         
          totalAereo : response.data.totalAereo,                                     
          totalSeguroViagem : response.data.totalSeguroViagem,
          totalOutros : response.data.totalOutros,
           
        });    
        return response;
        //setDashBoard({dashBoard: {totalBug: 1000}});
      })
      .catch(function (error) {
        console.log(error);
      });        
  }


  /* carrega produção de comissão */
  async function loadProducaoComissao  (){
    const ano = sessionStorage.getItem('anoComboProd');
    const mes = sessionStorage.getItem('mesComboProd');
    const codEmpresa = sessionStorage.getItem('codEmpresa');
    const tipoExtraFat = sessionStorage.getItem('mod-ger-tipoExtraFat');
    const moeda = sessionStorage.getItem('moeda'); 
    const paramsquery = 'codempresa='+  codEmpresa +'&mes='+ mes + '&ano=' +ano + '&tipoExtraFat=' + tipoExtraFat +
      '&moeda=' + moeda;
    const aURL = '/gerencial/producao?' + paramsquery;
    //console.log(aURL);
    const response = await api.get(aURL)                             
      .then(function (response) {
     
        setProducaoVendedor({totalProducaoComissao : response.data.totalProducaoComissao,
          totalProducaoComissaoExtra : response.data.totalProducaoComissaoExtra,
          totalProducaoComissaoFat : response.data.totalProducaoComissaoFat,
          totalOpcionaisComissao : response.data.totalOpcionaisComissao,  
          totalTransporteComissao : response.data.totalTransporteComissao,
          totalHospedagemComissao : response.data.totalHospedagemComissao,
          totalAereoComissao : response.data.totalAereoComissao,
          totalSeguroViagemComissao : response.data.totalSeguroViagemComissao,
          totalOutrosComissao : response.data.totalOutrosComissao,
          vendedores : response.data.vendedores,  
        });    
        return response;
        //setDashBoard({dashBoard: {totalBug: 1000}});
      })
      .catch(function (error) {
        console.log(error);
      });        
  }



  useEffect(() => {
     if (sessionStorage.getItem('anoComboProd') == null){
      sessionStorage.setItem('anoComboProd', new Date().getFullYear());   
    } 
    /* se não tiver mes definido setará ano atual */
    if (sessionStorage.getItem('mesComboProd') == null){
      sessionStorage.setItem('mesComboProd', new Date().getMonth() + 1);   
    } 
    loadProducaoServico();
    loadProducaoComissao();
  }, []);  


  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
      >
        
        <Grid
          item
          lg={2}
          sm={6}
          xl={3}
          xs={12}
        >
          <ComboMes
            mes ={sessionStorage.getItem('mesComboProd')}
            onChange={handleChange}
          /> 
        </Grid>
        <Grid
          item
          lg={2}
          sm={6}
          xl={3}
          xs={12}
        >
          <ComboAno
            ano={sessionStorage.getItem('anoComboProd')}
            onChange={handleChange}
          />
        </Grid>
        <Grid
          item
          // lg={3}
          // sm={6}
          // xl={3}
          // xs={12}
        >
          <ComboMoeda
            name={"cmbMoeda"}
            label={"nomemoeda"}
            value={sessionStorage.getItem('moeda')}
            onChange={handleChange}
            visible={(sessionStorage.getItem('trabOutraMoeda') === ASIM)}
          />
        </Grid>

        <Grid 
          item
          lg={1}
          sm={6}
          xl={3}
          xs={12}
          container 
          //justify="flex-end"
        >
        </Grid>        
      </Grid>


      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <ProducaoServico
            totalProducaoServico={getTotalProducao()}
            totalProducaoServicoExtra ={producaoServico.totalProducaoServicoExtra}
            totalProducaoServicoFat ={producaoServico.totalProducaoServicoFat}
          />            

        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
          //style ={{ display: 'none'}}          
          //sessionStorage.getItem("mod-ger-tipoExtraFat");
          style={{display: (sessionStorage.getItem('mod-ger-tipoExtraFat') == 3) ? '' : 'none'}}
        >
          <PercentuaisPorTipo
            percExtra={producaoServico.percExtra}                
            percFat ={producaoServico.percFat}                            
          />

        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <ServicosPorGenero
            totalOpcionais ={producaoServico.totalOpcionais}
            totalTransporte ={producaoServico.totalTransporte}            
            totalHospedagem ={producaoServico.totalHospedagem}                        
            totalAereo ={producaoServico.totalAereo}                                    
            totalSeguroViagem ={producaoServico.totalSeguroViagem}                                                
            totalOutros ={producaoServico.totalOutros}             
          />        
        </Grid>

        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <GraficoPorGenero
            totalOpcionais ={producaoServico.totalOpcionais}
            totalTransporte ={producaoServico.totalTransporte}            
            totalHospedagem ={producaoServico.totalHospedagem}                        
            totalAereo ={producaoServico.totalAereo}                                    
            totalSeguroViagem ={producaoServico.totalSeguroViagem}                                                
            totalOutros ={producaoServico.totalOutros}             
          />
        </Grid>

      </Grid>
      {/* Produção vendedores */}
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <ProducaoVendedor
            totalProducaoComissao ={getTotalProducaoComissao()}
            totalProducaoComissaoServicoExtra={producaoVendedor.totalProducaoComissaoExtra}  
            totalProducaoComissaoServicoFat ={producaoVendedor.totalProducaoComissaoFat}
          />            

        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
          //style ={{ display: 'none'}}          
          //sessionStorage.getItem("mod-ger-tipoExtraFat");

          style={{display: (sessionStorage.getItem('mod-ger-tipoExtraFat') == 3) ? '' : 'none'}}
        >
          <ProducaoVendedorPercentuaisPorGenero
            percOpcional ={producaoVendedor.totalOpcionaisComissao}                
            percTransporte ={producaoVendedor.totalTransporteComissao}               
            percHospedagem ={producaoVendedor.totalHospedagemComissao}   
            percAereo ={producaoVendedor.totalAereoComissao}
            percSeguroViagem ={producaoVendedor.totalSeguroViagemComissao}
            percOutros ={producaoVendedor.totalOutrosComissao}        
          />

        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <ProducaoVendedorServicosPorGenero
            totalOpcionais ={producaoVendedor.totalOpcionaisComissao}
            totalTransporte ={producaoVendedor.totalTransporteComissao}            
            totalHospedagem ={producaoVendedor.totalHospedagemComissao}                        
            totalAereo ={producaoVendedor.totalAereoComissao}                                    
            totalSeguroViagem ={producaoVendedor.totalSeguroViagemComissao}                                                
            totalOutros ={producaoVendedor.totalOutrosComissao}             
          />        
        </Grid>

        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <ProducaoVendedorPercentuais
          vendedores = {producaoVendedor.vendedores}
          
          />
        </Grid> 

      </Grid> 


    </div>
  );
};

export default MenuProducao;
