import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  }
}));

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  
  function getImage(){
    const modulo = sessionStorage.getItem('modulo');
  let imagePath = '';
    if (modulo == 37) {
      imagePath = '/images/logocliappcli.png';
    } else {
      // Caminho padrão se não houver correspondência
      console.log('não houve correspondencia')
      imagePath = '/images/logos/logo--white.png';
    }
    return imagePath;
  }

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src={getImage()} //"/images/logos/logo--white.png"
          />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
