import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card,  CardContent, Grid, Typography, Avatar,  Divider, IconButton} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { getISOMoeda } from 'lib/functions';
const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  }
}));

const AdministrativoFaturados = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const refreshDados = ()=>{
    window.location.reload();
  }

  const moeda = getISOMoeda(sessionStorage.getItem('moeda'));
  let totalGeralFaturados = rest.totalGeralFaturados.toLocaleString(moeda.abrevMoeda, { style: 'currency', currency: moeda.isoMoeda });
  let totalFaturadosRecebidos = rest.totalFaturadosRecebidos.toLocaleString(moeda.abrevMoeda, { style: 'currency', currency: moeda.isoMoeda });
  let totalFaturadosAReceber = rest.totalFaturadosAReceber.toLocaleString(moeda.abrevMoeda, { style: 'currency', currency: moeda.isoMoeda });

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="inherit"
              gutterBottom
              variant="body2"
              
            >
              FATURADOS
            </Typography>
            <Typography
              color="inherit"
              variant="h2"
            > {totalGeralFaturados}

              <IconButton 
                alignItems= "right"                
                color="inherit" 
                size="small"
              >
                <RefreshIcon 
                  onClick={refreshDados}
                />
              </IconButton>

            </Typography>
            <br/>
            <Typography
              color="inherit"
              variant="h5"
              style={{display: (sessionStorage.getItem('mod-ger-tipoExtraFat') == 3) ? '' : 'none'}}
            >
             FATURADOS - {totalFaturadosRecebidos}
            </Typography>
            <Divider />          

            <Typography
              color="inherit"
              variant="h5"
              style={{display: (sessionStorage.getItem('mod-ger-tipoExtraFat') == 3) ? '' : 'none'}}              
            >
             A FATURAR - {totalFaturadosAReceber}             
            </Typography>
            

          </Grid>
          <Grid item>
            <br/>
            <Avatar className={classes.avatar}>
              <AttachMoneyIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <div>
          <br/> 
        </div>
      </CardContent>
    </Card>    
  );
};

AdministrativoFaturados.propTypes = {
  className: PropTypes.string
};

export default AdministrativoFaturados;
