import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/styles';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'left',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 10
  },
  margin: {
    marginLeft : theme.spacing(2)
  },
  
}));



const ComboAno = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  //console.log('vai carregar o componente combo ano ' + rest.ano )
  
  function Anos () {
    const anos = [
      {id: 2010, descAno: '2010'},
      {id: 2011, descAno: '2011'},
      {id: 2012, descAno: '2012'},  
      {id: 2013, descAno: '2013'},
      {id: 2014, descAno: '2014'},
      {id: 2015, descAno: '2015'},
      {id: 2016, descAno: '2016'},
      {id: 2017, descAno: '2017'},
      {id: 2018, descAno: '2018'},
      {id: 2019, descAno: '2019'},
      {id: 2020, descAno: '2020'},
      {id: 2021, descAno: '2021'},                                                          
      {id: 2022, descAno: '2022'},                                                          
      {id: 2023, descAno: '2023'},                                                          
      {id: 2024, descAno: '2024'},                                                          
      {id: 2025, descAno: '2025'},                                                          
      {id: 2026, descAno: '2026'},                                                          
      {id: 2027, descAno: '2027'},                                                          
      {id: 2028, descAno: '2028'},                                                          
      {id: 2029, descAno: '2029'},                                                          
      {id: 2030, descAno: '2030'}                                                                                                          
    ];

    const listAnos = anos.map(
      (ano) => {
        return (
          <option
            key={ano.id} 
            value={ano.id}
          >{ano.descAno}</option>
        )
      }
    )
    

    return(
      <div>
        <InputLabel 
          htmlFor="outlined-age-native-simple"
        >Ano</InputLabel>
        <Select 
          inputProps={{
            id: 'outlined-age-native-simple',
            name: 'cmbAno'
          }}
          label="nomeano"
          native
          onChange={rest.onChange}
          //onClick={rest.onClick}
          size
          value={rest.ano}
        >
          {listAnos}
        </Select>
      </div>
    )
  }

  return (
    <Anos/>
    
  );
};

ComboAno.propTypes = {
  className: PropTypes.string
};

export default ComboAno;