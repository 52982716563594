import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Select from '@material-ui/core/Select';
import api from '../../../services/api';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'left',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 10
  },
  margin: {
    marginLeft : theme.spacing(2)
  },
  
}));


const ComboEmpresa = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const [empresasx,setEmpresas] = useState({
    companies :[]
  });

  //console.log('rota do módulo:' + rest.rotaModulo)


  const handleChangeEmpresa = event => {
    event.persist();
    console.log(event.target.value);
    sessionStorage.setItem('codEmpresa', event.target.value);
    //console.log('atribuiu código da empresa');    
  }

  useEffect(() => {
    //console.log('efect do combo empresa...');
    async function loadEmpresas () {
      const response = await api.get('/empresas')                       
        .then(function (response) {
          //console.log(response.data);
          //console.log('o resultado é: ' + response.data);
          //var r = response.data;
          setEmpresas({companies : response.data});

          //console.log(r);
          return response.data;        
        })
        .catch(function (error) {
          console.log(error);
        });       
  
    }
  
    loadEmpresas();
  }, []);
  


  function ListaEmpresas () {
    var empresas = empresasx.companies;    
    const listEmpresas = empresas.map(
      (empresa) => {
        return (
          <option
            key={empresa.codigo} 
            value={empresa.codigo}
          >{empresa.fantasia}</option>
        )
      }
    )

    return(
      <div>
        <InputLabel 
          htmlFor="outlined-age-native-simple"
          style ={{ display: (listEmpresas.length > 1 ?'' :'none' )}}              
          // show when length > 1 company
        >Empresa</InputLabel>
        <Select 
          fullWidth
          inputProps={{
            id: 'outlined-age-native-simple',
            name: 'cmbEmpresa'
          }}
          label="nomeempresa"
          native
          onChange={handleChangeEmpresa}
          size
          style ={{ display: (listEmpresas.length > 1 ?'' :'none' )}}              
          // show when length > 1 company
          //value={rest.codEmpresa}
          //value={3}
          value={sessionStorage.getItem('codEmpresa')}
        >
          {listEmpresas}
        </Select>
      </div>
    )
  }



  return (
    <ListaEmpresas/>
    
  );
};

ComboEmpresa.propTypes = {
  className: PropTypes.string
};

export default ComboEmpresa;