import React, { Component } from 'react';
import { Button, Card, CardContent, Table, TableCell, TableRow, TableHead, TableBody, Paper,
Input } from '@material-ui/core';
import api from '../../../services/api';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal/DeleteConfirmationModal';
import AlertInformation from '../../../components/AlertInformation/AlertInformation';

class PaisList extends Component{
  state = {
    paises : [],
    searchFilter : {
      nomePais : ''
    },
  };

  estilo = {
    marginLeft: 30,
    marginTop: 30,
    marginRight: 30,
    container: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    addButton: {
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      float: 'right',
      marginTop: 3,
      marginRight: 3
    },
    redText: {
      color: 'red',
    },  
  };

  constructor(props) {
    super(props);
  }

  /* carregamento inicial */
  async componentDidMount(){
    this.loadPaises();
  }

  /* Carrega Países */
  loadPaises = async () =>{
    const { searchFilter } = this.state;
    let params = '';

    /* consulta pelo nome do país */
    if (!(searchFilter.nomePais === '')){
      params += '?tbpais.nomepais=_like_' + searchFilter.nomePais
    }

    const response = await api.get('/pais' + params);

    this.setState({ paises : response.data });
  }

  /* Escolha de um país para exclusão */
  handleSelectItem = (id) => {
    this.setState({selectedId: id}); 
  };

  /* deleta país */
  handleDelete = async (id) => {
    try {
      const response = await api.delete(`/pais/${id}`);
      // Verifica se a exclusão foi bem-sucedida
      if (response.status === 200) {
        this.handleClose();
        this.loadPaises();
      }
    } catch (error) {
        this.handleClose();
        this.handleOpenAlert(error.response.data.error);
        console.log(error);
        console.log(error.message);
    }
  };

  /* abre janela confirmação para exclusão */
  handleOpen = (codpais) => {
    this.setState({ open: true });
    this.handleSelectItem(codpais);
  };

  /* abre janela de alerta com mensagem */
  handleOpenAlert = (message) => {
    this.setState({ openAlert: true });
    this.setState({ messageAlert: message });
  };  
  
  /* fecha janela de confirmação exclusão */
  handleClose = () => {
    this.setState({ open: false });
  };

  /* fecha janela mensagem alerta */
  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  }

  /* Pesquisar filtros na tela */
  OnChangeSearch = (event) => {
    const { searchFilter } = this.state;
    let { name, value } = event.target;
    value = value.toUpperCase()
    searchFilter[name] = value;
    this.setState({searchFilter: searchFilter });
  };

  onInputBlur = (event) => {
    const { searchFilter } = this.state;
    let { name, value } = event.target;
    value = value.toUpperCase()
    searchFilter[name] = value;
    this.setState({searchFilter: searchFilter });
  };

  /* Limpa Filtros na tela */
  LimpaFiltros = () => {
    let { searchFilter } = this.state;
    searchFilter.nomePais = '';
    document.getElementById('nomePais').value = '';
    this.setState({searchFilter: searchFilter });    
  }

  render(){
    const { paises } = this.state;
    const { searchFilter } = this.state;
    return(
      <div style={this.estilo}>
        <Button
          component={Link}
          to="/financas/paises/novo"
          variant="contained"
          color="primary"
          style={this.estilo.addButton}
        >
           Novo
        </Button>

        <Paper hover>
          <Input 
            autoFocus
            id="nomePais"
            name="nomePais"
            onBlur = {this.onInputBlur}
            // onChange = {this.OnChangeSearch}
            placeholder = "Nome país a pesquisar"
            style={{marginBottom: 10, width: 300, marginLeft:20, marginTop: 10 }}
            // value = {searchFilter.nomePais}
            value = {this.state.inputValue}
          />

          <Button
            color="primary"
            id="pesquisar"
            onClick={() => this.loadPaises()}
          >
            <SearchIcon />
          </Button>

          <Button
            color="primary"
            onClick={() => this.LimpaFiltros()}
          >
            <ClearAllIcon/>
          </Button>
        </Paper>

        <Card>
          <CardContent>
            <PerfectScrollbar>
              <div >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Código</TableCell>
                      <TableCell>Nome País</TableCell>
                      <TableCell>Abreviatura</TableCell>
                      <TableCell>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paises.slice(0, 250).map(pais => (
                      <TableRow
                        hover
                        key={pais.codpais}
                      >
                        <TableCell>{pais.codpais}</TableCell>
                       
                        <TableCell>{pais.nomepais}</TableCell>

                        <TableCell>{pais.abreviatura}</TableCell>
                        
                        <TableCell>
                          <Link to={`/financas/paises/${pais.codpais}`}>
                            <EditOutlinedIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                          </Link>
                          <Link to={`/financas/paises/${pais.codpais}/view`}>
                            <PageviewOutlinedIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                          </Link>
                          <DeleteForeverOutlinedIcon 
                              onClick={() => this.handleOpen(pais.codpais)}
                              style={{ cursor: 'pointer', marginRight: '10px' }} />
                        </TableCell>
                        
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
        </Card>        

        <DeleteConfirmationModal
          open={this.state.open}
          handleClose={() => this.handleClose()}
          itemId={this.state.selectedId}
          message={"Confirma exclusão do país " + this.state.selectedId + '?'}
          handleDelete={()=> this.handleDelete(this.state.selectedId)}
        />

        <AlertInformation
          open={this.state.openAlert}
          handleClose={() => this.handleCloseAlert()}
          message={this.state.messageAlert}
        />
       </div>
    );
  }
}

export default PaisList;