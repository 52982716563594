import React, { Component } from 'react';
import { Button, Card, CardContent, Table, TableCell, TableRow, TableHead, TableBody, Paper,
Input} from '@material-ui/core';
import api from '../../../services/api';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal/DeleteConfirmationModal';
import AlertInformation from '../../../components/AlertInformation/AlertInformation';
import { Select, MenuItem } from '@material-ui/core';
import { ANAO, ASIM } from 'consts';

class BancoList extends Component{
  state = {
    bancos : [],
    searchFilter : {
      nomeBanco : '',
      numeroBanco : '',
      situacaoInativoFilter: ANAO,
    },
  };

  estilo = {
    marginLeft: 30,
    marginTop: 30,
    marginRight: 30,
    container: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    addButton: {
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      float: 'right', // alinhamento a direita
      marginTop: 3,
      marginRight: 3
    },
    redText: {
      color: 'red',
    },  
  };

  constructor(props) {
    super(props);
  }

  /* carregamento inicial */
  async componentDidMount(){
    this.loadBancos();
  }

  /* Carrega Bancos */
  loadBancos = async () =>{
    const { searchFilter } = this.state;
    let params = '';

    /* consulta pelo nome do banco */
    if (!(searchFilter.nomeBanco === '')){
      params += '?tbbanco.descbanco=_like_' + searchFilter.nomeBanco
    }

    /* consulta pelo número do banco */
    if (!(searchFilter.numeroBanco === '')){
      if (params) {
        params += '&'
      }
      else {
        params += '?'
      }
      params += 'tbbanco.nrocompensacao=_like_' + searchFilter.numeroBanco
    }

    /* filtra situação do banco */
    switch (searchFilter.situacaoInativoFilter) {
      case ASIM:
        if (params){
          params += '&tbbanco.inativo=' + ASIM
        }
        else
          params += '?tbbanco.inativo=' + ASIM ;        
      break;
      case ANAO:
        if (params){
          params += '&tbbanco.inativo=' + ANAO
        }
        else
          params += '?tbbanco.inativo=' + ANAO  ;
        break;
      default:
        break;
    }        

    const response = await api.get('/banco' + params);      
    this.setState({ bancos : response.data });
  }

  /* Escolha de um banco para exclusão */
  handleSelectItem = (id) => {
    this.setState({selectedId: id}); 
  };

  /* deleta banco */
  handleDelete = async (id) => {
    try {
      const response = await api.delete(`/banco/${id}`);
      // Verifica se a exclusão foi bem-sucedida
      if (response.status === 200) {
        this.handleClose();
        this.loadBancos();
      }
    } catch (error) {
        this.handleClose();
        this.handleOpenAlert(error.response.data.error);
        console.log(error);
        console.log(error.message);
    }
  };

  /* abre janela confirmação para exclusão */
  handleOpen = (codbanco) => {
    this.setState({ open: true });
    this.handleSelectItem(codbanco);
  };

  /* abre janela de alerta com mensagem */
  handleOpenAlert = (message) => {
    this.setState({ openAlert: true });
    this.setState({ messageAlert: message });
  };  
  
//   /* fecha janela de confirmação exclusão */
  handleClose = () => {
    this.setState({ open: false });
  };

//   /* fecha janela mensagem alerta */
  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  }

  /* Pesquisar filtros na tela */
  OnChangeSearch = (event) => {
    const { searchFilter } = this.state;
    let { name, value } = event.target;
    value = value.toUpperCase()
    searchFilter[name] = value;
    this.setState({searchFilter: searchFilter });
    if (name === 'situacaoInativoFilter'){
      this.loadBancos();
    }   
  };

  /* Limpa Filtros na tela */
  LimpaFiltros = () => {
    let { searchFilter } = this.state;
    searchFilter.nomeBanco = '';
    searchFilter.numeroBanco = '';
    searchFilter.situacaoInativoFilter = 'N';
    this.setState({searchFilter: searchFilter });       
  }
  

  render(){
    const {bancos} = this.state;
    const {searchFilter} = this.state;
    return(
      <div style={this.estilo}>
        <Button
          component={Link}
          to="/financas/bancos/novo"
          variant="contained"
          color="primary"
          style={this.estilo.addButton}
        >
          Novo
        </Button>
        <Paper hover>
          <Input 
            autoFocus
            id="nomeBanco"
            name="nomeBanco"
            onBlur = {this.onInputBlur}
            onChange = {this.OnChangeSearch} 
            placeholder = "Nome banco a pesquisar"
            style={{marginBottom: 10, width: 300, marginLeft:10, marginTop:10}}
            value = {searchFilter.nomeBanco}
          />

          <Input
            id="numeroBanco"
            name="numeroBanco"
            onBlur = {this.onInputBlur}
            onChange = {this.OnChangeSearch} 
            placeholder = "Número"
            style={{marginBottom: 10, width: 100, marginLeft:10, marginTop:10}}
            value = {searchFilter.numeroBanco}
          />

          <Select
            id="situacaoInativoFilter"
            name="situacaoInativoFilter"
            style={{marginLeft: 20}}
            value={searchFilter.situacaoInativoFilter || ''}
            onChange={this.OnChangeSearch}
          >
            <MenuItem value={ANAO}>Ativos</MenuItem>
            <MenuItem value={ASIM}>Inativos</MenuItem>
            <MenuItem value={'T'}>Todos</MenuItem>
          </Select>

          <Button
            color="primary"
            id="pesquisar"
            onClick={() => this.loadBancos()}
          >
            <SearchIcon />
          </Button>

          <Button
            color="primary"
            onClick={() => this.LimpaFiltros()}
          >
            <ClearAllIcon/>
          </Button>
        </Paper>

        <Card>
          <CardContent>
            <PerfectScrollbar>
              <div >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Código</TableCell>
                      <TableCell>Banco</TableCell>
                      <TableCell>Número</TableCell>
                      <TableCell>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bancos.slice(0, 100).map(banco => (
                      <TableRow
                        hover
                        key={banco.codbanco}
                      >
                        <TableCell style={(banco.inativo === ASIM) ? this.estilo.redText : {}}>{banco.codbanco}</TableCell>
                       
                        <TableCell style={(banco.inativo === ASIM) ? this.estilo.redText : {}} >{banco.descbanco}</TableCell>

                        <TableCell style={(banco.inativo === ASIM) ? this.estilo.redText : {}} >{banco.nrocompensacao}</TableCell>
                        
                        <TableCell>
                          <Link to={`/financas/bancos/${banco.codbanco}`}>
                            <EditOutlinedIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                          </Link>
                          <Link to={`/financas/bancos/${banco.codbanco}/view`}>
                            <PageviewOutlinedIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                          </Link>
                          <DeleteForeverOutlinedIcon 
                              onClick={() => this.handleOpen(banco.codbanco)}
                              style={{ cursor: 'pointer', marginRight: '10px' }} />
                        </TableCell>
                        
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
        </Card>

        <DeleteConfirmationModal
          open={this.state.open}
          handleClose={() => this.handleClose()}
          itemId={this.state.selectedId}
          message={"Confirma exclusão do banco " + this.state.selectedId + '?'}
          handleDelete={()=> this.handleDelete(this.state.selectedId)}
        />

        <AlertInformation
          open={this.state.openAlert}
          handleClose={() => this.handleCloseAlert()}
          message={this.state.messageAlert}
        />
      </div>
    );
  }
}

export default BancoList;