import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';
import palette from 'theme/palette';
import { getISOMoeda } from 'lib/functions';
//import { data, options } from './chart';

const moeda = getISOMoeda(sessionStorage.getItem('moeda'));

function getOptions(){

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    legend: { display: false },
    cornerRadius: 20,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: palette.divider,
      backgroundColor: palette.white,
      titleFontColor: palette.text.primary,
      bodyFontColor: palette.text.secondary,
      footerFontColor: palette.text.secondary
    },
    layout: { padding: 0 },
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: palette.text.secondary
          },
          gridLines: {
            display: true,
            drawBorder: true
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider
          }
        }
      ]
    }
  };
  return options;

}

function getData(rest){
  const totalOpcionais = rest.totalOpcionais;
  const totalTransporte = rest.totalTransporte;
  const totalHospedagem = rest.totalHospedagem;
  const totalAereo = rest.totalAereo;
  const totalSeguroViagem = rest.totalSeguroViagem;
  const totalOutros = rest.totalOutros;
  
  const data = {
    labels: ['Opcionais', 'Transporte', 'Hospedagem', 'Aéreo', 'S.Viagem', 'Outros'],
    datasets: [
      {
        label: 'mês',
        backgroundColor: palette.secondary.main,
        data: [totalOpcionais, totalTransporte, totalHospedagem, totalAereo, totalSeguroViagem, 
          totalOutros]
      },
    ]
  }
  return data;
}

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const GraficoPorGenero = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Gráfico por gênero"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar
            data={getData(rest)}
            options={getOptions()}
          />
        </div>
      </CardContent>
      <Divider />
    </Card>
  );
};

GraficoPorGenero.propTypes = {
  className: PropTypes.string
};

export default GraficoPorGenero;
