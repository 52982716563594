import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import api from '../../services/api';
import { CardHospedagem} from './components';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Hospedagem = () => {
  const classes = useStyles();

 
  const handleChange = event => {
    event.persist();
    //console.log('escolheu mes');
    window.location.reload();  
  }


 /* Dados da OS */
 const [os, setDadosOS] = useState({});
  
/* carrega dados da OS */
async function loadDadosOS() {
  const codOS = sessionStorage.getItem('codOS');
  const aURL = '/os/' + codOS;
  try {
    const response = await api.get(aURL);
    setDadosOS(response.data[0]);
    //console.log(response.data[0].paxgeral)
  } catch (error) {
    console.log(error);
  }
}

  

  useEffect(() => {
      loadDadosOS();
  
  }, []);  


  return (
    <div className={classes.root}>
      
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          
          <CardHospedagem
            hospedagemprincipal={os.nomefanthotel}
        />
        </Grid>
  
      </Grid> 


    </div>
  );
};

export default Hospedagem;
