import api from '../../../services/api';
import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {CardContent,  FormLabel,  FormGroup, TextField, Divider } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Switch, FormControlLabel, Checkbox } from '@material-ui/core';
import { ANAO, ASIM } from 'consts';

class TransacaoFinanceiraForm extends Component{  
  constructor(props) {
    super(props);
    this.state = {
      transacao: {},
      isNewRecord : false,
      isView : false,
      schema : {
        usuario: {
          presence: { allowEmpty: false, message: 'é obrigatório' },
          length: {
            maximum: 64
          }
        },
      },
      
      useStyles : makeStyles(theme => ({
        root: {
          backgroundColor: theme.palette.background.default,
          height: '100%'
        },
        grid: {
          height: '100%'
        },
        quoteContainer: {
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        },
        quote: {
          backgroundColor: theme.palette.neutral,
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: 'url(/images/auth.jpg)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        },
        quoteInner: {
          textAlign: 'center',
          flexBasis: '600px'
        },
        quoteText: {
          color: theme.palette.white,
          fontWeight: 300
        },
        name: {
          marginTop: theme.spacing(3),
          color: theme.palette.white
        },
        bio: {
          color: theme.palette.white
        },
        contentContainer: {},
        content: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        },
        contentHeader: {
          display: 'flex',
          alignItems: 'center',
          paddingTop: theme.spacing(5),
          paddingBototm: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2)
        },
        logoImage: {
          marginLeft: theme.spacing(4)
        },
        contentBody: {
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
          }
        },
        form: {
          paddingLeft: 100,
          paddingRight: 100,
          paddingBottom: 125,
          flexBasis: 700,
          [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
          }
        },
        title: {
          marginTop: theme.spacing(3)
        },
        socialButtons: {
          marginTop: theme.spacing(3)
        },
        socialIcon: {
          marginRight: theme.spacing(1)
        },
        sugestion: {
          marginTop: theme.spacing(2)
        },
        textField: {
          marginTop: theme.spacing(2)
        },
        signInButton: {
          margin: theme.spacing(2, 0)
        },
        alert: {
          color: '#000000',
        }
      })),
    };
  }

  async componentDidMount(){
    const { id } = this.props.match.params;
    const { location } = this.props;
    const currentPath = location.pathname;
    
    if (id >= 0) {
      const response = await api.get(`/transacaofinanceira/${id}`);
      
      this.setState({ transacao: response.data[0],
        isNewRecord : false,
        isView : (currentPath.includes('/view'))}
      );      
      
    }    
    else
    if ( id === 'novo') 
    {
      this.setState({ 
        isNewRecord : true
      });    
      this.state.isNewRecord = true;
      const { transacao } = this.state;
    }
  }
  
  // Change Transacao Financeira
  OnChange = (event) => {    
    const { transacao } = this.state;
    const input = event.target;
    let value = input.value.toUpperCase();   
    transacao[event.target.name] = value;

    if (input.name === 'inativo') {
      value = input.checked; // Obtém o valor da propriedade checked do Switch
      console.log('value: ' + value);
      if (value === false){
        transacao.inativo = ASIM;
      }
      else
      {
        transacao.inativo = ANAO;
      }
    }

    if (input.name === 'exibefechfront') {
      value = input.checked;
      if (value === false) {
        transacao.exibefechfront = ANAO;
      } else {
        transacao.exibefechfront = ASIM;
      }
    }

    this.setState({ transacao: transacao });   
  };


  /* atualiza dados de transação financeira existente - post */
  atualizaTransacao = async () =>{
    const { transacao } = this.state;
  
    await api.post(`/transacaofinanceira/${transacao.codtransacao}`, transacao)
      .then(function (response) {
  //      console.log(response);
      })
      .catch(function (error) {
  //      console.log(error);
      });
    this.props.history.push('/financas/transacoes');
  }

  /* Click salvar */
  onClick = () =>{
    if (this.validationRequiredFields()){
      if (this.state.isNewRecord){
        this.insereTransacao()
      }
      else
      {
        this.atualizaTransacao();
      }
    }
  }


  /* verifica se tem erro campos atendimento */
  hasErrorField = (field, value) => {
    if (field === 'desctransacao') {
      if ((String(value).trim() === ''))
      {
        return true
      }
    }
  }

  /* valida campos obrigatórios ao salvar */
  validationRequiredFields() {
    const { transacao } = this.state
    if( (String(transacao.desctransacao).trim() === '')  || (transacao.desctransacao === undefined)){
      transacao.desctransacao = '';
      this.setState({transacao: transacao });
      return false
    } 
    else
    {
      return true
    }  
  }

  /* insere nova transação */
  insereTransacao = async () =>{
    const { transacao } = this.state;
    transacao.codUsuarioRegistrou = parseInt(sessionStorage.getItem('codUser'));
    
    try {
      const response = await api.post(`/transacaofinanceira`, transacao);
      this.props.history.push('/financas/transacoes');
    } catch (error) {
       console.log(error);
        console.log(error.message);
    }
  } 

  render() {
    const { transacao } = this.state;
    return (
      <div>
        <CardContent>
          <Typography 
            component="h1"
            variant="h3" 
          >
            Transação Financeira {transacao.codtransacao} 
          </Typography>             
          
          <FormGroup>
            <TextField
              className={this.state.useStyles.textField}
              label="Nome da transação financeira"
              style={{ marginTop: '10px' }} 
              name="desctransacao"
              value ={transacao.desctransacao || ''}
              variant="outlined"
              onChange={this.OnChange}
              helperText={transacao.desctransacao === '' ? 'Nome da transação financeira obrigatória' : ' '}              
              disabled={this.state.isView}
            />
          </FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={!(transacao.inativo === ASIM)}
                name="inativo"
                onChange={this.OnChange}
                color="primary"
                disabled={this.state.isView}
              />
            }
            label={(transacao.inativo === ASIM) ? 'Inativo' : 'Ativo'}
          />
          
          <br/>

          <FormControlLabel 
            control={
              <Checkbox
                checked={(transacao.exibefechfront === ASIM)}
                name="exibefechfront"
                onChange={this.OnChange}
                color="primary"
                disabled={this.state.isView}
              />} 
            label={'Exibe em fechamento na forma banco no módulo front'} 
          />

          <br/>
          <br/>

          <Button
            color="primary"
            variant="contained"
            onClick={this.onClick}
            style={{ marginTop: '10px' }} 
            disabled={this.state.isView}
          >
            Salvar
          </Button>
        </CardContent>
      </div>
    );
  }
}

TransacaoFinanceiraForm.propTypes = 
{
  classes: PropTypes.object.isRequired
};

export default TransacaoFinanceiraForm;