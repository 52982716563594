import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ChartTooltipItem } from 'chart.js';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
import { VisibilityOff } from '@material-ui/icons';
         
const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

const ProducaoVendedorPercentuais = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();

  //let vendedores =[];
  let vendedores = rest.vendedores;

  //vendedores = rest.vendedores;
  //vendedores.push({nomeVendedor: "JOSE", percVendedor:10});
  //vendedores.push({nomeVendedor: "MARIA", percVendedor:90});

  //console.log('objeto vendedores..', JSON.stringify(vendedores));

  const data = {
    datasets: [
      {
        data: vendedores.map((data) => data.totalcomissao),
        backgroundColor: [
          "#f3ba2f", "#2a71d0", 
          "#849847", "#2aa23b", "#290866", "#d90e8f", "#92e9b4", "#54c9d4", 
          "#477f1a", "#774a5a", "#41d833", "#8cf6b0", "#348225", "#1a7dc5", 
          "#1f41c6", "#22f936", "#13a7aa", "#7e4921", "#1acb78", "#75bbca", 
          "#19e8e4", "#3d4484", "#d4e32c", "#8a17f2", "#99a58b", "#517779", 
          "#b2c303", "#48ae3c", "#e60ed8", "#18614f", "#6a95d1", "#542630", 
          "#b6bc58", "#db6930", "#3dc58f", "#3b323c", "#2fde85", "#3d4bc7", 
          "#81c8c5", "#e01f76", "#7d9f8a", "#73be2c", "#5f4790", "#8520bb", 
          "#1bb66d", "#658ed2", "#6da7f4", "#44cc9a", "#965f4e", "#9ed8c8", 
          "#97034c", "#f0eca9", "#f6776f", "#c3734b", "#270aa5", "#ac29d6", 
          "#5e6c65", "#353dde", "#980d5a", "#d1fb5c", "#b8d450", "#37c253", 
          "#a68cce", "#356fc3", "#746184", "#aa3ee3", "#e98e5c", "#ece5b8", 
          "#22b7a5", "#2913d1", "#60c117", "#77782e", "#1872c6", "#9cab44", 
          "#5e71f7", "#929968", "#63a045", "#2db070", "#3e9ba5", "#2f51da", 
          "#ded97b", "#44c7cb", "#455660", "#3709f6", "#c89cbb", "#3d59d7", 
          "#115d0d", "#2b5d3c", "#db8f22", "#1ef946", "#9142eb", "#8f638c", 
          "#119578", "#0aee8c", "#e67554", "#7f8b0c", "#1d7a17", "#79ea32", 
          "#03e36e", "#2ac21b", "#d4eeb1", "#bdd61d", "#3c2acc", "#f7a327", 
          "#8088a3", "#60ef08", "#2c7b32", "#32e1d7", "#42b990", "#f619c9", 
          "#3adfe6", "#39eb3b", "#55b3cf", "#c55a64", "#49f129", "#934f40", 
          "#81c09e", "#d34a86", "#f273ae", "#2a77e5", "#124302", "#ceb421"  
        
        ],
       
       
        borderWidth: 2,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white,
      }
    ],
    labels: vendedores.map((data) => data.nomevendedor)
    
    
  };

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    //animation: false,
    animation: {
      animateRotate: true,
      animateScale: true,
    },  
    cutoutPercentage: 70,
    layout: { padding: 0 },
    
    tooltips: {
      callbacks: {
        label: (tooltipItem: ChartTooltipItem, data) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const currentValue = dataset.data[tooltipItem.index];
          const total = dataset.data.reduce((sum, value) => sum + value, 0);
          const percentage = ((currentValue / total) * 100).toFixed(2);
          return `${data.labels[tooltipItem.index]}: ${percentage}%`
        },
      },   
  }}

  
  const tipos = [
    {
      title: 'Opcionais',
      //value: percOpcional,
      //icon: <LaptopMacIcon />,
      //color: theme.palette.secondary.main
      
    },
    {
      title: 'Transporte',
      //value: percTransporte,
      //icon: <TabletMacIcon />,
      //color: theme.palette.warning.main
    },
    {
      title: 'Hospedagem',
      //value: 10,
      //icon: <TabletMacIcon />,
      //color: theme.palette.warning.main
    },
    {
      title: 'Aéreo',
      //value: percAereo,
      //icon: <TabletMacIcon />,
      //color: theme.palette.warning.main
    },
    {
      title: 'Seguro Viagem',
      //value: 10,
      //icon: <TabletMacIcon />,
      //color: theme.palette.warning.main
    },
    {
      //title: 'Outros',
      //value: percOutros ,
      //icon: <TabletMacIcon />,
      //color: theme.palette.warning.main
    }    

  ];

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Comissão por vendedor"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Doughnut
            data={data}
            options={options}
          />

        </div>
        <div className={classes.stats}>
        </div>
      </CardContent>
    </Card>
  );
};

ProducaoVendedorPercentuais.propTypes = {
  className: PropTypes.string
};

export default ProducaoVendedorPercentuais;
