import React, { Component } from 'react';
import { Button, Card,  CardActions, CardContent, TablePagination, Input, Paper,  Select, TextField,
  TableHead, TableRow, Table, TableCell, TableBody} from '@material-ui/core';
import api from '../../../services/api';
import { Link } from 'react-router-dom';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import TimerIcon from '@material-ui/icons/Timer';
import { AOS_ABERTA, AOS_ENCERRADA, ATIPO_TRANSFER_IN, ATIPO_TRANSFER_OUT } from  '../../../consts';
import SearchIcon from '@material-ui/icons/Search';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import LowPrioritySharpIcon from '@material-ui/icons/LowPrioritySharp';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import { orange } from '@material-ui/core/colors';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CheckIcon from '@material-ui/icons/Check';
class OSList  extends Component{  
  state = {
    oss: [],
    atendimentosInfo: {},
    selectedOptionUser : sessionStorage.getItem('token'),
    atividade : {codUsuario: 0, chaveOrigem: 0, origem :0},   
    selectedCodOSAtividade : 0,    
    osSelecionada : {codos: 0},   
    iconeStopedActivity : <TimerOffIcon/>,
    iconeStartedActivity : <TimerIcon/>,    
    iconeStopedPriority : <LowPrioritySharpIcon/>,
    iconeStartedPriority : <PriorityHighIcon  style={{ color: orange[500] }} />,    

    searchFilter : {
      codUsuarioRespFilter :0,
      situacaoOSFilter: 'A',
      nomeFantasiaClienteFilter : '',
      nroOSFilter : 0,            
      dataServicoFilter : ' ',
      horaServicoFilter : ' ',
      dataSaidaChegadaFilter : ' ',
      tipoChegadaSaidaFilter : 2
    },

    page : 0,
    rowsPerPage : 50,    
  };

  estilo = {
    marginLeft : 30,
    marginTop: 30,
    marginRight : 30,
    addButton: {
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      float: 'right', // alinhamento a direita
    }
  }

  constructor(props) {
    super(props);
  }

  async componentDidMount(){
    var date = moment().format('YYYY-MM-DD');
    console.log('a data é: ' + date);
    
    const { searchFilter } = this.state;
    //searchFilter['dataServicoFilter'] = moment(date, 'YYYY-MM-DD', true).format('YYYY-MM-DD');
    searchFilter['dataServicoFilter'] = date;
    this.setState({searchFilter: searchFilter });       


    this.loadOSs();
  }

  /* Carrega Ordens de Serviço */
  loadOSs = async (opcao) =>{
    if (opcao === undefined){
      opcao = this.state.selectedOptionUser;
    }

    const { searchFilter } = this.state;
    var params = '';
    params = 'FIRST=500';
    params = params + '&SKIP=0';
    //console.log('the searchField-situacaoOSFilter:' + searchFilter.situacaoOSFilter);

    if (!(searchFilter.situacaoOSFilter ==='')){
      params = params + '&TBOS.SITUACAO=' + "'" + searchFilter.situacaoOSFilter + "'";
    }
        
    if (!(searchFilter.nomeFantasiaClienteFilter ==='')){
      params = params + '&TBCLIENTE.FANTASIA=LIKE' + searchFilter.nomeFantasiaClienteFilter;
    }

    if (!(searchFilter.horaServicoFilter.trim() === '')){
      ///console.log('the hour is: ' + searchFilter.horaServicoFilter);
      params = params + '&TBITENSOS.HORAINSERVICO=' + "'" + searchFilter.horaServicoFilter + "'";
    }


    //console.log('data inicial filter' + searchFilter.dataInicialFilter);
    //console.log('data final filter' + searchFilter.dataFinalFilter);
    //console.log('considerarDataFilter' + searchFilter.considerarDataFilter);    

    if ((!(searchFilter.dataServicoFilter.trim() ===''))) {
      params = params + '&TBITENSOS.DATASERVICO =' + "'" + searchFilter.dataServicoFilter + "'";
    }    

    if ((!(searchFilter.dataSaidaChegadaFilter.trim() ===''))) {
      if (searchFilter.tipoChegadaSaidaFilter === ATIPO_TRANSFER_OUT) {
        params = params + '&TBOS.DATAOUT =' + "'" + searchFilter.dataSaidaChegadaFilter + "'";
      }
      else
      {
        params = params + '&TBOS.DATAIN =' + "'" + searchFilter.dataSaidaChegadaFilter + "'";        
      }
    }    


    if (searchFilter.nroOSFilter > 0){
      params = params + '&TBOS.NROOS=' + searchFilter.nroOSFilter;
    }
  
    const response = await api.get('/TControllerItemOS/ItemOS/0/' + '?'+ params)
    this.setState({ oss: response.data});
  }

  setOpcaoUsuario(opcao){
    this.setState({selectedOptionUser : opcao});
    this.loadOSs(opcao);
  }


  
  /* registros por página escolhido */
  handleRowsPerPageChange = event => {
  //alert(event.target.value)
    this.setState({rowsPerPage : event.target.value})
  };
  

  /* Ao escolher página */
  handlePageChange = (event, page) => {
    this.setState({page : page})    
  //    alert(page)  
  };
  /* onBlur - evento ao sair controle - se torna mais rapido quando
  tem muitos registros no retorno*/
  onInputBlur = (e) => {
    const { searchFilter } = this.state;
    //searchFilter.nomeFantasiaClienteFilter = e.target.value;
    if (e.target.name === 'nomeFantasiaClienteFilter'){
      if (!(e.target.value === '')){
        searchFilter[e.target.name] = e.target.value.toUpperCase();    
      }
      //console.log('passou onblue nome fantasia...')
    }
    else
    {
      searchFilter[e.target.name] = e.target.value;              
    }
    //console.log('passou onblue... valor:' + e.target.value)
    /* seta campo */
    //if (e.target.name === 'tipoChegadaSaidaFilter'){
    //}

    if (!(e.target.value === '')){    
      this.setState({searchFilter: searchFilter});    
    }

  }  


  LimpaFiltros = () => {
    let { searchFilter } = this.state;
    searchFilter.nomeFantasiaClienteFilter = '';    
    searchFilter.nroOSFilter = '';        
    searchFilter.dataInicialFilter = ' ';
    searchFilter.dataSaidaChegadaFilter = ' ';    
    searchFilter.situacaoOSFilter = 'A';
    searchFilter.horaServicoFilter = ' ';
    searchFilter.tipoChegadaSaidaFilter = ATIPO_TRANSFER_OUT;
    
    this.setState({searchFilter: searchFilter });       
    document.getElementById('nomeFantasiaClienteFilter').value = '';
    document.getElementById('nroOSFilter').value = '';    
    document.getElementById('situacaoOSFilter').value = AOS_ABERTA;
    document.getElementById('tipoChegadaSaidaFilter').value = ATIPO_TRANSFER_OUT;    

  }
  
/* Pesquisar filtros na tela */
OnChangeSearch = (event) => {
  const { searchFilter } = this.state;
  const input = event.target;
  var value =  input.value;
  searchFilter[event.target.name] = value;
  //if (event.target.name = 'tipoChegadaSaidaFilter'){
    
  //document.getElementById('dataSaidaChegadaFilter').label = 'sddfsdfs';
  //console.log('the value component is: ' + value)
    
  //}
  this.setState({searchFilter: searchFilter });   
};
  
render(){
  const {oss} = this.state;
  const {searchFilter} = this.state;    

  return(
    <div style={this.estilo}>
      <div>
        <span/>
        <div>
          <Link to="/os/novo">
            <Button
              color="primary"
              style={this.estilo.addButton}
              variant="contained"
            >
              Novo
            </Button>
          </Link>
        </div>
      </div>
      <div>
        <Paper 
          hover
          size
          style={{marginBottom: 10, marginTop: 10}}
        >
          <Input 
            id="nroOSFilter"
            name="nroOSFilter"
            //onChange={this.OnChangeSearch}
            onBlur = {this.onInputBlur}
            placeholder="OS"
            style={{marginTop: 15, marginLeft: 15, marginBottom: 10, width: 100}}
            //type="number"
          />

          <TextField
            inputProps={{ maxLength: 50 }} // tamanho máximo permitido
            label="Data do Serviço"
            name="dataServicoFilter"
            onChange={this.OnChangeSearch} 
            //onBlur = {this.onInputBlur}                        
            //type="datetime-local"              
            style={{marginLeft: 20}}            
            //type="date"              
            type="date"              
            value ={searchFilter.dataServicoFilter || ''} 
            //variant="outlined"
          />

          <TextField
            inputProps={{ maxLength: 50 }} // tamanho máximo permitido
            label="Hora"
            name="horaServicoFilter"
            onChange={this.OnChangeSearch} 
            //onBlur = {this.onInputBlur}                        
            //type="datetime-local"              
            style={{marginLeft: 20}}            
            type="Time"              
            //type="hour"              
            value ={searchFilter.horaServicoFilter || ''} 
            //variant="outlined"
          />

          <TextField
            id= "dataSaidaChegadaFilter"
            inputProps={{ maxLength: 50 }} // tamanho máximo permitido
            label={
              (searchFilter.tipoChegadaSaidaFilter === 2) ? 'Data de Saída' : 'Data de Chegada'
            }
            name="dataSaidaChegadaFilter"
            onChange={this.OnChangeSearch} 
            style={{marginLeft: 20}}            
            //type="date"              
            type="date"              
            value ={searchFilter.dataSaidaChegadaFilter || ''} 
            //variant="outlined"
          />
          <Select
            id="tipoChegadaSaidaFilter"
            name="tipoChegadaSaidaFilter"
            native
            //onBlur = {this.onInputBlur}  
            onChange = {this.OnChangeSearch}          
            style={{marginLeft: 20}}            
          >
            <option 
              key={ATIPO_TRANSFER_OUT} 
              value={ATIPO_TRANSFER_OUT}
            > 
              {'OUT'}</option>

            <option 
              key={ATIPO_TRANSFER_IN} 
              value={ATIPO_TRANSFER_IN}
            > 
              {'IN'}</option>
          </Select>


        </Paper>

        <Paper>
          <Select
            id="situacaoOSFilter"
            name="situacaoOSFilter"
            native
            //onChange={this.OnChangeSearch}
            onBlur = {this.onInputBlur}            
            style={{marginLeft: 20}}            
          >
            <option 
              key={AOS_ABERTA} 
              value={AOS_ABERTA}
            > 
              {'Abertas'}</option>

            <option 
              key={AOS_ENCERRADA} 
              value={AOS_ENCERRADA}
            > 
              {'Encerradas'}</option>
            <option 
              key={''} 
              value={''}
            > 
              {'Todas'}</option>
          </Select>
        
        </Paper>

        <Paper 
          hover
          style={{marginBottom: 10},{marginTop: 10}}
        >
          <Input 
            autoFocus
            id="nomeFantasiaClienteFilter"
            name="nomeFantasiaClienteFilter"
            //onChange={this.OnChangeSearch}
            onBlur = {this.onInputBlur}
            placeholder="Nome Pax"
            style={{marginBottom: 10, width: 300, marginLeft:10}}
            value = {this.state.inputValue} 
            //value={this.state.searchFilter.nameCustomer}
          />
          <Button
            color="primary"
            id="pesquisar"
            onClick={() => this.loadOSs()}
          >
            <SearchIcon />
          </Button>

          <Button
            color="primary"
            onClick={() => this.LimpaFiltros()}
          >
            <ClearAllIcon/>
          </Button>

        </Paper>
      </div>
      <div>
        <br />
      </div>

      <div>
        <Card
          //{...rest}
          //className={clsx(classes.root, className)}
        >
          <CardContent>
            <PerfectScrollbar>
              <div >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Data Serviço</TableCell>                      
                      <TableCell>Hs</TableCell>                                            
                      <TableCell>Hs Apres.</TableCell>                                                                  
                      <TableCell>Serviço</TableCell>
                      <TableCell>Número OS</TableCell>
                      <TableCell>Pax</TableCell>               
                      <TableCell>Ações</TableCell>                                                   
                    </TableRow>
                  </TableHead>                  
                  <TableBody>
                    {oss.slice(0, 10).map(os => (
                      <TableRow
                        hover
                        key={os.codItemOS}
                      >
                        <TableCell width="100">{os.dataServico}</TableCell>                        
                        <TableCell width="5">{os.horaInServico}</TableCell>                                        
                        <TableCell width="5">{os.horaApresentacao}</TableCell>                                                                
                        <TableCell width="50">{os.descServico}</TableCell>
                        <TableCell width="10">{os.nroOS}</TableCell>

                        <TableCell width="10">
                          <Tooltip
                            onClose={this.handleTooltipClose}
                            onOpen={this.handleTooltipOpen}
                            open={this.state.open}
                            title={os.pax}
                          >
                            <Button>{os.resumoPax}</Button>
                          </Tooltip>
                        </TableCell>                        

                        <TableCell width="1">

                          {this.props.ehModal ?           
                            <Button
                              color="primary"
                              onClick={() => this.clickModalCliente(os.nroOS)}                            
                            > 
                              <RemoveRedEyeIcon
                              />
                            </Button>
                            : null}
                          {!this.props.ehModal ?           
                            <Link to={{ pathname: `/os/${os.nroOS}`, state: { backUrl: '/os/10000'} }}>
                              <Button
                                color="primary"
                              >
                                <RemoveRedEyeIcon/>
                              </Button>
                            </Link>
                            : null}

                          <Button
                            color="primary"
                            onClick={() => this.setState({modalDeletionIsOpen: true,
                              selectedClienteDelete : os.codOS,
                              osToDelete : os
                            })}                             
                          >
                            <DeleteOutlineIcon
                              style ={{ display: 'none'}}/>
                          </Button>

                          {this.props.showOptionSelectRecord ? 
                            <Button
                              onClick={() => this.props.lookupClienteCallBack(os.codOS)}>
                              <CheckIcon/>
                            </Button> : null}


                        </TableCell>                        

                      </TableRow>

                    ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
        </Card>        
      </div>

      <CardActions >
        <TablePagination
          component="div"
          count={oss.length}  
          labelRowsPerPage={'Registros por página'}
          //labelDisplayedRows={'sdfsdfds', 'sdfsd', 'sdfsd', 'sdfsd'}
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleRowsPerPageChange}
          //page={page}
          page={this.state.page}          
          rowsPerPage={this.state.rowsPerPage}
          //rowsPerPageOptions={['30', 50, { value: 10, label: 'All' }]}            
          //rowsPerPage={50}          
          rowsPerPageOptions={[50, 100, 150, 200]}
          //rowsPerPageOptions={[10, 20, 30, 40], {label:'xyz'}}            
        />
      </CardActions>

    </div>

  );
}
}
export default OSList;
