import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const DeleteConfirmationModal = ({ open, handleClose, itemId, deleteRoute, message, handleDelete }) => {
  const classes = useStyles();

  /*const handleDelete = () => {
    // Lógica para executar a exclusão do item usando a rota deleteRoute
    // ...
    console.log('irá deletar...')
    // Após a exclusão, feche a modal
    handleClose();
  }; */

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <div className={classes.paper}>
        <Typography variant="h6" gutterBottom>
          Confirmação de Exclusão
        </Typography>
        <Typography variant="body1">
          {message}
        </Typography>
        <div style={{ marginTop: '16px' }}>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="contained" color="secondary" onClick={handleDelete} style={{ marginLeft: '16px' }}>
            Excluir
          </Button>
        </div>
      </div>
    </Modal>
  );
};

DeleteConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
  message : PropTypes.string.isRequired,
  handleDelete : PropTypes.func.isRequired,
};

export default DeleteConfirmationModal;
