import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { InputLabel, Select } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'left',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 10
    },
    margin: {
        marginLeft: theme.spacing(2)
    },

}));

const ComboTipoChavePix = props => {
    const { className, ...rest } = props;
    const classes = useStyles();

    function Tipos() {
        const tipos = [
            { id: 1, descTipo: 'CPF/CNPJ' },
            { id: 2, descTipo: 'EMAIL' },
            { id: 3, descTipo: 'CELULAR' },
            { id: 4, descTipo: 'ALEATÓRIO' }
        ];

        const listTipos = tipos.map(
            (tipo) => {
                return (                    
                    <option
                        key={tipo.id}
                        value={tipo.id}
                    >{tipo.descTipo}</option>
                )
            }
        )

        return (
            <div>
                <InputLabel
                    htmlFor="outlined-age-native-simple"
                >Tipo</InputLabel>
                <Select
                    fullWidth
                    inputProps={{
                        id: 'outlined-age-native-simple',
                        name: rest.name
                    }}
                    label={rest.label}
                    native
                    onChange={rest.onChange}
                    size
                    value={rest.value}
                >
                    <option
                        aria-label="Não Definido"
                        value="0"
                    />
                    {listTipos}
                </Select>
            </div>
        )
    }

    return (
        <Tipos />

    );
};

ComboTipoChavePix.propTypes = {
    className: PropTypes.string
};

export default ComboTipoChavePix;