import { FORMA_DINHEIRO, FORMA_DOLAR, FORMA_EURO,  FORMA_PESO, FORMA_GUARANI } from 'consts';


/*  retornar a ISO da Moeda e abreviatura em Objeto */
export function getISOMoeda(moeda) {
  let abrevMoeda = '';
  let isoMoeda = '';


  switch (parseInt(moeda)) {
    case FORMA_DINHEIRO:
      abrevMoeda = 'pt-BR';
      isoMoeda = 'BRL';
      break;
    case FORMA_DOLAR:
      abrevMoeda = 'en-US';
      isoMoeda = 'USD';
     break;
     case FORMA_PESO:
      abrevMoeda = 'es-AR';
      isoMoeda = 'ARS';
     break;
     case FORMA_EURO:
      abrevMoeda = 'en-US';
      isoMoeda = 'EUR';
     break;
     case FORMA_GUARANI:
      abrevMoeda = 'es-PY';
      isoMoeda = 'PYG';
     break;
  } 
  const objMoeda = {
    abrevMoeda : abrevMoeda,
    isoMoeda : isoMoeda
  };
  return objMoeda;
}
