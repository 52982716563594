import uuid from 'uuid/v1';
import moment from 'moment';

var totalOpcionais = 200;
var totalTransporte = 100;
var totalHospedagem = 300;
var totalAereo = 120;
var totalSeguroViagem = 80;
var totalOutros = 21.40;

export default [
  {
    id: uuid(),
    name: 'Opcionais',
    imageUrl: '/images/products/product_1.png',
    updatedAt: moment().subtract(2, 'hours'),
    totalGenero : totalOpcionais.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
  },
  {
    id: uuid(),
    name: 'Transporte',
    imageUrl: '/images/products/product_2.png',
    updatedAt: moment().subtract(2, 'hours'),
    totalGenero : totalTransporte.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})

  },
  {
    id: uuid(),
    name: 'Hospedagem',
    imageUrl: '/images/products/product_3.png',
    updatedAt: moment().subtract(3, 'hours'),
    totalGenero : totalHospedagem.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
  },
  {
    id: uuid(),
    name: 'Aéreo',
    imageUrl: '/images/products/product_4.png',
    updatedAt: moment().subtract(5, 'hours'),
    totalGenero : totalAereo.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
  },
  {
    id: uuid(),
    name: 'Seguro Viagem',
    imageUrl: '/images/products/product_5.png',
    updatedAt: moment().subtract(9, 'hours'),
    totalGenero : totalSeguroViagem.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
  },
  {
    id: uuid(),
    name: 'Outros',
    imageUrl: '/images/products/product_6.png',
    updatedAt: moment().subtract(9, 'hours'),
    totalGenero : totalOutros.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
  }

];
