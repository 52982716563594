import React, { Component } from 'react';
import { Button, Card, CardContent, Table, TableCell, TableRow, TableHead, TableBody, Paper,
Input, InputLabel, FormControl} from '@material-ui/core';
import api from '../../../services/api';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal/DeleteConfirmationModal';
import AlertInformation from '../../../components/AlertInformation/AlertInformation';
import { Select, MenuItem } from '@material-ui/core';
import { AFERIADO_ESTADUAL, AFERIADO_MUNICIPAL, AFERIADO_NACIONAL, AFERIADO_OUTRO, ANAO, ASIM } from 'consts';
import moment from 'moment';

class FeriadoList extends Component{
  state = {
    feriados : [],
    searchFilter : {
      nomeFeriado : '',
      situacaoInativoFilter : ANAO,
      dia : '',
      mes : ''
    },
  };

  estilo = {
    marginLeft: 30,
    marginTop: 30,
    marginRight: 30,
    container: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    addButton: {
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      float: 'right',
      marginTop: 3,
      marginRight: 3
    },
    redText: {
      color: 'red',
    },  
  };

  constructor(props) {
    super(props);
  }

  /* carregamento inicial */
  async componentDidMount(){
    this.loadFeriados();
  }

  /* Carrega Feriados */
  loadFeriados = async () =>{
    const { searchFilter } = this.state;
    let params = '';

    params += '?sort=data';

    /* consulta pelo nome do feriado */
    if (!(searchFilter.nomeFeriado === '')){
      params += '&tbferiado.descferiado=_like_' + searchFilter.nomeFeriado
    }

    /* consulta pelo dia */
    if (!(searchFilter.dia === '')){
      params += '&dia=' + searchFilter.dia
    }

    /* consulta pelo mês */
    if (!(searchFilter.mes === '')){
      params += '&mes=' + searchFilter.mes
    }

    /* filtra situação do feriado */
    switch (searchFilter.situacaoInativoFilter) {
      case ASIM:
        if (params){
          params += '&tbferiado.inativo=' + ASIM
        }
        else
          params += '?tbferiado.inativo=' + ASIM;
      break;
      case ANAO:
        if (params){
          params += '&tbferiado.inativo=' + ANAO
        }
        else
          params += '?tbferiado.inativo=' + ANAO  ;
        break;
      default:
        break;
    }

    const response = await api.get('/feriado' + params);

    for (let i = 0; i < response.data.length; i++) {
      response.data[i].data = moment(response.data[i].data).format("DD/MM/YYYY");

      switch (response.data[i].tipoferiado) {
        case AFERIADO_NACIONAL:
          response.data[i].desctipoferiado = 'NACIONAL'
          break;
        case AFERIADO_ESTADUAL:
          response.data[i].desctipoferiado = 'ESTADUAL'
          break;
        case AFERIADO_MUNICIPAL:
          response.data[i].desctipoferiado = 'MUNICIPAL'
          break;
        case AFERIADO_OUTRO:
          response.data[i].desctipoferiado = 'OUTRO'
          break;
      }      
    }

    this.setState({ feriados : response.data });
  }

  /* Escolha de um feriado para exclusão */
  handleSelectItem = (id) => {
    this.setState({selectedId: id}); 
  };

  /* deleta feriado */
  handleDelete = async (id) => {
    try {
      const response = await api.delete(`/feriado/${id}`);
      // Verifica se a exclusão foi bem-sucedida
      if (response.status === 200) {
        this.handleClose();
        this.loadFeriados();
      }
    } catch (error) {
        this.handleClose();
        this.handleOpenAlert(error.response.data.error);
        console.log(error);
        console.log(error.message);
    }
  };

  /* abre janela confirmação para exclusão */
  handleOpen = (codferiado) => {
    this.setState({ open: true });
    this.handleSelectItem(codferiado);
  };

  /* abre janela de alerta com mensagem */
  handleOpenAlert = (message) => {
    this.setState({ openAlert: true });
    this.setState({ messageAlert: message });
  };  
  
  /* fecha janela de confirmação exclusão */
  handleClose = () => {
    this.setState({ open: false });
  };

  /* fecha janela mensagem alerta */
  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  }

  /* Pesquisar filtros na tela */
  OnChangeSearch = (event) => {
    const { searchFilter } = this.state;
    let { name, value } = event.target;
    if (name === 'nomeFeriado'){
      value = value.toUpperCase()
    }
    searchFilter[name] = value;
    this.setState({searchFilter: searchFilter });
    if (name === 'situacaoInativoFilter'){
      this.loadFeriados();
    }

  };

  /* Limpa Filtros na tela */
  LimpaFiltros = () => {
    let { searchFilter } = this.state;
    searchFilter.nomeFeriado = '';
    searchFilter.situacaoInativoFilter = 'N';
    searchFilter.dia = '';
    searchFilter.mes = '';
    this.setState({searchFilter: searchFilter });       
  }  

  render(){
    const {feriados} = this.state;
    const {searchFilter} = this.state;
    return(
      <div style={this.estilo}>
        <Button
          component={Link}
          to="/financas/feriados/novo"
          variant="contained"
          color="primary"
          style={this.estilo.addButton}
        >
           Novo
        </Button>

        <Paper hover>
          <Input 
            autoFocus
            id="nomeFeriado"
            name="nomeFeriado"
            onBlur = {this.onInputBlur}
            onChange = {this.OnChangeSearch} 
            placeholder = "Nome feriado a pesquisar"
            style={{marginBottom: 10, width: 300, marginLeft:20, marginTop: 16 }}
            value = {searchFilter.nomeFeriado}
          />
            
          <FormControl
            style={{ marginLeft: 20 , width: 80}}
            disabled={this.state.isView}
          >
            <InputLabel htmlFor="outlined-age-native-simple">Dia</InputLabel>
            <Select
              id="dia"
              name="dia"
              value={searchFilter.dia || ''}
              onChange={this.OnChangeSearch}
              label="dia"
            >
              <MenuItem value=''>
                <em>Qualquer</em>
              </MenuItem>
              <MenuItem value={1}>01</MenuItem>
              <MenuItem value={2}>02</MenuItem>
              <MenuItem value={3}>03</MenuItem>
              <MenuItem value={4}>04</MenuItem>
              <MenuItem value={5}>05</MenuItem>
              <MenuItem value={6}>06</MenuItem>
              <MenuItem value={7}>07</MenuItem>
              <MenuItem value={8}>08</MenuItem>
              <MenuItem value={9}>09</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={11}>11</MenuItem>
              <MenuItem value={12}>12</MenuItem>
              <MenuItem value={13}>13</MenuItem>
              <MenuItem value={14}>14</MenuItem>
              <MenuItem value={15}>15</MenuItem>
              <MenuItem value={16}>16</MenuItem>
              <MenuItem value={17}>17</MenuItem>
              <MenuItem value={18}>18</MenuItem>
              <MenuItem value={19}>19</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={21}>21</MenuItem>
              <MenuItem value={22}>22</MenuItem>
              <MenuItem value={23}>23</MenuItem>
              <MenuItem value={24}>24</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={26}>26</MenuItem>
              <MenuItem value={27}>27</MenuItem>
              <MenuItem value={28}>28</MenuItem>
              <MenuItem value={29}>29</MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={31}>31</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            style={{ marginLeft: 20 , width: 100}}
            disabled={this.state.isView}
          >
            <InputLabel htmlFor="outlined-age-native-simple">Mês</InputLabel>
            <Select
              id="mes"
              name="mes"
              value={searchFilter.mes || ''}
              onChange={this.OnChangeSearch}
              label="mes"
            >
              <MenuItem value=''>
                <em>Qualquer</em>
              </MenuItem>
              <MenuItem value={1}>Janeiro</MenuItem>
              <MenuItem value={2}>Fevereiro</MenuItem>
              <MenuItem value={3}>Março</MenuItem>
              <MenuItem value={4}>Abril</MenuItem>
              <MenuItem value={5}>Maio</MenuItem>
              <MenuItem value={6}>Junho</MenuItem>
              <MenuItem value={7}>Julho</MenuItem>
              <MenuItem value={8}>Agosto</MenuItem>
              <MenuItem value={9}>Setembro</MenuItem>
              <MenuItem value={10}>Outubro</MenuItem>
              <MenuItem value={11}>Novembro</MenuItem>
              <MenuItem value={12}>Dezembro</MenuItem>
            </Select>
          </FormControl>

          <Select
            id="situacaoInativoFilter"
            name="situacaoInativoFilter"
            style={{marginLeft: 20, marginTop: 16}}
            value={searchFilter.situacaoInativoFilter || ''}
            onChange={this.OnChangeSearch}
          >
            <MenuItem value={ANAO}>Ativos</MenuItem>
            <MenuItem value={ASIM}>Inativos</MenuItem>
            <MenuItem value={'T'}>Todos</MenuItem>
          </Select>

          <Button
            color="primary"
            id="pesquisar"
            onClick={() => this.loadFeriados()}
          >
            <SearchIcon />
          </Button>

          <Button
            color="primary"
            onClick={() => this.LimpaFiltros()}
          >
            <ClearAllIcon/>
          </Button>
        </Paper>

        <Card>
          <CardContent>
            <PerfectScrollbar>
              <div >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Data</TableCell>
                      <TableCell>Feriado</TableCell>
                      <TableCell>Tipo de Feriado</TableCell>
                      <TableCell>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {feriados.slice(0, 100).map(feriado => (
                      <TableRow
                        hover
                        key={feriado.codferiado}
                      >
                        <TableCell style={(feriado.inativo === ASIM) ? this.estilo.redText : {}}>{feriado.data}</TableCell>
                       
                        <TableCell style={(feriado.inativo === ASIM) ? this.estilo.redText : {}} >{feriado.descferiado}</TableCell>

                        <TableCell style={(feriado.inativo === ASIM) ? this.estilo.redText : {}} >{feriado.desctipoferiado}</TableCell>
                        
                        <TableCell>
                          <Link to={`/financas/feriados/${feriado.codferiado}`}>
                            <EditOutlinedIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                          </Link>
                          <Link to={`/financas/feriados/${feriado.codferiado}/view`}>
                            <PageviewOutlinedIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                          </Link>
                          <DeleteForeverOutlinedIcon 
                              onClick={() => this.handleOpen(feriado.codferiado)}
                              style={{ cursor: 'pointer', marginRight: '10px' }} />
                        </TableCell>
                        
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
        </Card>        

        <DeleteConfirmationModal
          open={this.state.open}
          handleClose={() => this.handleClose()}
          itemId={this.state.selectedId}
          message={"Confirma exclusão do feriado " + this.state.selectedId + '?'}
          handleDelete={()=> this.handleDelete(this.state.selectedId)}
        />

        <AlertInformation
          open={this.state.openAlert}
          handleClose={() => this.handleCloseAlert()}
          message={this.state.messageAlert}
        />
       </div>
    );
  }
}

export default FeriadoList;