import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/styles';
import api from 'services/api';
import { ANAO } from 'consts';

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'left',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 10
    },
    margin: {
        marginLeft: theme.spacing(2)
    },

}));

const ComboSetor = props => {
    const { className, ...rest } = props;
    const classes = useStyles();
    const [setoresx, setSetores] = useState({
        setores: []
    });

    useEffect(() => {
        async function loadSetores() {
            let params = '?tbsetor.inativo=' + ANAO;

            try {
                const response = await api.get('/setor' + params);
                if (response.status === 200) {
                    setSetores({ setores: response.data });

                    return response.data;
                }
            } catch (error) {
                console.log(error);
            }
        }

        loadSetores();
    }, []);

    function Setores() {
        var listSetores = setoresx.setores;

        return (
            <div>
                <Select
                    fullWidth
                    inputProps={{
                        id: 'outlined-age-native-simple',
                        name: rest.name
                    }}
                    label={rest.label}
                    native 
                    onChange={rest.onChange}
                    value={rest.value}
                >
                    <option
                        aria-label="Não Definido"
                        value="-1"
                    />
                    {listSetores.map(setor => (
                        <option
                            key={setor.codsetor}
                            value={setor.codsetor}
                        >
                            {setor.descsetor}</option>
                    ))}
                </Select>
            </div>
        )
    }

    return (
        <Setores />
    );
};

ComboSetor.propTypes = {
    className: PropTypes.string
};

export default ComboSetor;