import api from '../../../services/api';React
import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {CardContent,  FormGroup, TextField, colors } from '@material-ui/core';
import { Button } from '@material-ui/core';
import AlertInformation from '../../../components/AlertInformation/AlertInformation';
import moment from 'moment';
import { Link } from 'react-router-dom';

class FechamentoFinanceiroForm extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      fechamentofinanceiro: {},
      maxData : '',
      isNewRecord : false,
      isView : false,      
      schema : {
        usuario: {
          presence: { allowEmpty: false, message: 'é obrigatório' },
          length: {
            maximum: 64
          }
        },
      },
      
      useStyles : makeStyles(theme => ({
        root: {
          backgroundColor: theme.palette.background.default,
          height: '100%'
        },
        grid: {          
          height: '100%'
        },
        quoteContainer: {
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        },
        quote: {
          backgroundColor:  theme.palette.neutral,
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: 'url(/images/auth.jpg)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        },
        quoteInner: {
          textAlign: 'center',
          flexBasis: '600px'
        },
        quoteText: {
          color: theme.palette.white,
          fontWeight: 300
        },
        name: {
          marginTop: theme.spacing(3),
          color: theme.palette.white
        },
        bio: {
          color: theme.palette.white
        },
        contentContainer: {},
        content: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        },
        contentHeader: {
          display: 'flex',
          alignItems: 'center',
          paddingTop: theme.spacing(5),
          paddingBototm: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2)
        },
        logoImage: {
          marginLeft: theme.spacing(4)
        },
        contentBody: {
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
          }
        },
        form: {
          paddingLeft: 100,
          paddingRight: 100,
          paddingBottom: 125,
          flexBasis: 700,
          [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
          }
        },
        title: {
          marginTop: theme.spacing(3)
        },
        socialButtons: {
          marginTop: theme.spacing(3)
        },
        socialIcon: {
          marginRight: theme.spacing(1)
        },
        sugestion: {
          marginTop: theme.spacing(2)
        },
        textField: {
          marginTop: theme.spacing(2)
        },
        signInButton: {
          margin: theme.spacing(2, 0)
        },
        alert: {
          color: '#000000',
        },
        addButton: {
          height: '42px',
          display: 'flex',
          alignItems: 'center',
          float: 'right', // alinhamento a direita
          marginTop: 3,
          marginRight: 3
        }
      })),
    };
  }

  async componentDidMount(){
    let { aMaxData } = this.props.match.params;
    const response = await api.get('/financas/getDataUltimoFechamentoFinanceiro/1')                             
      .then(function (response) {
        if (response.data == null) {
          aMaxData = ''
        } else {
          aMaxData = moment(response.data).format("DD/MM/YYYY");
        }
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });

    // carrega informações formulário
    const { id } = this.props.match.params;
    const { location } = this.props;
    const currentPath = location.pathname;    
   
    this.setState({ 
      isNewRecord : true,
      maxData : aMaxData
    });
    const { fechamentofinanceiro } = this.state;
  }
  
  // Change fechamento financeiro
  OnChange = (event) => {  
    const { fechamentofinanceiro } = this.state;
    const input = event.target;
    let value = input.value.toUpperCase();
    fechamentofinanceiro[event.target.name] = value;

    this.setState({ fechamentofinanceiro: fechamentofinanceiro });   
  };

  /* abre janela de alerta com mensagem */
  handleOpenAlert = (message) => {
    this.setState({ openAlert: true });
    this.setState({ messageAlert: message});
  };

  /* fecha janela mensagem alerta */
  handleCloseAlert = (message) => {
    this.setState({ openAlert: false });
    if (message == 'Fechamento realizado com sucesso!') {
      window.location.reload(false);
    }
  }

  /* Click salvar */
  onClickSave = () =>{
    if (this.validationRequiredFields()){
      if (this.state.isNewRecord){
        this.insereFechamentoFinanceiro()
      }
    }
  }

  /* valida campos obrigatórios ao salvar */
  validationRequiredFields() {
    const { fechamentofinanceiro } = this.state

    if( (String(fechamentofinanceiro.datafechamento).trim() === '')  || (fechamentofinanceiro.datafechamento === undefined)){
      fechamentofinanceiro.datafechamento = '';
      this.setState({fechamentofinanceiro: fechamentofinanceiro });
      return false
    } else 
    {
      return true
    }  
  }

  /* insere novo fechamento financeiro */
  insereFechamentoFinanceiro = async () =>{
    const { fechamentofinanceiro } = this.state;
    fechamentofinanceiro.codusuariofechou = parseInt(sessionStorage.getItem('codUser'));    
    fechamentofinanceiro.codempresa = parseInt(sessionStorage.getItem('codEmpresa'));    
    
    try {
      const response = await api.post(`/financas/fechamentofinanceiro`, fechamentofinanceiro);
      if (response.status === 200) {
        this.handleOpenAlert('Fechamento realizado com sucesso!');
      }
    } catch (error) {
      this.handleOpenAlert(error.response.data.error);
    }
  }

  render() {
    const { fechamentofinanceiro } = this.state;
    return (
      <div>
        <CardContent>
          <Button
            component={Link}
            to="/financas/fechamentosfinanceiros"
            variant="contained"
            color="primary"
            style={{ float : 'right '}}
          >
            Fechamentos
          </Button>

          <Typography 
            component="h1"
            variant="h3"
          >
            Fechamento Financeiro
          </Typography>            

          <FormGroup row>
            <TextField
              InputLabelProps={{ shrink: true, required: false }}
              inputProps={{ maxLength: 50 }}
              label="Data fechamento"
              name="datafechamento"
              onChange={this.OnChange}                     
              style={{marginTop : 20, width : 150 }}
              type="date"            
              value={fechamentofinanceiro.datafechamento || ''}
              variant="outlined"
              helperText={fechamentofinanceiro.datafechamento === '' ? 'Data fechamento obrigatória' : ' '}
              disabled={this.state.isView}
            />
          </FormGroup>

          <FormGroup row>
            <Typography style={{ marginTop:'5px' }}>
                Fechado até:
            </Typography>

            <Typography style={{ marginTop:'5px', marginLeft: '10px',
                                paddingLeft: 5, paddingRight: 5,
                                backgroundColor: colors.grey[400] }}>
                {this.state.maxData}
            </Typography>
          </FormGroup>
            
          <br />

          <Button
            name="confirmar"
            color="primary"
            variant="contained"
            onClick={this.onClickSave}
            style={{ marginTop: '10px' }} 
            disabled={this.state.isView}
          >
            Confirmar
          </Button>
        </CardContent>

        <AlertInformation
          open={this.state.openAlert}
          handleClose={() => this.handleCloseAlert(this.state.messageAlert)}
          message={this.state.messageAlert}
        />
      </div>
    );
  }
}

FechamentoFinanceiroForm.propTypes = 
{
  classes: PropTypes.object.isRequired
};

export default FechamentoFinanceiroForm;