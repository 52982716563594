import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Profile, SidebarNav } from './components';
import HotelIcon from '@material-ui/icons/Hotel';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EventNoteIcon from '@material-ui/icons/EventNote';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const SideBarAppCli = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'Programação',
      href: '/appcli/programacao',
      icon: <EventNoteIcon />
    },
    {
      href: '/appcli/hospedagem',
      title: 'Hospedagem',
      icon: <HotelIcon />
    },

    {
      title: 'Fale conosco',
      href: '/appcli/faleconosco',
      icon: <ChatBubbleIcon />
    },

    {
      title: 'Sair',
      href: '/logout',  
      icon: <LockOpenIcon />
    }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};

SideBarAppCli.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default SideBarAppCli;
