import api from '../../../services/api';React
import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {CardContent,  FormGroup, TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import AlertInformation from '../../../components/AlertInformation/AlertInformation';

class PaisForm extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      pais : {},
      isNewRecord : false,
      isView : false,
      schema : {
        usuario: {
          presence: { allowEmpty: false, message: 'é obrigatório' },
          length: {
            maximum: 64
          }
        },
      },
      
      useStyles : makeStyles(theme => ({
        root: {
          backgroundColor: theme.palette.background.default,
          height: '100%'
        },
        grid: {          
          height: '100%'
        },
        quoteContainer: {
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        },
        quote: {
          backgroundColor:  theme.palette.neutral,
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: 'url(/images/auth.jpg)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        },
        quoteInner: {
          textAlign: 'center',
          flexBasis: '600px'
        },
        quoteText: {
          color: theme.palette.white,
          fontWeight: 300
        },
        name: {
          marginTop: theme.spacing(3),
          color: theme.palette.white
        },
        bio: {
          color: theme.palette.white
        },
        contentContainer: {},
        content: {
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        },
        contentHeader: {
          display: 'flex',
          alignItems: 'center',
          paddingTop: theme.spacing(5),
          paddingBototm: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2)
        },
        logoImage: {
          marginLeft: theme.spacing(4)
        },
        contentBody: {
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
          }
        },
        form: {
          paddingLeft: 100,
          paddingRight: 100,
          paddingBottom: 125,
          flexBasis: 700,
          [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
          }
        },
        title: {
          marginTop: theme.spacing(3)
        },
        socialButtons: {
          marginTop: theme.spacing(3)
        },
        socialIcon: {
          marginRight: theme.spacing(1)
        },
        sugestion: {
          marginTop: theme.spacing(2)
        },
        textField: {
          marginTop: theme.spacing(2)
        },
        signInButton: {
          margin: theme.spacing(2, 0)
        },
        alert: {
          color: '#000000',
        }
      })),
    };
  }

  async componentDidMount(){    
    // carrega informações formulário
    const { id } = this.props.match.params;
    const { location } = this.props;
    const currentPath = location.pathname;    
    
    if (id >= 0) {
      const response = await api.get(`/pais/${id}`);
  
      this.setState({ pais: response.data[0],
        isNewRecord : false,
        isView : (currentPath.includes('/view'))}
      );      
      
    }    
    else
    if ( id === 'novo') 
    {
      this.setState({ 
        isNewRecord : true
      });
      this.state.isNewRecord = true;
      const { pais } = this.state;
    }
  }
  
  // Change país
  OnChange = (event) => {  
    const { pais } = this.state;
    const input = event.target;
    let value = input.value.toUpperCase();
    pais[event.target.name] = value;

    this.setState({ pais: pais });   
  };

  /* atualiza dados de país existente - post */
  atualizaPais = async () =>{
    const { pais } = this.state;

    pais.nomepais = pais.nomepais.trim();   
    pais.abreviatura = pais.abreviatura.trim();

    pais.possuireldiplomatica = '';
    pais.codtipodocpessoal = '';
    pais.formatotipodocpessoal = '';

    try {
      const response = await api.post(`/pais/${pais.codpais}`, pais);
      if (response.status === 200) {
        this.props.history.push('/financas/paises');
      }
    } catch (error) {
      this.handleOpenAlert(error.response.data.error);
    }
  }

  /* abre janela de alerta com mensagem */
  handleOpenAlert = (message) => {
    this.setState({ openAlert: true });
    this.setState({ messageAlert: message});
  };

  /* fecha janela mensagem alerta */
  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  }

  /* Click salvar */
  onClickSave = () =>{
    if (this.validationRequiredFields()){
      if (this.state.isNewRecord){
        this.inserePais()
      }
      else
      {
       this.atualizaPais();
     }
    }
  }

  /* Click cancelar */
  onClickCancel = () =>{
    this.props.history.push('/financas/paises');
  }

  /* verifica se tem erro campos atendimento */
  hasErrorField = (field, value) => {
    if (field === 'nomepais') {
      if ((String(value).trim() === ''))
      {
        return true
      }
    }
  }

  /* valida campos obrigatórios ao salvar */
  validationRequiredFields() {
    const { pais } = this.state

    if((String(pais.nomepais).trim() === '')  || (pais.nomepais === undefined)){
        pais.nomepais = '';
      this.setState({pais: pais });
      return false
    } else 
    if((String(pais.abreviatura).trim() === '')  || (pais.abreviatura === undefined)){
        pais.abreviatura = '';
      this.setState({pais: pais });
      return false
    } else 
    {
      return true
    }  
  }

  /* insere novo país */
  inserePais = async () =>{
    const { pais } = this.state;
    
    pais.nomepais = pais.nomepais.trim();
    pais.abreviatura = pais.abreviatura.trim();
    
    try {
      const response = await api.post(`/pais`, pais);
      if (response.status === 200) {
        this.props.history.push('/financas/paises');
      }
    } catch (error) {
      this.handleOpenAlert(error.response.data.error);
    }
  }

  render() {
    const { pais } = this.state;
    return (
      <div>
        <CardContent>
          <Typography 
            component="h1"
            variant="h3"
          >
            País {pais.codpais} 
          </Typography> 
          
          <FormGroup>
            <TextField
              className={this.state.useStyles.textField}
              label="Nome do país"
              style={{ marginTop: 20 }} 
              name="nomepais"
              value = {pais.nomepais || ''}
              variant="outlined"
              onChange={this.OnChange}
              helperText={pais.nomepais === '' ? 'Nome do país obrigatório' : ' '}
              disabled={this.state.isView}
              inputProps={{ maxLength: 50 }}
            />
          </FormGroup>

          <FormGroup>
            <TextField
              className={this.state.useStyles.textField}
              label="Abreviatura"
              style={{ marginTop: 10, width: 200 }} 
              name="abreviatura"
              value = {pais.abreviatura || ''}
              variant="outlined"
              onChange={this.OnChange}
              helperText={pais.abreviatura === '' ? 'Abreviatura obrigatória' : ' '}
              disabled={this.state.isView}
              inputProps={{ maxLength: 3 }}
            />
          </FormGroup>
          
          <br />
          <br />

          <Button
            name="salvar"
            color="primary"
            variant="contained"
            onClick={this.onClickSave}
            style={{ marginTop: '10px' }} 
            disabled={this.state.isView}
          >
            Salvar
          </Button>

          <Button
            name="cancelar"
            color="primary"
            variant="contained"
            onClick={this.onClickCancel}
            style={{ marginTop: '10px', marginLeft: '10px' }}
          >
            Cancelar
          </Button>
        </CardContent>

        <AlertInformation
          open={this.state.openAlert}
          handleClose={() => this.handleCloseAlert()}
          message={this.state.messageAlert}
        />
      </div>
    );
  }
}

PaisForm.propTypes = 
{
  classes: PropTypes.object.isRequired
};

export default PaisForm;