import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import App from './App';

require('dotenv').config(); // enable var. environment

//const cors = require("cors");
//App.use(cors());


//var corsOptions = {
//origin: "http://localhost:3000"
//};

//App.use(cors(corsOptions));

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
