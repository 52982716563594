import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/styles';
import InputLabel from '@material-ui/core/InputLabel';
import { ASIM, FORMA_DINHEIRO, FORMA_DOLAR, FORMA_PESO, FORMA_GUARANI, FORMA_EURO, FORMA_BOLIVAR, FORMA_PESO_CHILENO, FORMA_SOLES } from 'consts';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'left',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 10
  },
  margin: {
    marginLeft: theme.spacing(2)
  },

}));

const ComboMoeda = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  //console.log('vai carregar o componente combo ano ' + rest.ano )

  function Moedas() {
    const moedas = [
      { id: FORMA_DINHEIRO, descMoeda: 'BRL' },
      { id: FORMA_DOLAR, descMoeda: 'USD' },
      { id: FORMA_EURO, descMoeda: 'EUR' },
      { id: FORMA_PESO, descMoeda: 'ARS' },
      { id: FORMA_GUARANI, descMoeda: 'PYG' },
      { id: FORMA_SOLES, descMoeda: 'PEN' },
      { id: FORMA_BOLIVAR, descMoeda: 'VEF' },
      { id: FORMA_PESO_CHILENO, descMoeda: 'CLP' }
    ];

    const listMoedas = moedas.map(
      (moeda) => {
        return (
          <option
            key={moeda.id}
            value={moeda.id}
          >{moeda.descMoeda}</option>
        )
      }
    )


    return (
      // <div style={{display: (sessionStorage.getItem('trabOutraMoeda') === ASIM) ? '' : 'none'}} >
      <div style={{display: rest.visible ? '' : 'none'}} >
        <InputLabel
          htmlFor="outlined-age-native-simple"
        >Moeda</InputLabel>
        <Select
          inputProps={{
            id: 'outlined-age-native-simple',
            name: rest.name
          }}
          fullWidth
          label={rest.label}
          native
          onChange={rest.onChange}
          size
          value={rest.value}
        >
          {listMoedas}
        </Select>
      </div>
    )
  }

  return (
    <Moedas />

  );
};

ComboMoeda.propTypes = {
  className: PropTypes.string
};

export default ComboMoeda;