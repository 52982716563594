import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import api from '../../services/api';
import { FinanceiroContaCorrente, FinanceiroGraficoCreditoDebito, FinanceiroSaldoPorConta, FinanceiroGraficoPorSetor } from './components';
import ComboMes from '../Components/ComboMes';
import ComboAno from '../Components/ComboAno';
import ComboMoeda from '../Components/ComboMoeda';
import { ASIM } from 'consts';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const MenuFinanceiro = () => {
  const classes = useStyles();


  const [ano, setAno] = useState({
    //ano : new Date().getFullYear()
    ano : sessionStorage.getItem('anoComboProd')
    //ano : aano
  });

  const [mes, setMes] = useState({
    mes : new Date().getMonth() + 1 // esta funcao comeca com 0 zero janeiro
  });


  const [moeda, setMoeda] = useState({
    moeda : sessionStorage.getItem('moeda')
  });


  const handleChange = event => {
    event.persist();

    if (event.target.name === 'cmbAno'){
      console.log('setará o ano no evento handlechange' + event.target.value )
      setAno({ano : event.target.value});
      sessionStorage.setItem('anoComboProd', event.target.value);    
    }
    if (event.target.name === 'cmbMes'){
      //console.log('setará o mes' + event.target.value )
      setMes({mes : event.target.value});
      sessionStorage.setItem('mesComboProd', event.target.value);          
    }
    if (event.target.name === 'cmbMoeda'){
      setMoeda({moeda : event.target.value});
      sessionStorage.setItem('moeda', event.target.value);          
    }

    window.location.reload(); 
  }

  /* Indicadores DashBoard */
  const [financeiro, setFinanceiro] = useState({
    saldoGeral : 0,
    saldoEmBanco :0,
    saldoEmCaixa :0,
    totalCredito :0,
    totalDebito : 0,
    listaSaldosContas : [],
    despesasPorSetor : []
  });

  useEffect(() => {
    /* se não tiver ano definido setará ano atual */
    if (sessionStorage.getItem('anoComboProd') == null){
      sessionStorage.setItem('anoComboProd', new Date().getFullYear());   
    }
    /* se não tiver mes definido setará ano atual */
    if (sessionStorage.getItem('mesComboProd') == null){
      sessionStorage.setItem('mesComboProd', new Date().getMonth() + 1);   
    }

    async function loadFinanceiro  (){    
      const ano = sessionStorage.getItem('anoComboProd');
      const mes = sessionStorage.getItem('mesComboProd');
      const codEmpresa = sessionStorage.getItem('codEmpresa');
      const tipoExtraFat = sessionStorage.getItem('mod-ger-tipoExtraFat')
      const moeda = sessionStorage.getItem('moeda'); 
      const paramsquery = 'codempresa='+  codEmpresa +'&mes='+ mes + '&ano=' +ano + '&tipoExtraFat=' + tipoExtraFat +
        '&moeda=' + moeda;
      const aURL = '/gerencial/financeiro?' + paramsquery;
      console.log(aURL);
    
        const response = await api.get(aURL)                             
          .then(function (response) {
            console.log(JSON.stringify(response.headers));
            console.log('cabecalho da resposta: ' + response.headers)
            setFinanceiro({saldoGeral : response.data.saldoGeral,
                           saldoEmBanco : response.data.saldoEmBanco,
                           saldoEmCaixa : response.data.saldoEmCaixa,
                           totalCredito : response.data.totalCredito,
                           totalDebito :  response.data.totalDebito,
                           listaSaldosContas : response.data.listaSaldosContas,
                           despesasPorSetor : response.data.despesasPorSetor,  
            });   
            return response;
          })
          .catch(function (error) {
            console.log(error);
          });       
      }
    
      loadFinanceiro();
      
    
    }, []);  
  

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
      >
        
        <Grid
          item
          lg={2}
          sm={6}
          xl={3}
          xs={12}
        >
          <ComboMes
            //mes ={mes.mes}
            mes ={sessionStorage.getItem('mesComboProd')}
            onChange={handleChange}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <ComboAno
            ano={sessionStorage.getItem('anoComboProd')}
            onChange={handleChange}
          />

        </Grid>
        <Grid
          item
          // lg={3}
          // sm={6}
          // xl={3}
          // xs={12}
        >
          <ComboMoeda    
            name={"cmbMoeda"}
            label={"nomemoeda"}
            value={sessionStorage.getItem('moeda')}
            onChange={handleChange}
            visible={(sessionStorage.getItem('trabOutraMoeda') === ASIM)}
          />
        </Grid>
          
      </Grid>


      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <FinanceiroContaCorrente
            saldoGeral={financeiro.saldoGeral}
            saldoEmBanco={financeiro.saldoEmBanco}
            saldoEmCaixa={financeiro.saldoEmCaixa}  
          />            

        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
           
          <FinanceiroGraficoCreditoDebito
            totalCredito={financeiro.totalCredito}                
            totalDebito ={financeiro.totalDebito}                            
           
          />

        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <FinanceiroSaldoPorConta
            listaSaldosContas ={financeiro.listaSaldosContas}
          />        
        </Grid>

        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <FinanceiroGraficoPorSetor
            despesasPorSetor = {financeiro.despesasPorSetor}         
          />
        </Grid>

      </Grid>

    </div>
  );
};

export default MenuFinanceiro;
