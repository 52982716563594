import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import api from '../../services/api';
import { CardReserva, CardChegada, CardTitleProgramacao, CardPartida, CardFaleConosco, CardServicos } from './components';
import { CustomizedTimeline } from '.';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const MenuProgramacao = () => {
  const classes = useStyles();
  const handleChange = event => {
  event.persist();
    //console.log('escolheu mes');
    window.location.reload();  
  }

 /* Dados da OS */
 const [os, setDadosOS] = useState({});
 const [passengers, setPassengers] = useState([]);
 const [itens, setItensLocalizador] = useState([]);
 const [cfgappcli, setCfgAppcli] = useState([])
 
/* carrega dados da OS */
async function loadDadosOS() {
  const codOS = sessionStorage.getItem('codOS');
  const aURL = '/os/' + codOS;
  try {
    console.log('código da OS consultada é:' + codOS);
    const response = await api.get(aURL);
    setDadosOS(response.data[0]);
    setPassengers(response.data[0].paxgeral);
    //console.log(response.data[0].paxgeral)
  } catch (error) {
    console.log(error);
  }
}


/* carrega itens do localizador */
async function loadItensLocalizador() {
  const localizador = sessionStorage.getItem('localizador');
  const aURL = '/appcli/itensOSByLocalizador';
  try {
    const response = await api.post(aURL, {localizador: localizador});
    let itens = response.data;
    // formata datas
    for (let i = 0; i < itens.length; i++) {
      itens[i].dataservico = moment(itens[i].dataservico).format("DD/MM/YYYY");
      let horain = new Date(itens[i].horainservico).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      itens[i].horainservico = horain;
      let horaapresentacao = new Date(itens[i].horaapresentacao).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      itens[i].horaapresentacao = horaapresentacao;
    }
    console.log('itens: ' + JSON.stringify(itens));
    setItensLocalizador(itens);
    
    console.log('o que está no log: ' + JSON.stringify(response.data));
  
} catch (error) {
    console.log(error);
  }
}


/* carrega configurações app cli */
async function loadDadosCfgAppCli() {
  //const codOS = sessionStorage.getItem('codOS');
  const aURL = '/appcli/configuracoes/1';
  try {
    const response = await api.get(aURL);
    setCfgAppcli(response.data[0]);
   } catch (error) {
    console.log(error);
  }
}

useEffect(() => {
    loadDadosOS();
    loadItensLocalizador();
    loadDadosCfgAppCli();
  }, []);  


  return (
    <div className={classes.root}>
      
      <CustomizedTimeline
        datain={os.datain}
        dataout={os.dataout}
        itens={itens}
      />
      <CardTitleProgramacao/>
     
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={6}
          xs={12}
        >
          <CardReserva
            nroos={os.nroos}
            nomefantcli={os.nomefantcli}
            idiomaos={os.idiomaos}
            passageiros={passengers}
            hospedagemprincipal={os.nomefanthotel}
          />

        </Grid>
   
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <CardServicos
            servicos={itens}
          />

        </Grid>

        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        > 
          <CardFaleConosco
            faleconosco={cfgappcli.faleconosco}/>
        
        </Grid>


      </Grid>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >

        </Grid>
  
      </Grid> 


    </div>
  );
};

export default MenuProgramacao;
