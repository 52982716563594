import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import PropTypes from 'prop-types';


const options = ['Extras', 'Faturados', 'Todos'];

const ButtonGroupExtraFat = props => {
  const { className, ...rest } = props;
  //const classes = useStyles();
  //export default function SplitButton() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  //const { className, ...rest } = props;
  
  let initialIndex = 0;

  if (sessionStorage.getItem('mod-ger-tipoExtraFat') == null) {
    initialIndex = 2 
  }
  else
    initialIndex = (sessionStorage.getItem('mod-ger-tipoExtraFat') -1);

  //const [selectedIndex, setSelectedIndex] = React.useState(2);    
  const [selectedIndex, setSelectedIndex] = React.useState(initialIndex);

  const handleClick = () => {
    console.info(`You clicked.. ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    //console.log('vai setar o index ' + index)
    sessionStorage.setItem('mod-ger-tipoExtraFat', index + 1);
    //if (sessionStorage.getItem('mod-ger-tipoExtraFat') != null){
    //index = index -1
    //}
    //console.log('gravado em mod-ger-tipoExtraFat is: ' + sessionStorage.getItem("mod-ger-tipoExtraFat"));
    setSelectedIndex(index);
    setOpen(false);
    //console.info(`You clicked index` + index);    
    if (rest.atualiza == true){
      window.location.reload();     
    }

  };

  const handleToggle = () => {
    console.log('index actual ' + selectedIndex);
    console.log('handle toggle')
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Grid container direction="column" alignItems="left">
      <Grid item xs={12}>
        <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
          <Button onClick={handleClick}>{options[selectedIndex]}</Button>
          <Button
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="menu"
            aria-label="select merge strategy"
            color="primary"
            onClick={handleToggle}
            size="medium"
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        //disabled={index === 2}
                        onClick={(event) => handleMenuItemClick(event, index)}
                        selected={index === selectedIndex}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

ButtonGroupExtraFat.propTypes = {
  className: PropTypes.string
};

export default ButtonGroupExtraFat;