import React, { Component } from 'react';
import { Button, Card, CardContent, Table, TableCell, TableRow, TableHead, TableBody, Paper,
Input} from '@material-ui/core';
import api from '../../../services/api';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal/DeleteConfirmationModal';
import AlertInformation from '../../../components/AlertInformation/AlertInformation';
import { Select, MenuItem } from '@material-ui/core';
import { ANAO, ASIM } from 'consts';

class TransacaoFinanceiraList extends Component{
  state = {
    transacoes : [],
    searchFilter : {
      nomeTransacao : '',
      situacaoInativoFilter: ANAO,
    },
  };

  estilo = {
    marginLeft: 30,
    marginTop: 30,
    marginRight: 30,
    container: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    addButton: {
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      float: 'right', // alinhamento a direita
      marginTop: 3,
      marginRight: 3
    },
    redText: {
      color: 'red',
    },  
  };

  constructor(props) {
    super(props);
  }

  /* carregamento inicial */
  async componentDidMount(){
    this.loadTransacoes();
  }

  /* Carrega Transações */
  loadTransacoes = async () =>{
    const { searchFilter } = this.state;
    let params = '';

    /* consulta pelo nome da transação */
    if (!(searchFilter.nomeTransacao === '')){
      params += '?tbtransacaofinanc.desctransacao=_like_' + searchFilter.nomeTransacao
    }

    /* filtra situação da transação */
    switch (searchFilter.situacaoInativoFilter) {
      case ASIM:
        if (params){
          params += '&tbtransacaofinanc.inativo=' + ASIM
        }
        else
          params += '?tbtransacaofinanc.inativo=' + ASIM ;        
      break;
      case ANAO:
        if (params){
          params += '&tbtransacaofinanc.inativo=' + ANAO
        }
        else
          params += '?tbtransacaofinanc.inativo=' + ANAO  ;
        break;
      default:
        break;
    }        

    const response = await api.get('/transacaofinanceira' + params);      
    this.setState({ transacoes : response.data });
  }

  /* Escolha de uma transação para exclusão */
  handleSelectItem = (id) => {
    this.setState({selectedId: id}); 
  };

  /* deleta transaçao */
  handleDelete = async (id) => {
    try {
      const response = await api.delete(`/transacaofinanceira/${id}`);
      // Verifica se a exclusão foi bem-sucedida
      if (response.status === 200) {
        this.handleClose();
        this.loadTransacoes();
      }
    } catch (error) {
        this.handleClose();
        this.handleOpenAlert(error.response.data.error);
        console.log(error);
        console.log(error.message);
    }
  };

  /* abre janela confirmação para exclusão */
  handleOpen = (codtransacao) => {
    this.setState({ open: true });
    this.handleSelectItem(codtransacao);
  };

  /* abre janela de alerta com mensagem */
  handleOpenAlert = (message) => {
    this.setState({ openAlert: true });
    this.setState({ messageAlert: message });
  };  
  
  /* fecha janela de confirmação exclusão */
  handleClose = () => {
    this.setState({ open: false });
  };

  /* fecha janela mensagem alerta */
  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  }

  /* Pesquisar filtros na tela */
  OnChangeSearch = (event) => {
    const { searchFilter } = this.state;
    let { name, value } = event.target;
    value = value.toUpperCase()
    searchFilter[name] = value;
    this.setState({searchFilter: searchFilter });
    if (name === 'situacaoInativoFilter'){
      this.loadTransacoes();
    }   
  };

  /* Limpa Filtros na tela */
  LimpaFiltros = () => {
    let { searchFilter } = this.state;
    searchFilter.nomeTransacao = '';
    searchFilter.situacaoInativoFilter = 'N';
    this.setState({searchFilter: searchFilter });       
  }
  

  render(){
    const {transacoes} = this.state;
    const {searchFilter} = this.state;
    return(
      <div style={this.estilo}>
        <Button
          component={Link}
          to="/financas/transacoes/novo"
          variant="contained"
          color="primary"
          style={this.estilo.addButton}
        >
          Novo
        </Button>
        <Paper hover>
          <Input 
            autoFocus
            id="nomeTransacao"
            name="nomeTransacao"
            onBlur = {this.onInputBlur}
            onChange = {this.OnChangeSearch} 
            placeholder = "Nome transação a pesquisar"
            style={{marginBottom: 10, width: 300, marginLeft:10, marginTop:10}}
            value = {searchFilter.nomeTransacao} 
          />

          <Select
            id="situacaoInativoFilter"
            name="situacaoInativoFilter"
            style={{marginLeft: 20}}
            value={searchFilter.situacaoInativoFilter || ''}
            onChange={this.OnChangeSearch}
          >
            <MenuItem value={ANAO}>Ativos</MenuItem>
            <MenuItem value={ASIM}>Inativos</MenuItem>
            <MenuItem value={'T'}>Todos</MenuItem>
          </Select>

          <Button
            color="primary"
            id="pesquisar"
            onClick={() => this.loadTransacoes()}
          >
            <SearchIcon />
          </Button>

          <Button
            color="primary"
            onClick={() => this.LimpaFiltros()}
          >
            <ClearAllIcon/>
          </Button>
        </Paper>

        <Card>
          <CardContent>
            <PerfectScrollbar>
              <div >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Código</TableCell>
                      <TableCell>Transação</TableCell>
                      <TableCell>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transacoes.slice(0, 100).map(transacao => (
                      <TableRow
                        hover
                        key={transacao.codtransacao}
                      >
                        <TableCell style={(transacao.inativo === ASIM) ? this.estilo.redText : {}}>{transacao.codtransacao}</TableCell>
                       
                        <TableCell style={(transacao.inativo === ASIM) ? this.estilo.redText : {}} >{transacao.desctransacao}</TableCell>
                        
                        <TableCell>
                          <Link to={`/financas/transacoes/${transacao.codtransacao}`}>
                            <EditOutlinedIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                          </Link>
                          <Link to={`/financas/transacoes/${transacao.codtransacao}/view`}>
                            <PageviewOutlinedIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                          </Link>
                          <DeleteForeverOutlinedIcon 
                              onClick={() => this.handleOpen(transacao.codtransacao)}
                              style={{ cursor: 'pointer', marginRight: '10px' }} />
                        </TableCell>
                        
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
        </Card>        

        <DeleteConfirmationModal
          open={this.state.open}
          handleClose={() => this.handleClose()}
          itemId={this.state.selectedId}
          message={"Confirma exclusão da transação financeira " + this.state.selectedId + '?'}
          handleDelete={()=> this.handleDelete(this.state.selectedId)}
        />

        <AlertInformation
          open={this.state.openAlert}
          handleClose={() => this.handleCloseAlert()}
          message={this.state.messageAlert}
        />
      </div>
    );
  }
}
export default TransacaoFinanceiraList;