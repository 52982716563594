import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card,  CardContent, Grid, Typography, Avatar, Divider, IconButton} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ButtonGroupExtraFat from'views/Components/ButtonGroupExtraFat/ButtonGroupExtraFat';
import RefreshIcon from '@material-ui/icons/Refresh';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DoneIcon from '@material-ui/icons/Done';
import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  }
}));

const OperacionalOS = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const refreshDados = ()=>{
    window.location.reload();
  }


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>

            <Typography
              className={classes.title}
              color="inherit"
              gutterBottom
              variant="body2"
              
            >
              ORÇAMENTOS
            </Typography>

            <Typography
              color="inherit"
              variant="h2"
            > {rest.qtdeOrcamento}

              <IconButton 
                alignItems= "right"                
                color="inherit" 
                size="small"
              >
                <RefreshIcon 
                  onClick={refreshDados}
                />
              </IconButton>

            </Typography>
            <br/>

            <Typography
              className={classes.title}
              color="inherit"
              gutterBottom
              variant="body2"
              
            >
              ORDENS DE SERVIÇO
            </Typography>
            <Typography
              color="inherit"
              variant="h2"
            > {rest.qtdeOS}

              <IconButton 
                alignItems= "right"                
                color="inherit" 
                size="small"
              >
                <RefreshIcon 
                  onClick={refreshDados}
                />
              </IconButton>

            </Typography>
            <br/>
            
            <Typography
              className={classes.title}
              color="inherit"
              gutterBottom
              variant="body2"
              
            >
              ESCALAS
            </Typography>
            <Typography
              color="inherit"
              variant="h2"
            > {rest.qtdeEscalas}

              <IconButton 
                alignItems= "right"                
                color="inherit" 
                size="small"
              >
                <RefreshIcon 
                  onClick={refreshDados}
                />
              </IconButton>


            </Typography>


          </Grid>
          <Grid item>
            <br/>
            <Avatar className={classes.avatar}>
              <DescriptionIcon className={classes.icon} />
            </Avatar>


            <br/>
            <Avatar className={classes.avatar}>
              <AssignmentIcon className={classes.icon} />
            </Avatar>
            <br/>

            <Avatar className={classes.avatar}>
              <DoneIcon className={classes.icon} />
            </Avatar>

          </Grid>

        </Grid>
      </CardContent>
    </Card>
  );
};

OperacionalOS.propTypes = {
  className: PropTypes.string
};

export default OperacionalOS;
