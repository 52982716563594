import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import api from '../../services/api';
import { AdministrativoExtras, AdministrativoGraficoExtrasRecebidosReceber, AdministrativoFaturados, 
  AdministrativoGraficoFaturadoaFaturar } from './components';
import ComboMes from '../Components/ComboMes';
import ComboAno from '../Components/ComboAno';
import ComboMoeda from '../Components/ComboMoeda/ComboMoeda';
import { ASIM, ATIPO_EXTRA, ATIPO_FATURADO, ATIPO_TODOS } from '../../consts';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const MenuAdministrativo = () => {
  const classes = useStyles();

  /*var aano = sessionStorage.getItem('anoComboProd');
  if (aano == undefined){
    aano : new Date().getFullYear();
  } */

  const [ano, setAno] = useState({
    //ano : new Date().getFullYear()
    ano : sessionStorage.getItem('anoComboProd')
    //ano : aano
  });

  const [mes, setMes] = useState({
    mes : new Date().getMonth() + 1 // esta funcao comeca com 0 zero janeiro
  });

  
  const [moeda, setMoeda] = useState({
    moeda : sessionStorage.getItem('moeda')
  });


  const handleChange = event => {
    event.persist();

    if (event.target.name === 'cmbAno'){
      console.log('setará o ano no evento handlechange' + event.target.value )
      setAno({ano : event.target.value});
      sessionStorage.setItem('anoComboProd', event.target.value);    
    }
    if (event.target.name === 'cmbMes'){
      //console.log('setará o mes' + event.target.value )
      setMes({mes : event.target.value});
      sessionStorage.setItem('mesComboProd', event.target.value);          
    }
    if (event.target.name === 'cmbMoeda'){
      setMoeda({moeda : event.target.value});
      sessionStorage.setItem('moeda', event.target.value);          
    }

    window.location.reload(); 
  }

  /* Indicadores DashBoard */
  const [administrativo, setAdministrativo] = useState({
    totalGeralExtras : 0,
    totalExtrasRecebidos : 0,
    totalExtrasAReceber : 0,
    totalGeralFaturados : 0,
    totalFaturadosRecebidos : 0,
    totalFaturadosAReceber : 0,
  });

  function getTotalProducao(){
    var totProducao = 0;
    if (sessionStorage.getItem('mod-ger-tipoExtraFat') == ATIPO_TODOS) {
      totProducao = administrativo.totalProducaoServico
    }
    else
    if (sessionStorage.getItem('mod-ger-tipoExtraFat') == ATIPO_EXTRA) {
      totProducao = producaoServico.totalProducaoServicoExtra
    }
    else 
    if (sessionStorage.getItem('mod-ger-tipoExtraFat') == ATIPO_FATURADO) {
      totProducao = producaoServico.totalProducaoServicoFat
    }
    return totProducao
  }

  useEffect(() => {
    /* se não tiver ano definido setará ano atual */
    if (sessionStorage.getItem('anoComboProd') == null){
      sessionStorage.setItem('anoComboProd', new Date().getFullYear());   
    }
    /* se não tiver mes definido setará ano atual */
    if (sessionStorage.getItem('mesComboProd') == null){
      sessionStorage.setItem('mesComboProd', new Date().getMonth() + 1);   
    }
    async function loadAdministrativo  (){
      const ano = sessionStorage.getItem('anoComboProd');
      const mes = sessionStorage.getItem('mesComboProd');
      const codEmpresa = sessionStorage.getItem('codEmpresa');
      const tipoExtraFat = sessionStorage.getItem('mod-ger-tipoExtraFat')
      const moeda = sessionStorage.getItem('moeda'); 
      const paramsquery = 'codempresa='+  codEmpresa +'&mes='+ mes + '&ano=' +ano + '&tipoExtraFat=' + tipoExtraFat +
        '&moeda=' + moeda;
      console.log('parametros os logs...:' + paramsquery);
      const aURL = '/gerencial/administrativo?' + paramsquery;
      console.log(aURL);
    
      const response = await api.get(aURL)                             
        .then(function (response) {
          console.log(response)
          setAdministrativo({totalGeralExtras : response.data.totalGeralExtras, 
            totalExtrasRecebidos : response.data.totalExtrasRecebidos,
            totalExtrasAReceber : response.data.totalExtrasAReceber,
            totalGeralFaturados : response.data.totalGeralFaturados,
            totalFaturadosRecebidos : response.data.totalFaturadosRecebidos,            
            totalFaturadosAReceber : response.data.totalFaturadosAReceber
          });   

          return response;
        })
        .catch(function (error) {
          console.log(error);
        });       
    }
  
    loadAdministrativo();
    
  }, []);  


  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
      >
        
        <Grid
          item
          lg={2}
          sm={6}
          xl={3}
          xs={12}
        >
          <ComboMes
            mes ={sessionStorage.getItem('mesComboProd')}
            onChange={handleChange}
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <ComboAno
            ano={sessionStorage.getItem('anoComboProd')}
            onChange={handleChange}
          />
        </Grid>
        <Grid
          item
          // lg={3}
          // sm={6}
          // xl={3}
          // xs={12}          
        >
          <ComboMoeda            
            name={"cmbMoeda"}
            label={"nomemoeda"}
            value={sessionStorage.getItem('moeda')}
            onChange={handleChange}
            visible={(sessionStorage.getItem('trabOutraMoeda') === ASIM)}
          />
        </Grid>

      </Grid>


      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
         <AdministrativoExtras
            totalGeralExtras = {administrativo.totalGeralExtras}
            totalExtrasRecebidos = {administrativo.totalExtrasRecebidos}
            totalExtrasAReceber = {administrativo.totalExtrasAReceber}
         />
                      

        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <AdministrativoGraficoExtrasRecebidosReceber
            totalExtrasAReceber = {administrativo.totalExtrasAReceber}
            totalExtrasRecebidos = {administrativo.totalExtrasRecebidos}
          
          />
          

        </Grid>

      </Grid>

      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
         <AdministrativoFaturados 
            totalGeralFaturados = {administrativo.totalGeralFaturados}
            totalFaturadosRecebidos = {administrativo.totalFaturadosRecebidos}
            totalFaturadosAReceber = {administrativo.totalFaturadosAReceber}
         />
                       

        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
         <AdministrativoGraficoFaturadoaFaturar
            totalFaturadosRecebidos={administrativo.totalFaturadosRecebidos}                
            totalFaturadosAReceber ={administrativo.totalFaturadosAReceber}                            
        />

        </Grid>
      </Grid>
    </div>
  );
};

export default MenuAdministrativo;
