import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';

import uuid from 'uuid/v1';
import moment from 'moment';
import { getISOMoeda } from 'lib/functions';
import mockData from './data';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  image: {
    height: 48,
    width: 48
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const ProducaoVendedorServicosPorGenero = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  //const [products] = useState(mockData);
  const moeda = getISOMoeda(sessionStorage.getItem('moeda'));
  
  const totalOpcionais = rest.totalOpcionais.toLocaleString(moeda.abrevMoeda, { style: 'currency', currency: moeda.isoMoeda });
  const totalTransporte = rest.totalTransporte.toLocaleString(moeda.abrevMoeda, { style: 'currency', currency: moeda.isoMoeda });
  const totalHospedagem = rest.totalHospedagem.toLocaleString(moeda.abrevMoeda, { style: 'currency', currency: moeda.isoMoeda });
  const totalAereo = rest.totalAereo.toLocaleString(moeda.abrevMoeda, { style: 'currency', currency: moeda.isoMoeda });
  const totalSeguroViagem = rest.totalSeguroViagem.toLocaleString(moeda.abrevMoeda, { style: 'currency', currency: moeda.isoMoeda });
  const totalOutros = rest.totalOutros.toLocaleString(moeda.abrevMoeda, { style: 'currency', currency: moeda.isoMoeda });
   
  const products = [
    {
      id: uuid(),
      name: 'Opcionais',
      imageUrl: '/images/products/product_1.png',
      updatedAt: moment().subtract(2, 'hours'),
      totalGenero : totalOpcionais
    },
    {
      id: uuid(),
      name: 'Transporte',
      imageUrl: '/images/products/product_2.png',
      updatedAt: moment().subtract(2, 'hours'),
      totalGenero : totalTransporte
    },
    {
      id: uuid(),
      name: 'Hospedagem',
      imageUrl: '/images/products/product_3.png',
      updatedAt: moment().subtract(3, 'hours'),
      totalGenero : totalHospedagem
    },
    {
      id: uuid(),
      name: 'Aéreo',
      imageUrl: '/images/products/product_4.png',
      updatedAt: moment().subtract(5, 'hours'),
      totalGenero : totalAereo
    },
    {
      id: uuid(),
      name: 'Seguro Viagem',
      imageUrl: '/images/products/product_5.png',
      updatedAt: moment().subtract(9, 'hours'),
      totalGenero : totalSeguroViagem
    },
    {
      id: uuid(),
      name: 'Outros',
      imageUrl: '/images/products/product_6.png',
      updatedAt: moment().subtract(9, 'hours'),
      totalGenero : totalOutros
    }
  ];


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        subtitle={`${products.length} in total`}
        title="Valores comissão por gênero"
      />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          {products.map((product, i) => (
            <ListItem
              divider={i < products.length - 1}
              key={product.id}
            >
              <ListItemAvatar>
                <img
                  alt="Product"
                  className={classes.image}
                  src={product.imageUrl}
                />
              </ListItemAvatar>
              <ListItemText
                primary={product.name}
                //secondary={`R$ ${product.updatedAt.fromNow()}`}
                secondary={product.totalGenero}                
                
              />
              
            </ListItem>
          ))}
        </List>
      </CardContent>
      <Divider />
    </Card>
  );
};

ProducaoVendedorServicosPorGenero.propTypes = {
  className: PropTypes.string
};

export default ProducaoVendedorServicosPorGenero;
