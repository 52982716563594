import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import uuid from 'uuid/v1';
import moment from 'moment';
import mockData from './data';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  image: {
    height: 48,
    width: 48
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const OperacionalNumeros = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  //const [products] = useState(mockData);


  const itens = [
    {
      id: uuid(),
      name: 'Orçamentos',
      imageUrl: '/images/gerencial/operacional-img-os-orcamento.png',
      updatedAt: moment().subtract(2, 'hours'),
      totalGenero : rest.qtdeOrcamento,
      detalhe : '',
    },

    {
      id: uuid(),
      name: 'OSs registradas',
      imageUrl: '/images/gerencial/operacional-img-os-registrada1.png',
      updatedAt: moment().subtract(2, 'hours'),
      totalGenero : rest.qtdeOS,
      detalhe : '',
    },
    {
      id: uuid(),
      name: 'Quantidade de pax',
      imageUrl: '/images/gerencial/operacional-img-qtde-pax1.png',
      updatedAt: moment().subtract(3, 'hours'),
      totalGenero : rest.qtdeTotalPax,
      detalhe : '',
    },
    {
      id: uuid(),
      name: 'Serviços registrados',
      imageUrl: '/images/gerencial/operacional-img-os-servicos-registrados.png',
      updatedAt: moment().subtract(5, 'hours'),
      totalGenero : rest.qtdeServicos,
      detalhe : '',
    },
    {
      id: uuid(),
      name: 'Quantidade de escalas',
      imageUrl: '/images/gerencial/operacional-img-os-qtde-escalas.png',
      updatedAt: moment().subtract(9, 'hours'),
      totalGenero : rest.qtdeEscalas,
      detalhe : ''
    }
  ];


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        subtitle={`${itens.length} in total`}
        title="Números"
      />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          {itens.map((item, i) => (
            <ListItem
              divider={i < itens.length - 1}
              key={itens.id}
            >
              <ListItemAvatar>
                <img
                  alt="Item"
                  className={classes.image}
                  src={item.imageUrl}
                />

              </ListItemAvatar>
              <ListItemText
                primary={item.name}
                secondary={item.totalGenero}
              />
            </ListItem>

            
          ))}
        </List>
      </CardContent>
      <Divider />
    </Card>
  );
};

OperacionalNumeros.propTypes = {
  className: PropTypes.string
};

export default OperacionalNumeros;
