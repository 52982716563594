import React, { Component } from 'react';
import { Button, Card, CardContent, Table, TableCell, TableRow, TableHead, TableBody, Paper,
Input} from '@material-ui/core';
import api from '../../../services/api';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal/DeleteConfirmationModal';
import AlertInformation from '../../../components/AlertInformation/AlertInformation';
import { Select, MenuItem } from '@material-ui/core';
import { ANAO, ASIM } from 'consts';

class HistoricoBancarioList extends Component{
  state = {
    historicosbancarios : [],
    searchFilter : {
      nomeHistoricoBancario : '',
      situacaoInativoFilter : ANAO,
    },
  };

  estilo = {
    marginLeft: 30,
    marginTop: 30,
    marginRight: 30,
    container: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    addButton: {
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      float: 'right',
      marginTop: 3,
      marginRight: 3
    },
    redText: {
      color: 'red',
    },  
  };

  constructor(props) {
    super(props);
  }

  /* carregamento inicial */
  async componentDidMount(){
    this.loadHistoricosBancarios();
  }

  /* Carrega Históricos Bancários */
  loadHistoricosBancarios = async () =>{
    const { searchFilter } = this.state;
    let params = '';

    /* consulta pelo nome do histórico bancário */
    if (!(searchFilter.nomeHistoricoBancario === '')){
      params += '?tbhistorico.descricao=_like_' + searchFilter.nomeHistoricoBancario
    }

    /* filtra situação do histórico bancário */
    switch (searchFilter.situacaoInativoFilter) {
      case ASIM:
        if (params){
          params += '&tbhistorico.inativo=' + ASIM
        }
        else
          params += '?tbhistorico.inativo=' + ASIM ;        
      break;
      case ANAO:
        if (params){
          params += '&tbhistorico.inativo=' + ANAO
        }
        else
          params += '?tbhistorico.inativo=' + ANAO  ;
        break;
      default:
        break;
    }        

    const response = await api.get('/historico' + params);
    this.setState({ historicosbancarios : response.data });
  }

  /* Escolha de um histórivo bancário para exclusão */
  handleSelectItem = (id) => {
    this.setState({selectedId: id}); 
  };

  /* deleta histórico bancário */
  handleDelete = async (id) => {
    try {
      const response = await api.delete(`/historico/${id}`);
      // Verifica se a exclusão foi bem-sucedida
      if (response.status === 200) {
        this.handleClose();
        this.loadHistoricosBancarios();
      }
    } catch (error) {
        this.handleClose();
        this.handleOpenAlert(error.response.data.error);
        console.log(error);
        console.log(error.message);
    }
  };

  /* abre janela confirmação para exclusão */
  handleOpen = (codhistoricobancario) => {
    this.setState({ open: true });
    this.handleSelectItem(codhistoricobancario);
  };

  /* abre janela de alerta com mensagem */
  handleOpenAlert = (message) => {
    this.setState({ openAlert: true });
    this.setState({ messageAlert: message });
  };  
  
  /* fecha janela de confirmação exclusão */
  handleClose = () => {
    this.setState({ open: false });
  };

  /* fecha janela mensagem alerta */
  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  }

  /* Pesquisar filtros na tela */
  OnChangeSearch = (event) => {
    const { searchFilter } = this.state;
    let { name, value } = event.target;
    value = value.toUpperCase()
    searchFilter[name] = value;
    this.setState({searchFilter: searchFilter });
    if (name === 'situacaoInativoFilter'){
      this.loadHistoricosBancarios();
    }   
  };

  /* Limpa Filtros na tela */
  LimpaFiltros = () => {
    let { searchFilter } = this.state;
    searchFilter.nomeHistoricoBancario = '';
    searchFilter.situacaoInativoFilter = 'N';
    this.setState({searchFilter: searchFilter });       
  }  

  render(){
    const {historicosbancarios} = this.state;
    const {searchFilter} = this.state;
    return(
      <div style={this.estilo}>
        <Button
          component={Link}
          to="/financas/historicosbancarios/novo"
          variant="contained"
          color="primary"
          style={this.estilo.addButton}
        >
          Novo
        </Button>
        <Paper hover>
          <Input 
            autoFocus
            id="nomeHistoricoBancario"
            name="nomeHistoricoBancario"
            onBlur = {this.onInputBlur}
            onChange = {this.OnChangeSearch} 
            placeholder = "Nome histórico bancário a pesquisar"
            style={{marginBottom: 10, width: 300, marginLeft:10, marginTop:10}}
            value = {searchFilter.nomeHistoricoBancario}
          />

          <Select
            id="situacaoInativoFilter"
            name="situacaoInativoFilter"
            style={{marginLeft: 20}}
            value={searchFilter.situacaoInativoFilter || ''}
            onChange={this.OnChangeSearch}
          >
            <MenuItem value={ANAO}>Ativos</MenuItem>
            <MenuItem value={ASIM}>Inativos</MenuItem>
            <MenuItem value={'T'}>Todos</MenuItem>
          </Select>

          <Button
            color="primary"
            id="pesquisar"
            onClick={() => this.loadHistoricosBancarios()}
          >
            <SearchIcon />
          </Button>

          <Button
            color="primary"
            onClick={() => this.LimpaFiltros()}
          >
            <ClearAllIcon/>
          </Button>
        </Paper>

        <Card>
          <CardContent>
            <PerfectScrollbar>
              <div >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Código</TableCell>
                      <TableCell>Histórico Bancário</TableCell>
                      <TableCell>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {historicosbancarios.slice(0, 100).map(historicobancario => (
                      <TableRow
                        hover
                        key={historicobancario.codigo}
                      >
                        <TableCell style={(historicobancario.inativo === ASIM) ? this.estilo.redText : {}}>{historicobancario.codigo}</TableCell>
                       
                        <TableCell style={(historicobancario.inativo === ASIM) ? this.estilo.redText : {}} >{historicobancario.descricao}</TableCell>
                        
                        <TableCell>
                          <Link to={`/financas/historicosbancarios/${historicobancario.codigo}`}>
                            <EditOutlinedIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                          </Link>
                          <Link to={`/financas/historicosbancarios/${historicobancario.codigo}/view`}>
                            <PageviewOutlinedIcon style={{ cursor: 'pointer', marginRight: '10px' }} />
                          </Link>
                          <DeleteForeverOutlinedIcon 
                              onClick={() => this.handleOpen(historicobancario.codigo)}
                              style={{ cursor: 'pointer', marginRight: '10px' }} />
                        </TableCell>
                        
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
        </Card>        

        <DeleteConfirmationModal
          open={this.state.open}
          handleClose={() => this.handleClose()}
          itemId={this.state.selectedId}
          message={"Confirma exclusão do histórico bancário " + this.state.selectedId + '?'}
          handleDelete={()=> this.handleDelete(this.state.selectedId)}
        />

        <AlertInformation
          open={this.state.openAlert}
          handleClose={() => this.handleCloseAlert()}
          message={this.state.messageAlert}
        />
      </div>
    );
  }
}

export default HistoricoBancarioList;