import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card,  CardContent, Grid, Typography, Avatar, Divider, IconButton} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ButtonGroupExtraFat from'views/Components/ButtonGroupExtraFat/ButtonGroupExtraFat';
import RefreshIcon from '@material-ui/icons/Refresh';
import { FORMA_DINHEIRO, FORMA_DOLAR, FORMA_EURO,  FORMA_PESO, FORMA_GUARANI } from 'consts';

import {getISOMoeda} from 'lib/functions';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  }
}));

const ProducaoServico = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const refreshDados = ()=>{
    window.location.reload();
  }

  const moeda = getISOMoeda(sessionStorage.getItem('moeda'));
  
  const totalProducaoServico = rest.totalProducaoServico.toLocaleString(moeda.abrevMoeda, { style: 'currency', currency: moeda.isoMoeda });
  const totalProducaoServicoExtra = rest.totalProducaoServicoExtra.toLocaleString(moeda.abrevMoeda, { style: 'currency', currency: moeda.isoMoeda });
  const totalProducaoServicoFat = rest.totalProducaoServicoFat.toLocaleString(moeda.abrevMoeda, { style: 'currency', currency: moeda.isoMoeda });
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          //justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="inherit"
              gutterBottom
              variant="body2"
              
            >
              PRODUÇÃO SERVIÇOS
            </Typography>
            <Typography
              color="inherit"
              variant="h2"
              
            > {totalProducaoServico}

              <IconButton 
                alignItems= "right"                
                color="inherit" 
                size="small"
              >
                <RefreshIcon 
                  onClick={refreshDados}
                />
              </IconButton>

            </Typography>
            <br/>
            <Typography
              color="inherit"
              variant="h5"
              style={{display: (sessionStorage.getItem('mod-ger-tipoExtraFat') == 3) ? '' : 'none'}}
            >
             EXTRAS - {totalProducaoServicoExtra}
            </Typography>
            <Divider />          

            <Typography
              color="inherit"
              variant="h5"
              style={{display: (sessionStorage.getItem('mod-ger-tipoExtraFat') == 3) ? '' : 'none'}}              
            >
             FATURADOS - {totalProducaoServicoFat}             
            </Typography>
            
          </Grid>
          <Grid item>
            <br/>
            <Avatar className={classes.avatar}>
              <AttachMoneyIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <div>
          <br/> 
          <ButtonGroupExtraFat
            atualiza={true}
          />
        </div>
      </CardContent>
    </Card>
  );
};

ProducaoServico.propTypes = {
  className: PropTypes.string
};

export default ProducaoServico;
