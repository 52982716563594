import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import { getISOMoeda } from 'lib/functions';
import uuid from 'uuid/v1';
import moment from 'moment';
import mockData from './data';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  image: {
    height: 48,
    width: 48
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const FinanceiroSaldoPorConta = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  //console.log('o nome do locate xxx.. ' + window.location.pathname);

  //const [products] = useState(mockData);
  const moeda = getISOMoeda(sessionStorage.getItem('moeda'));
  
  const saldosPorConta = rest.listaSaldosContas;
  let contas = saldosPorConta;  

  //console.log('o que tem em contas:' + rest.listaSaldosContas)
  /*const contas = [
    {
      id: uuid(),
      name: 'Banco do Brasil',
      imageUrl: '/images/products/financeiro-img-saldo-conta4.png',
      updatedAt: moment().subtract(2, 'hours'),
      totalGenero : 10
    },
    {
      id: uuid(),
      name: 'Itaú',
      imageUrl: '/images/products/financeiro-img-saldo-conta4.png',
      updatedAt: moment().subtract(2, 'hours'),
      totalGenero : 10
    },
    {
      id: uuid(),
      name: 'Santander',
      imageUrl: '/images/products/financeiro-img-saldo-conta4.png',
      updatedAt: moment().subtract(3, 'hours'),
      totalGenero : 10
    },
    {
      id: uuid(),
      name: 'Caixa Econômica Federal',
      imageUrl: '/images/products/financeiro-img-saldo-conta4.png',
      updatedAt: moment().subtract(5, 'hours'),
      totalGenero : 10
    },
    {
      id: uuid(),
      name: 'City Bank',
      imageUrl: '/images/products/financeiro-img-saldo-conta4.png',
      updatedAt: moment().subtract(9, 'hours'),
      totalGenero : 10
    },
    {
      id: uuid(),
      name: 'Bradesco',
      imageUrl: '/images/products/financeiro-img-saldo-conta4.png',
      updatedAt: moment().subtract(9, 'hours'),
      totalGenero : 10
    }
  ];
  */

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        subtitle={`${contas.length} in total`}
        title="Saldos por conta"
      />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          {contas.map((conta, i) => (
            <ListItem
              divider={i < contas.length - 1}
              key={conta.cdConta}
            >
              <ListItemAvatar>
                <img
                  alt="Product"
                  className={classes.image}
                  src={conta.imageUrl}
                />
              </ListItemAvatar>
              <ListItemText
                primary={conta.clienteConta}
                //secondary={`R$ ${product.updatedAt.fromNow()}`}
                secondary={conta.saldoConta.toLocaleString(moeda.abrevMoeda, { style: 'currency', currency: moeda.isoMoeda })}                
                
              />
              
            </ListItem>
          ))}
        </List>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>

      </CardActions>


    </Card>
  );
};

FinanceiroSaldoPorConta.propTypes = {
  className: PropTypes.string
};

export default FinanceiroSaldoPorConta;
