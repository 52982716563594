import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import { red } from '@material-ui/core/colors';
import { ChartTooltipItem } from 'chart.js';
import { getISOMoeda } from 'lib/functions';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

const FinanceiroGraficoCreditoDebito = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();

  let totalCredito = rest.totalCredito;
  let totalDebito = rest.totalDebito;
  const moeda = getISOMoeda(sessionStorage.getItem('moeda'));
  
  const data = {
    datasets: [
      {
        data: [totalCredito, totalDebito],
        backgroundColor: [
          theme.palette.secondary.main,
          //theme.palette.warning.main
          red[500]
        ],
        borderWidth: 8,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white,
      
      
      },
    ],
    labels: ['Créditos', 'Débitos']
  };

  const options = {
    legend: {
      display: true
    },
    responsive: true,
    maintainAspectRatio: false,
    //animation: false,
    cutoutPercentage: 70,
    layout: { padding: 0 },
    animation: {
      animateRotate: true,  
      animateScale: true,
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        label: (tooltipItem: ChartTooltipItem, data) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const currentValue = dataset.data[tooltipItem.index];
          const total = dataset.data.reduce((sum, value) => sum + value, 0);
          const value = ((currentValue) );
          return `${data.labels[tooltipItem.index]}:  ${value.toLocaleString(moeda.abrevMoeda, { style: 'currency', currency: moeda.isoMoeda })}  `
        },
      },   

    }

  };

  
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Créditos / Débitos"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Doughnut
            data={data}
            options={options}
          />
        </div>
      </CardContent>
    </Card>
  );
};

FinanceiroGraficoCreditoDebito.propTypes = {
  className: PropTypes.string
};

export default FinanceiroGraficoCreditoDebito;
