import React  from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));



const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  const classes = useStyles();
  function getImage(){
    const modulo = sessionStorage.getItem('modulo');
    let imagePath = '';
    if (modulo === '37') {
      imagePath = '/images/logocliappcli.png';
    } else {
      console.log('não houve correspondencia')
      imagePath = '/images/logos/logo--white.png';
    }
    return imagePath;

  }

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src={getImage()}
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        
        <Typography      
           className={classes.title}
              color="inherit"
              gutterBottom
              variant="body2"
                >
           {rest.fantasia}
           
        </Typography>
        
        
        
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
