import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Profile, SidebarNav } from './components';
import PollRounded from '@material-ui/icons/PollRounded';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import TodayIcon from '@material-ui/icons/Today';
import PinDropRoundedIcon from '@material-ui/icons/PinDropRounded';
const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const SideBarGerencial = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'Produção',
      href: '/gerencial/menuproducao',
      icon: <PollRounded />
    },

    {
      title: 'Operacional',
      href: '/gerencial/menuoperacional',
      icon: <PinDropRoundedIcon />
      

    },

    {
      title: 'Financeiro',
      href: '/gerencial/menufinanceiro',
      icon: <MonetizationOn />
    },

    {
      title: 'Administrativo',
      href: '/gerencial/menuadministrativo',
      icon: <TodayIcon />
    },

    {
      title: 'Sair',
      href: '/logout',  
      icon: <LockOpenIcon />
    }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};

SideBarGerencial.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default SideBarGerencial;
