import axios from 'axios';
import {BASEURL} from 'consts';


//window.btoa = window.btoa || require('Base64').btoa;
const api = axios.create({
  baseURL: BASEURL,            
  headers: {   
    'Content-type': 'application/json; charset=utf-8',
    'Accept': 'application/json; charset=utf-8',
    //'Content-Type': 'application/xhtml+xml; charset=utf-8',
    //'Accept': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*', // para permitir acesso a todos métodos - verbos
    
  }
});

export default api;
